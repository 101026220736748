import React, { useEffect } from 'react';

import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import { makeStyles } from '@material-ui/core/styles';
import useStores from '../hooks/useStores';

const useStyles = makeStyles(theme => ({
  root: {
    marginTop: theme.spacing(25),
    marginBottom: theme.spacing(25),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  }
}));

export default function SairPage() {
  const classes = useStyles();
  const store = useStores();

  useEffect(() => {
    if (store.usuarioStore.sessao.loggedIn) {
      (async function() {
        await store.usuarioStore.sair();
      })();
    }
  });

  return (
    <div className={classes.root}>
      <Container component="main" maxWidth="lg">
        <Box mt={5}>
          <Typography component="h1" variant="h5">
            Saindo...
          </Typography>
        </Box>
      </Container>
    </div>
  );
}
