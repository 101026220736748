const AppConfig = {
  API_URL: process.env.REACT_APP_API_URL || '',
  HOME_URL: process.env.REACT_APP_HOME_URL || 'https://psique.app',
  STRIPE_PUBLIC_KEY:
    process.env.REACT_APP_STRIPE_PK ||
    'pk_test_51Iq6ZgLW8KguUS1IyahltwnGiFplgRMQnK8n7pOYza68O4fClkxuifVD5roxXsx6uG4gcokxe205QLvQ8e2vK2hy00i6cuH4a6',
  CUPOM_PADRAO_CONTA_PROFISSIONAL: process.env.REACT_APP_CUPOM_PADRAO_CONTA_PROFISSIONAL
};

export default AppConfig;
