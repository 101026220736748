import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import useStores from '../../hooks/useStores';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableRow from '@material-ui/core/TableRow';
import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import AccordionActions from '@material-ui/core/AccordionActions';
import { withStyles } from '@material-ui/core/styles';
import { observer } from 'mobx-react';
import CheckIcon from '@material-ui/icons/Check';
import Icon from '@material-ui/core/Icon';
import { grey } from '@material-ui/core/colors';
import Box from '@material-ui/core/Box';
import Divider from '@material-ui/core/Divider';
import Button from '@material-ui/core/Button';
import Chip from '@material-ui/core/Chip';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import ExtensionIcon from '@material-ui/icons/Extension';
import ModalMapearDistorcoes from './ModalMapearDistorcoes';
import ModalDesafioPensamento from './ModalDesafioPensamento';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'row',
    justify: 'center',
    alignItems: 'center',
    width: '100%'
  },
  diarios_summary: {
    textAlign: 'center',
    width: '100%',
    fontSize: '1rem',
    [theme.breakpoints.up('md')]: {
      fontSize: '1.12rem'
    }
  },
  iconesHumor: {
    color: grey[500],
    fontSize: '1.2rem',
    [theme.breakpoints.up('md')]: {
      fontSize: '1.5rem'
    },
    marginRight: '0.5rem'
  },
  formControl: {
    marginRight: '0.3rem',
    [theme.breakpoints.up('md')]: {
      marginRight: '1rem'
    }
  },
  readCheckIcon: {
    fontSize: '1rem',
    [theme.breakpoints.up('md')]: {
      fontSize: '1.2rem'
    },
    marginLeft: '0.5rem'
  },
  firstCol: {
    width: '20%'
  },
  desafio: {
    '& > *': {
      margin: theme.spacing(0.5)
    }
  },
  desafioPensamentoHeader: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '0.5rem'
  },
  emocoes: {
    display: 'flex',
    justifyContent: 'left',
    flexWrap: 'wrap',
    '& > *': {
      margin: theme.spacing(0.5)
    }
  },
  distorcoes: {
    display: 'flex',
    justifyContent: 'left',
    alignItems: 'center',
    flexWrap: 'wrap',
    '& > *': {
      margin: theme.spacing(0.5)
    }
  },
  distorcoesChip: {
    background: '#ff9800'
  }
}));

export default observer(function AccordionDoDiario(props) {
  const store = useStores();
  const classes = useStyles();
  const diario = props.diario;
  const idPaciente = props.idPaciente;
  const onCheckboxChanged = props.onCheckboxChanged;
  const checked = props.checked;
  const distorcoes = store.tratamentoStore.tratamento.distorcoes;
  const [isModalDistorcoesOpen, setModalDistorcoesOpen] = useState(false);
  const [pensamentoEmocoesModalDistorcoes, setPensamentoEmocoesModalDistorcoes] = useState(null);
  const [isModalDesafioPensamentosOpen, setModalDesafioPensamentosOpen] = useState(false);
  const [pensamentoADesafiar, setPensamentoADesafiar] = useState(null);

  const abrirModalDistorcoes = pensamentoEmocoes => {
    setPensamentoEmocoesModalDistorcoes(pensamentoEmocoes);
    setModalDistorcoesOpen(true);
  };

  const fecharModalDistorcoes = (houveAlteracao, idPensamento, idDistorcoes) => {
    setModalDistorcoesOpen(false);
    if (houveAlteracao) {
      diario.pensamentosEmocoes.find(pe => pe.idPensamento === idPensamento).idDistorcoes = idDistorcoes;
    }
  };

  const abrirModalDesafioPensamentos = pensamento => {
    setPensamentoADesafiar(pensamento);
    setModalDesafioPensamentosOpen(true);
  };

  const fecharModalDesafioPensamentos = () => {
    setModalDesafioPensamentosOpen(false);
  };

  const MyTableCell = withStyles({
    root: {
      borderBottom: 'none'
    }
  })(TableCell);

  const handleChangeExpansion = (idDiario, origem, statusAtual) => (event, newExpanded) => {
    if (store.interfaceStore.oneTrueToManyFalse['a' + idDiario]) {
      store.interfaceStore.setOneTrueToManyFalse('a' + idDiario, false, false);
    } else {
      store.interfaceStore.setOneTrueToManyFalse('a' + idDiario, true, false);
    }

    if (newExpanded && !statusAtual) {
      store.tratamentoStore.alterarStatusDiario(store.usuarioStore.usuario.id, idPaciente, idDiario, true);
    } else {
      if (origem === 'botao') {
        store.tratamentoStore.alterarStatusDiario(store.usuarioStore.usuario.id, idPaciente, idDiario, false);
      }
    }
  };

  if (!diario) {
    return null;
  }

  return (
    <>
      <ModalMapearDistorcoes
        idTratamento={idPaciente}
        pensamentoEmocoes={pensamentoEmocoesModalDistorcoes}
        distorcoes={distorcoes}
        isOpen={isModalDistorcoesOpen}
        onClose={fecharModalDistorcoes}
      />
      <ModalDesafioPensamento
        pensamento={pensamentoADesafiar}
        isOpen={isModalDesafioPensamentosOpen}
        onClose={fecharModalDesafioPensamentos}
      />
      <Accordion
        key={diario.id}
        align="center"
        expanded={
          store.interfaceStore.oneTrueToManyFalse['a' + diario.id] === undefined
            ? false
            : store.interfaceStore.oneTrueToManyFalse['a' + diario.id]
        }
        onChange={handleChangeExpansion(diario.id, '', diario.diarioLido)}
        TransitionProps={{ unmountOnExit: true }}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon color="secondary" />}
          aria-controls={'panel-content' + diario.id}
          id={'panel-header' + diario.id}>
          <FormControlLabel
            aria-label="Acknowledge"
            onClick={event => event.stopPropagation()}
            onFocus={event => event.stopPropagation()}
            onChange={event => onCheckboxChanged(diario.id, event.target.checked)}
            className={classes.formControl}
            control={<Checkbox checked={checked} />}
          />
          <Box style={{ alignSelf: 'center', lineHeight: 0 }}>{getIconHumor(diario.idSentimento)} </Box>
          <Box className={classes.desafioPensamentoHeader} style={{ opacity: '0%' }}>
            {diario.pensamentosEmocoes.some(pe => pe.pensamentoReformulado !== null) && (
              <Chip label="Desafiado" color="secondary" size="small" />
            )}
          </Box>
          <Box className={classes.root}>
            <Typography
              variant="body1"
              noWrap
              color={diario.diarioLido ? 'primary' : 'initial'}
              className={classes.diarios_summary}>
              {formatarDataDiario(new Date(diario.dataEnvio))}
              <CheckIcon className={classes.readCheckIcon} style={{ opacity: diario.diarioLido ? '100%' : '0%' }} />
            </Typography>
          </Box>
          <Box className={classes.desafioPensamentoHeader}>
            {diario.pensamentosEmocoes.some(pe => pe.pensamentoReformulado !== null) && (
              <Chip label="Desafiado" color="secondary" size="small" style={{ color: 'white' }} />
            )}
          </Box>
        </AccordionSummary>
        <Divider width="90%" />
        {store.interfaceStore.oneTrueToManyFalse['a' + diario.id] && (
          <>
            <AccordionDetails>
              <TableContainer>
                <Table size="small">
                  <TableBody>
                    <TableRow key="1">
                      <MyTableCell className={classes.firstCol}>Sentimento:</MyTableCell>
                      <MyTableCell>{diario.sentimento}</MyTableCell>
                    </TableRow>
                    <TableRow key="2">
                      <MyTableCell className={classes.firstCol}>Situação:</MyTableCell>
                      <MyTableCell>{diario.situacao}</MyTableCell>
                    </TableRow>
                    <TableRow key="3">
                      <MyTableCell className={classes.firstCol}>Ação:</MyTableCell>
                      <MyTableCell>{diario.acao}</MyTableCell>
                    </TableRow>
                    <TableRow align="center" key="4">
                      <MyTableCell colSpan={2}>
                        <Divider width="100%" />
                      </MyTableCell>
                    </TableRow>
                    {diario.pensamentosEmocoes.map((pe, index) => (
                      <React.Fragment key={index}>
                        <TableRow key="5">
                          <MyTableCell className={classes.firstCol}>Pensamento:</MyTableCell>
                          <MyTableCell>{pe.textoPensamento}</MyTableCell>
                        </TableRow>
                        {pe.pensamentoReformulado && (
                          <TableRow key="6">
                            <MyTableCell className={classes.firstCol}>Desafio:</MyTableCell>
                            <MyTableCell>
                              <div className={classes.desafio}>
                                <Button
                                  size="small"
                                  variant="outlined"
                                  color="secondary"
                                  startIcon={<CheckIcon />}
                                  onClick={() => abrirModalDesafioPensamentos(pe)}>
                                  Ver Desafio
                                </Button>
                              </div>
                            </MyTableCell>
                          </TableRow>
                        )}
                        <TableRow key="7">
                          <MyTableCell className={classes.firstCol}>
                            {pe.emocoes.length > 1 ? 'Emoções:' : 'Emoção:'}
                          </MyTableCell>
                          <MyTableCell>
                            <div className={classes.emocoes}>
                              {pe.emocoes.map((emocao, index) => (
                                <Chip key={index} label={emocao.textoEmocao} color="primary" size="small" />
                              ))}
                            </div>
                          </MyTableCell>
                        </TableRow>

                        <TableRow key="8">
                          <MyTableCell className={classes.firstCol}>Distorções:</MyTableCell>
                          <MyTableCell>
                            <div className={classes.distorcoes}>
                              {pe.idDistorcoes.map((id, index) => (
                                <Chip
                                  key={index}
                                  label={distorcoes.find(d => d.id === id).nome}
                                  className={classes.distorcoesChip}
                                  size="small"
                                />
                              ))}
                              <Button
                                size="small"
                                variant="outlined"
                                color="primary"
                                startIcon={<ExtensionIcon />}
                                onClick={() => abrirModalDistorcoes(pe)}>
                                Mapear Distorções
                              </Button>
                            </div>
                          </MyTableCell>
                        </TableRow>

                        <TableRow align="center" key="9">
                          <MyTableCell colSpan={2}>
                            <Divider width="100%" />
                          </MyTableCell>
                        </TableRow>
                      </React.Fragment>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </AccordionDetails>
            <AccordionActions>
              <Button
                size="small"
                color="primary"
                onClick={handleChangeExpansion(diario.id, 'botao', diario.diarioLido)}>
                Marcar como não lido
              </Button>
            </AccordionActions>
          </>
        )}
      </Accordion>
    </>
  );

  function getIconHumor(idSentimento) {
    switch (idSentimento) {
      case 1:
        return <Icon classes={{ root: 'far fa-frown' }} className={classes.iconesHumor} />;
      case 2:
        return <Icon classes={{ root: 'far fa-sad-tear' }} className={classes.iconesHumor} />;
      case 3:
        return <Icon classes={{ root: 'far fa-meh' }} className={classes.iconesHumor} />;
      case 4:
        return <Icon classes={{ root: 'far fa-smile' }} className={classes.iconesHumor} />;
      case 5:
        return <Icon classes={{ root: 'far fa-laugh-squint' }} className={classes.iconesHumor} />;
      default:
        return null;
    }
  }
});

function formatarDataDiario(data) {
  return new Intl.DateTimeFormat('pt-BR', {
    timeZone: 'America/Sao_Paulo',
    year: 'numeric',
    month: 'numeric',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric'
  }).format(data);
}
