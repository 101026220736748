import React, { useEffect, useMemo, useState } from 'react';
import { observer } from 'mobx-react';
import useStores from '../../hooks/useStores';
import { useParams } from 'react-router-dom';
import ReactWordCloud from 'react-wordcloud';
import LoadingBalls from '../LoadingBalls';

let totalEmocoes = 0;

export default observer(function CloudEmocoes(props) {
  const store = useStores();
  const { idPaciente } = useParams();
  const diarios = store.tratamentoStore.tratamento.diarios;
  const [isLoading, setLoading] = useState(true);

  const memoizedSize = useMemo(() => [400, 400], []);
  const memoizedEmocoes = useMemo(() => buscaEmocoes(diarios), [diarios]);

  const J = JSON.stringify(memoizedEmocoes);
  const memoizedWordCloud = useMemo(() => trazWordCloud(J, memoizedSize), [J, memoizedSize]);

  function trazWordCloud(j, memoizedsize) {
    const memoizedemocoes = JSON.parse(j);
    return (
      <div justify="center" style={{ height: '200px', width: '100%' }}>
        <ReactWordCloud
          minSize={memoizedsize}
          // minSize={[400, 400]}
          options={{
            fontFamily: 'sans-serif',
            fontSizes: [12, 60],
            fontWeight: 'normal',
            padding: 1,
            rotations: 0,
            scale: 'log',
            spiral: 'archimedean'
          }}
          words={memoizedemocoes}
          callbacks={{
            getWordTooltip: ({ text, value }) => `${text}: ${percentualEmocao(value)}%`
          }}
        />
      </div>
    );
  }

  useEffect(() => {
    (async function() {
      await store.tratamentoStore.listarDiarios(store.usuarioStore.usuario.id, idPaciente);
      setLoading(false);
    })();
  }, [store.tratamentoStore, store.tratamentoStore.tratamento, store.usuarioStore.usuario, idPaciente]);

  if (isLoading || diarios === '') {
    return <LoadingBalls />;
  }

  return <div>{memoizedWordCloud}</div>;
});

function buscaEmocoes(diarios) {
  let nuvemEmocoes = [];
  totalEmocoes = 0;

  Array.from(diarios).forEach(diario => {
    diario.pensamentosEmocoes.forEach(pe => {
      pe.emocoes.forEach(emocao => {
        let index = nuvemEmocoes.findIndex(x => x.text === emocao.textoEmocao);
        if (index === -1) {
          let cloud = { text: emocao.textoEmocao, value: 1 };
          totalEmocoes++;
          nuvemEmocoes.push(cloud);
        } else {
          totalEmocoes++;
          nuvemEmocoes[index].value += 1;
        }
      });
    });
  });
  return nuvemEmocoes;
}

function percentualEmocao(valorEmocao) {
  let percentual = ((valorEmocao / totalEmocoes) * 100).toFixed(2);
  return percentual;
}
