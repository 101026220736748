import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import LinkEmailSuporte from '../../components/LinkEmailSuporte';
import LayoutTextual from '../../layout/LayoutTextual';
import { makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import CardT2P2 from '../../components/CardT2P2';
import AccountBoxIcon from '@material-ui/icons/AccountBox';
import PersonAddIcon from '@material-ui/icons/PersonAdd';
import LinkWhatsAppSuporte from '../../components/LinkWhatsAppSuporte';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    backgroundColor: theme.palette.background.paper
  }
}));

export default function ListaTutoriaisPage() {
  const classes = useStyles();

  return (
    <LayoutTextual
      title="Tutoriais do Psiquê"
      iconUrl="/images/icon_lista_tutoriais.svg"
      iconAltText="Tutoriais do Psiquê">
      <LayoutTextual.Paragrafo>
        Aqui você encontra tutoriais para te auxiliar no uso do Psiquê! Veja abaixo a lista dos tutoriais que temos
        disponíveis no momento. Se não encontrar o que está procurando, não se preocupe, basta entrar em contato através
        do e-mail <LinkEmailSuporte /> ou pelo WhatsApp no número <LinkWhatsAppSuporte /> que iremos te ajudar!
      </LayoutTextual.Paragrafo>
      <LayoutTextual.Paragrafo>
        <CardT2P2 titulo="Tutoriais Disponíveis">
          <div className={classes.root}>
            <List>
              <ListItem button component={RouterLink} to="/tutoriais/inclusao-paciente">
                <ListItemIcon>
                  <PersonAddIcon />
                </ListItemIcon>
                <ListItemText primary="Passo a Passo: Incluindo Pacientes" />
              </ListItem>
              <ListItem button component={RouterLink} to="/tutoriais/utilizar-como-paciente">
                <ListItemIcon>
                  <AccountBoxIcon />
                </ListItemIcon>
                <ListItemText primary="Adicione sua Própria Conta como Paciente" />
              </ListItem>
            </List>
          </div>
        </CardT2P2>
      </LayoutTextual.Paragrafo>
    </LayoutTextual>
  );
}
