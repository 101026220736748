import React from 'react';
import { observer } from 'mobx-react';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import Box from '@material-ui/core/Box';
import Chip from '@material-ui/core/Chip';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import ScheduleIcon from '@material-ui/icons/Schedule';
import { makeStyles } from '@material-ui/core/styles';
import LoadingBalls from '../LoadingBalls';
import useStores from '../../hooks/useStores';
import CheckIcon from '@material-ui/icons/Check';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import NotificationsActiveOutlinedIcon from '@material-ui/icons/NotificationsActiveOutlined';
import { capitalizeWords } from '../PureJSUtils';
import SituacaoPagamento from './SituacaoAssinatura';
import currency from 'currency.js';

export default observer(CardAssinatura);

const useStyles = makeStyles(theme => ({
  planoBox: {
    borderWidth: 1,
    border: 'dashed',
    borderColor: theme.palette.grey[500],
    backgroundColor: '#cbfbe7',
    paddingTop: '0.5rem',
    paddingBottom: '0.5rem',
    marginTop: '0.5rem',
    marginBottom: '0.25rem'
  },
  planoTitle: {
    fontFamily: 'Source Sans Pro',
    fontWeight: 'bold',
    fontSize: '1.85rem',
    paddingBottom: '0.15rem'
  },
  planoSubtitle: {
    fontFamily: 'Source Sans Pro',
    fontSize: '0.85rem'
  },
  condicoesDesconto: {
    fontWeight: 'bold',
    fontSize: '8pt'
  },
  textoPagamentoCard: {
    paddingTop: '1rem',
    fontSize: '1rem'
  },
  textoPagamentoIcon: {
    paddingTop: '1rem',
    textAlign: 'center'
  }
}));

function CardAssinatura(props) {
  const classes = useStyles();
  const { assinaturaStore } = useStores();
  const assinatura = assinaturaStore.assinatura;
  const isLoading = assinaturaStore.isLoading();
  const { usuario } = props;
  const usuarioPossuiCpf = !!usuario.cpf;

  const assinaturaAtiva =
    assinatura &&
    (assinatura.situacao === SituacaoPagamento.ATIVO || assinatura.situacao === SituacaoPagamento.CHECKOUT_CONCLUIDO)
      ? true
      : false;

  const possuiInformacoesPagamento = assinatura.bandeiraCartao && assinatura.ultimosDigitosCartao;

  const ConteudoCardAssinatura = () => {
    if (isLoading) {
      return (
        <Grid container spacing={3}>
          <Grid item xs={12} md={12}>
            <LoadingBalls />
          </Grid>
        </Grid>
      );
    }

    return (
      <>
        <Box className={classes.planoBox}>
          <Typography component="h1" variant="h5" align="center" className={classes.planoTitle}>
            {assinatura.nomeProduto}
          </Typography>
          {assinatura.cupom && (
            <Typography component="p" variant="subtitle1" align="center" className={classes.planoSubtitle}>
              Desconto especial de <b>{getValorDesconto(assinatura.cupom)}</b> por{' '}
              <b>{assinatura.cupom.duracaoMeses} meses</b>*.
            </Typography>
          )}
        </Box>
        <CardContent>
          <Grid container spacing={3}>
            <ChipStatusAssinatura />
            <Grid item xs={12} md={12}>
              <Typography component="p" variant="h6">
                Pagamento
              </Typography>
              <TextoStatusAssinatura />
              <Grid
                item
                xs={12}
                md={12}
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  marginTop: '1.5rem',
                  marginBottom: '1.5rem'
                }}>
                <ContainerAssinatura />
              </Grid>
            </Grid>
          </Grid>
          {assinatura.cupom && (
            <Typography component="p" variant="subtitle" align="center" className={classes.condicoesDesconto}>
              * Oferta válida somente para assinaturas realizadas na Plataforma do Psiquê. Não aplicável a assinaturas
              realizadas pela App Store ou Play Store.
            </Typography>
          )}
        </CardContent>
      </>
    );
  };

  const ChipStatusAssinatura = () => {
    if (assinaturaAtiva) {
      return (
        <Grid item xs={12} md={12} style={{ display: 'flex', justifyContent: 'center' }}>
          <Chip icon={<CheckIcon />} label="Sua assinatura está ativa!" color="primary" />
        </Grid>
      );
    } else {
      if (assinatura.isPeriodoGratis) {
        if (assinatura.diasRestantesPeriodoGratis > 7) {
          return (
            <Grid item xs={12} md={12} style={{ display: 'flex', justifyContent: 'center' }}>
              <Chip
                icon={<ScheduleIcon />}
                label={`Você tem ${assinatura.diasRestantesPeriodoGratis} dias grátis para avaliação`}
              />
            </Grid>
          );
        } else {
          return (
            <Grid item xs={12} md={12} style={{ display: 'flex', justifyContent: 'center' }}>
              <Chip
                icon={<NotificationsActiveOutlinedIcon style={{ color: '#1f2937' }} />}
                style={{ backgroundColor: '#ffb74d', color: '#1f2937' }}
                label={getTextoDiasRestantes()}
              />
            </Grid>
          );
        }
      } else {
        if (assinatura.situacao === SituacaoPagamento.INICIAL) {
          return (
            <Grid item xs={12} md={12} style={{ display: 'flex', justifyContent: 'center' }}>
              <Chip
                icon={<ErrorOutlineIcon style={{ color: 'white' }} />}
                style={{ backgroundColor: '#d32f2f', color: 'white' }}
                label={`O seu período de testes expirou`}
              />
            </Grid>
          );
        } else if (
          assinatura.situacao === SituacaoPagamento.CANCELADO ||
          assinatura.situacao === SituacaoPagamento.CANCELAMENTO_SOLICITADO
        ) {
          return (
            <Grid item xs={12} md={12} style={{ display: 'flex', justifyContent: 'center' }}>
              <Chip
                icon={<ErrorOutlineIcon style={{ color: 'white' }} />}
                style={{ backgroundColor: '#d32f2f', color: 'white' }}
                label={`A sua assinatura foi cancelada`}
              />
            </Grid>
          );
        } else {
          return (
            <Grid item xs={12} md={12} style={{ display: 'flex', justifyContent: 'center' }}>
              <Chip
                icon={<ErrorOutlineIcon style={{ color: 'white' }} />}
                style={{ backgroundColor: '#d32f2f', color: 'white' }}
                label={`Ocorreu um erro ao processar sua assinatura`}
              />
            </Grid>
          );
        }
      }
    }
  };

  const TextoStatusAssinatura = () => {
    if (assinatura.isPeriodoGratis) {
      return (
        <>
          <Typography component="p" variant="subtitle1" className={classes.textoPagamentoCard}>
            O seu período de avaliação termina em <b>{assinatura.dataFimPeriodoGratis}</b>.
          </Typography>
          {assinatura.situacao === 'inicial' && (
            <Typography component="p" variant="subtitle1" className={classes.textoPagamentoCard}>
              Utilize o botão abaixo para configurar seu pagamento. O seu cartão só será cobrado ao final do período de
              testes.
            </Typography>
          )}
          {assinatura.situacao === 'cancelamento-solicitado' && (
            <Typography component="p" variant="subtitle1" className={classes.textoPagamentoCard}>
              Você solicitou o cancelamento da assinatura. Seu cartão <b>não será</b> cobrado ao final do período de
              testes.
            </Typography>
          )}
          {assinaturaAtiva && !possuiInformacoesPagamento && (
            <Typography component="p" variant="subtitle1" className={classes.textoPagamentoCard}>
              Seu cartão será cobrado ao final do seu período de testes.
            </Typography>
          )}
          {assinaturaAtiva && possuiInformacoesPagamento && (
            <Typography component="p" variant="subtitle1" className={classes.textoPagamentoCard}>
              Ao final do período de testes, o seu cartão <b>{capitalizeWords(assinatura.bandeiraCartao)}</b> final{' '}
              <b>{assinatura.ultimosDigitosCartao}</b> será cobrado automáticamente.
            </Typography>
          )}
        </>
      );
    } else {
      return (
        <>
          {assinaturaAtiva && !possuiInformacoesPagamento && (
            <Typography component="p" variant="subtitle1" className={classes.textoPagamentoCard}>
              Obrigado por assinar o Psiquê! O seu cartão será cobrado automáticamente.
            </Typography>
          )}
          {assinaturaAtiva && possuiInformacoesPagamento && (
            <Typography component="p" variant="subtitle1" className={classes.textoPagamentoCard}>
              Obrigado por assinar o Psiquê! O seu cartão <b>{capitalizeWords(assinatura.bandeiraCartao)}</b> final{' '}
              <b>{assinatura.ultimosDigitosCartao}</b> será cobrado automáticamente.
            </Typography>
          )}
          {assinatura.situacao === SituacaoPagamento.INICIAL && (
            <>
              <Typography component="p" variant="subtitle1" className={classes.textoPagamentoCard}>
                O seu período de avaliação terminou em <b>{assinatura.dataFimPeriodoGratis}</b>.
              </Typography>
              <Typography component="p" variant="subtitle1" className={classes.textoPagamentoCard}>
                Para continuar utilizando o Psiquê, por favor configure uma forma de pagamento.
              </Typography>
            </>
          )}
          {assinatura.situacao === SituacaoPagamento.CANCELAMENTO_SOLICITADO && (
            <>
              <Typography component="p" variant="subtitle1" className={classes.textoPagamentoCard}>
                Você solicitou o cancelamento da assinatura. Seu acesso estará disponível até o final do período de
                faturamento atual.
              </Typography>
            </>
          )}
          {assinatura.situacao === SituacaoPagamento.CANCELADO && (
            <>
              <Typography component="p" variant="subtitle1" className={classes.textoPagamentoCard}>
                A sua assinatura foi cancelada. Para continuar utilizando o Psiquê, você precisa configurar novamente
                uma forma de pagamento.
              </Typography>
            </>
          )}
        </>
      );
    }
  };

  const ContainerAssinatura = () => {
    const possuiAssinaturaAtivaEmLoja =
      assinatura.plataformaPagamento !== 'stripe' &&
      (assinatura.situacao === SituacaoPagamento.ATIVO ||
        assinatura.situacao === SituacaoPagamento.CANCELAMENTO_SOLICITADO);

    if (possuiAssinaturaAtivaEmLoja) {
      return (
        <Grid className={classes.planoBox}>
          <Typography component="p" style={{ textAlign: 'center' }}>
            Você pode gerenciar sua assinatura pela loja de aplicativos no seu celular.
          </Typography>
        </Grid>
      );
    }

    if (usuarioPossuiCpf) {
      return (
        <>
          <BotaoAssinatura />
          <Typography
            component="p"
            style={{
              fontSize: '0.8rem',
              textAlign: 'center',
              marginTop: '0.75rem',
              color: '#4b5563'
            }}>
            Você será direcionado para o ambiente seguro do Stripe, o nosso parceiro de pagamentos.
          </Typography>
        </>
      );
    }

    return (
      <>
        <BotaoAssinatura />
        <Typography
          component="p"
          style={{
            fontSize: '0.8rem',
            textAlign: 'center',
            marginTop: '0.75rem',
            color: '#4b5563',
            fontWeight: 'bold'
          }}>
          Informe o seu CPF, utilizando o formulário nesta página, para poder realizar a assinatura.
        </Typography>
      </>
    );
  };

  const BotaoAssinatura = () => {
    if (assinatura.situacao === SituacaoPagamento.INICIAL || assinatura.situacao === SituacaoPagamento.CANCELADO) {
      return (
        <Button color="primary" variant="contained" onClick={assinaturaStore.iniciarCheckout} disabled={!usuario.cpf}>
          Configurar Pagamento
        </Button>
      );
    }

    return (
      <Button onClick={assinaturaStore.iniciarPortal} variant="contained" color="primary">
        Gerenciar Assinatura
      </Button>
    );
  };

  const getTextoDiasRestantes = () => {
    if (assinatura.diasRestantesPeriodoGratis === 0) {
      return 'Seu período de testes termina hoje';
    } else if (assinatura.diasRestantesPeriodoGratis === 1) {
      return 'Seu período de testes termina amanhã';
    } else {
      return `Faltam ${assinatura.diasRestantesPeriodoGratis} dias para o fim do teste`;
    }
  };

  const currencyOptions = { decimal: ',', symbol: '' };

  const getValorDesconto = cupom => {
    if (cupom.tipoDesconto === 'percentual') {
      return `${cupom.valorDesconto}%`;
    } else if (cupom.tipoDesconto === 'fixo') {
      return `R$\u00A0${currency(cupom.valorDesconto, currencyOptions).format()}`;
    }
    return cupom.valorDesconto;
  };

  return (
    <Card>
      <CardHeader title="Assinatura" titleTypographyProps={{ variant: 'h6' }} style={{ paddingBottom: '0px' }} />
      <ConteudoCardAssinatura />
    </Card>
  );
}
