import React, { useRef } from 'react';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import CardT2P2 from '../components/CardT2P2';
import LineChartPacientesDiarios from '../components/PainelPage/LineChartPacientesDiarios';
import TabelaPacientes from '../components/PainelPage/TabelaPacientes';
import TabelaDiarios from '../components/PainelPage/TabelaDiarios';
import CategoriasMenu from '../config/CategoriasMenu';
import useStores from '../hooks/useStores';
import { observer } from 'mobx-react';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Link from '@material-ui/core/Link';
import { Link as RouterLink } from 'react-router-dom';
import LayoutTextual from '../layout/LayoutTextual';
import Hidden from '@material-ui/core/Hidden';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import NewReleasesIcon from '@material-ui/icons/NewReleasesOutlined';
import LinkEmailSuporte from '../components/LinkEmailSuporte';
import LinkWhatsAppSuporte from '../components/LinkWhatsAppSuporte';
import ListaDiariosRecentes from '../components/PainelPage/ListaDiariosRecentes';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  desktopOnly: {
    display: 'none',
    [theme.breakpoints.up('md')]: {
      display: 'flex'
    }
  },
  horizontalList: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    justifyContent: 'space-around'
  },
  menuCard: {
    width: '100%',
    height: 60,
    padding: 0
  },
  gridTextoAjuda: {
    justifyContent: 'center',
    textAlign: 'center'
  },
  botaoNovidades: {
    marginTop: '-0.25rem',
    marginBottom: '1.25rem'
  }
}));

export default observer(PainelPage);

function PainelPage(props) {
  const classes = useStyles();
  const store = useStores();
  const usuario = store.usuarioStore.usuario;
  const tratamentoStore = store.tratamentoStore;
  const itensMenu = store.interfaceStore.menus.menuLateral.itens;
  const novidadesRef = useRef(null);
  const scrollToNovidades = () => novidadesRef.current.scrollIntoView({ behavior: 'smooth', block: 'end' });

  const mostrarBoasVindas = (function() {
    if (
      tratamentoStore.statusCarregamento.listaPacientes === true &&
      tratamentoStore.statusCarregamento.listaPacientesPendentes === true
    ) {
      if (
        tratamentoStore.tratamento.pacientes.length === 0 &&
        tratamentoStore.tratamento.pacientesPendentes.length === 0
      ) {
        return true;
      }
    }
    return false;
  })();

  store.interfaceStore.setHideTitle(mostrarBoasVindas);

  return (
    <Container component="main" maxWidth="lg">
      <div className={classes.root}>
        <Hidden mdUp>
          <Button
            onClick={scrollToNovidades}
            variant="outlined"
            color="primary"
            size="small"
            className={classes.botaoNovidades}
            startIcon={<NewReleasesIcon />}>
            Confira as novidades do Psiquê
          </Button>
        </Hidden>
        <Grid container spacing={3}>
          {mostrarBoasVindas && (
            <Grid item xs={12} style={{ padding: '1.2rem' }}>
              <LayoutTextual
                title={`Olá, ${usuario.nome.split(' ')[0]}!`}
                iconUrl="/images/icon_boas_vindas.svg"
                iconAltText="Boas Vindas!">
                <LayoutTextual.Paragrafo>
                  Para começar a utilizar o Psiquê, precisamos adicionar os seus pacientes.{' '}
                  <Link component={RouterLink} to="/tutoriais/inclusao-paciente">
                    Clique aqui
                  </Link>{' '}
                  para acessar o tutorial que criamos para te auxiliar.
                </LayoutTextual.Paragrafo>
                <LayoutTextual.Paragrafo>
                  Você também pode adicionar a sua própria conta como paciente, para explorar os recursos e
                  funcionalidades do Psiquê.{' '}
                  <Link component={RouterLink} to="/tutoriais/utilizar-como-paciente">
                    Veja aqui como fazer
                  </Link>
                  .
                </LayoutTextual.Paragrafo>
              </LayoutTextual>
            </Grid>
          )}
          <Grid item xs={12} md={3}>
            <Grid container spacing={3}>
              <Grid item xs={12} md={12}>
                <CardT2P2 tipo="tabela" titulo="Pacientes" menor>
                  <TabelaPacientes />
                </CardT2P2>
              </Grid>
              <Grid item xs={12} md={12}>
                <CardT2P2 tipo="tabela" titulo="Diários" menor>
                  <TabelaDiarios />
                </CardT2P2>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} md={9}>
            <Grid container spacing={3}>
              <Grid item md={12} className={classes.desktopOnly}>
                <CardT2P2 className={classes.menuCard}>
                  <List className={classes.horizontalList}>
                    {itensMenu.length > 0 &&
                      itensMenu[CategoriasMenu.PACIENTES].map(itemMenu => {
                        return (
                          <Link
                            key={`menu-laterial-${itemMenu.nome}`}
                            component={RouterLink}
                            to={itemMenu.path}
                            color="inherit"
                            underline="none">
                            <ListItem button>
                              <ListItemIcon>{itemMenu.icone}</ListItemIcon>
                              <ListItemText primary={itemMenu.nome} />
                            </ListItem>
                          </Link>
                        );
                      })}
                  </List>
                </CardT2P2>
              </Grid>
              <Grid item xs={12} md={12}>
                <CardT2P2 tipo="grafico" titulo="Pacientes e Diários: últimos 7 dias" fixed>
                  <LineChartPacientesDiarios />
                </CardT2P2>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <CardT2P2 tipo="tabela" titulo="Novos Diários" menor>
              <ListaDiariosRecentes />
            </CardT2P2>
          </Grid>
          <Grid ref={novidadesRef} item xs={12}>
            <CardT2P2 titulo="Novidades no Psiquê!">
              <Typography variant="body2" style={{ fontWeight: 'bold', marginBottom: '0.25rem' }}>
                Desafio de Pensamentos
              </Typography>
              <Typography variant="body2">
                Agora o seu paciente pode desafiar os pensamentos automáticos pelo aplicativo do Psiquê! Na listagem de
                registros do paciente, você verá um indicativo no registro quando houver algum pensamento desafiado, e
                ao expandir o registro poderá ver os detalhes do desafio.
              </Typography>
            </CardT2P2>
          </Grid>
          <Grid item xs={12} md={12} className={classes.gridTextoAjuda}>
            Está com algum problema ou dúvida? Envie um e-mail para <LinkEmailSuporte /> ou pelo WhatsApp{' '}
            <LinkWhatsAppSuporte /> que iremos te ajudar!
          </Grid>
        </Grid>
      </div>
    </Container>
  );
}
