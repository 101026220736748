import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import useStores from '../hooks/useStores';
import { observer } from 'mobx-react';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import LoadingBalls from '../components/LoadingBalls';
import { BotaoIncluirPaciente } from '../components/IncluirPacientePage/BotaoIncluirPaciente';
import { BotaoPequenoRemoverPaciente } from '../components/RemoverPacientePage/BotaoPequenoRemoverPaciente';
import LayoutTextual from '../layout/LayoutTextual';
import Link from '@material-ui/core/Link';
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';
import { Link as RouterLink } from 'react-router-dom';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%'
  },
  table: {
    minWidth: 650
  },
  title: {
    width: '100%'
  }
}));

export default observer(PacientesPendentesPage);

function PacientesPendentesPage(props) {
  const store = useStores();
  const classes = useStyles();
  const [colunaSort, setColunaSort] = useState('qtdNaoVisualizado');
  const [sortDirection, setSortDirection] = useState('desc');
  const [isLoading, setLoading] = useState(true);

  const rows = [];

  store.tratamentoStore.tratamento.pacientesPendentes.forEach(p => {
    rows.push({ id: p.id, nome: p.nome, email: p.email, type: 'p' });
  });
  store.tratamentoStore.tratamento.convitesEnviados.forEach((c, index) => {
    rows.push({
      id: `c${c.id}`,
      nome: c.nomePaciente,
      email: c.emailPaciente,
      type: 'c'
    });
  });

  useEffect(() => {
    (async function() {
      await store.tratamentoStore.listarTratamentosPendentes(store.usuarioStore.usuario.id);
      await store.tratamentoStore.listarConvitesEnviados(store.usuarioStore.usuario.id);
      setLoading(false);
    })();
  }, [store]);

  const handleSetColuna = x => {
    setColunaSort(x);
    if (colunaSort === x) {
      sortDirection === 'asc' ? setSortDirection('desc') : setSortDirection('asc');
    }
  };

  function somaNumerosColuna(nomeColuna) {
    let valorSoma = 0;
    Array.from(rows).map(row => (valorSoma += isNaN(row[nomeColuna]) ? 1 : row[nomeColuna]));
    return valorSoma;
  }

  function comparaSort(a, b) {
    if (a[colunaSort] < b[colunaSort]) {
      return sortDirection === 'asc' ? -1 : 1;
    } else {
      return sortDirection === 'asc' ? 1 : -1;
    }
  }

  if (isLoading) {
    return <LoadingBalls />;
  }

  if (rows.length === 0) {
    store.interfaceStore.setHideTitle(true);
    return (
      <LayoutTextual
        title={`Nenhum paciente pendente`}
        iconUrl="/images/icon_empty.svg"
        iconAltText="Nenhum paciente encontrado">
        <LayoutTextual.Paragrafo>
          Você não possui nenhum paciente pendente no momento. Gostaria de incluir um paciente agora?
        </LayoutTextual.Paragrafo>
        <LayoutTextual.Paragrafo>
          <Box item style={{ display: 'flex', justifyContent: 'center', marginBottom: '1rem', marginTop: '0.5rem' }}>
            <Button color="primary" variant="contained" component={RouterLink} to="/pacientes/novo">
              Incluir Paciente
            </Button>
          </Box>
        </LayoutTextual.Paragrafo>
        <LayoutTextual.Paragrafo variant="subtitle2">
          Se estiver com dificuldades para incluir seus pacientes, nós preparamos um tutorial especial para te auxiliar
          na inclusão, você pode acessar ele{' '}
          <Link to="/tutoriais/inclusao-paciente" component={RouterLink}>
            clicando aqui
          </Link>
          , ou a qualquer momento pelo menu principal.
        </LayoutTextual.Paragrafo>
      </LayoutTextual>
    );
  }

  return (
    <Container component="main" maxWidth="lg">
      <div className={classes.root}>
        <Paper>
          <Toolbar>
            <Typography className={classes.title} />
            <BotaoIncluirPaciente />
          </Toolbar>
          <TableContainer>
            <Table className={classes.table} aria-label="simple table">
              <TableHead>
                <TableRow hover>
                  <TableCell>
                    <TableSortLabel
                      active={'nome' === colunaSort}
                      direction={sortDirection}
                      onClick={x => handleSetColuna('nome')}>
                      Pacientes Pendentes ({somaNumerosColuna('nome')})
                    </TableSortLabel>
                  </TableCell>
                  <TableCell align="left">
                    <TableSortLabel
                      active={'email' === colunaSort}
                      direction={sortDirection}
                      onClick={x => handleSetColuna('email')}>
                      Email
                    </TableSortLabel>
                  </TableCell>
                  <TableCell align="right">
                    <TableSortLabel
                      active={'dataInicio' === colunaSort}
                      direction={sortDirection}
                      onClick={x => handleSetColuna('dataInicio')}>
                      Status
                    </TableSortLabel>
                  </TableCell>
                  <TableCell />
                </TableRow>
              </TableHead>
              <TableBody>
                {Array.from(rows)
                  .sort(comparaSort)
                  .map(row => (
                    <TableRow key={`${row.type}${row.id}`} hover>
                      <TableCell component="th" scope="row">
                        {row.nome}
                      </TableCell>
                      <TableCell align="left">{row.email}</TableCell>
                      <TableCell align="right">
                        {row.type === 'p' ? 'Aguardando confirmação' : 'Convite enviado'}
                      </TableCell>
                      <TableCell align="right">
                        {row.type === 'p' && <BotaoPequenoRemoverPaciente idPaciente={row.id} />}
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>
      </div>
    </Container>
  );
}
