import { useEffect } from 'react';
import { observer } from 'mobx-react';
import useStores from '../hooks/useStores';
import { useLocation } from 'react-router-dom';
import { matchPath } from 'react-router-dom';

export default observer(SetPageTitle);

function SetPageTitle(props) {
  const store = useStores();
  const location = useLocation();

  useEffect(() => {
    let titulo = null;

    props.routes.forEach(rota => {
      const match = matchPath(location.pathname, {
        path: rota.route.path,
        exact: true,
        strict: false
      });
      if (match) {
        titulo = rota.pageTitle;
        store.interfaceStore.setTitle(titulo);
      }
    });

    if (titulo == null) {
      store.interfaceStore.setTitle('');
    }
  });
  return null;
}
