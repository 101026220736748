import React from 'react';
import Link from '@material-ui/core/Link';

export default LinkWhatsAppSuporte;

function LinkWhatsAppSuporte() {
  return (
    <Link href="https://wa.me/5561999566910" target="_blank">
      (61)&nbsp;99956-6910
    </Link>
  );
}
