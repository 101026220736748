import React from 'react';
import { observer } from 'mobx-react';
import useStores from '../hooks/useStores';
import LinearProgress from '@material-ui/core/LinearProgress';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  root: {
    position: 'fixed',
    height: '4px',
    width: '100%',
    zIndex: 999,
    top: 56,
    [`${theme.breakpoints.up('xs')} and (orientation: landscape)`]: {
      top: 48
    },
    [theme.breakpoints.up('sm')]: {
      top: 64
    }
  }
}));

export default observer(LoadingBar);

function LoadingBar(props) {
  const store = useStores();
  const classes = useStyles();

  return (
    <div className={classes.root}>
      {store.interfaceStore.interface.isLoading && <LinearProgress className={classes.root} color="secondary" />}
    </div>
  );
}
