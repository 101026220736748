import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import { makeStyles } from '@material-ui/core/styles';
import useStores from '../hooks/useStores';
import queryString from 'query-string';
import { useLocation } from 'react-router-dom';
import { InputSenhaLogin } from '../components/Input';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Link from '@material-ui/core/Link';
import { Link as RouterLink } from 'react-router-dom';
import { Redirect } from 'react-router-dom';
import LoadingBalls from '../components/LoadingBalls';
import PsiqueAvatar from '../components/PsiqueAvatar';

const useStyles = makeStyles(theme => ({
  paper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  alert: {
    marginTop: theme.spacing(1),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  nomePagina: {
    color: theme.palette.primary.main
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(3)
  },
  submit: {
    margin: theme.spacing(3, 0, 2)
  },
  progress: {
    position: 'fixed',
    zIndex: 1200,
    top: '0',
    left: '0',
    width: '100%'
  }
}));

export default observer(ConfirmarEmailPage);

function ConfirmarEmailPage() {
  const classes = useStyles();
  const location = useLocation();
  const store = useStores();
  const [senha, setSenha] = useState('');
  const [erroConfirmacao, setErroConfirmacao] = useState(false);
  const [erroParseUrl, setErroParseUrl] = useState(false);
  const [emailJaConfirmado, setEmailJaConfirmado] = useState(false);
  const [isLoading, setLoading] = useState(true);

  let id = ' ';
  let codigoConfirmacao = ' ';
  let tokenProvisorio = ' ';

  if (!erroParseUrl) {
    try {
      const values = queryString.parse(location.search);
      const jsonB64 = values.p;
      const jsonAsc = JSON.parse(window.atob(jsonB64));
      id = jsonAsc.id;
      codigoConfirmacao = jsonAsc.codigoConfirmacao;
      tokenProvisorio = window.localStorage.getItem('psiqueEmailConfirmar' + id);
    } catch (err) {
      setErroParseUrl(true);
    }
  }

  useEffect(() => {
    (async function() {
      if (!erroParseUrl) {
        const RC = await store.usuarioStore.validarLinkEmailConfirmacao(id, codigoConfirmacao);
        if (RC.success) {
          if (store.usuarioStore.usuario.emailConfirmado) {
            setEmailJaConfirmado(true);
          }
          if (!store.usuarioStore.usuario.codigoCorreto) {
            setErroConfirmacao(true);
          }

          if (
            tokenProvisorio &&
            store.usuarioStore.usuario.codigoCorreto &&
            !store.usuarioStore.usuario.emailConfirmado
          ) {
            const RC = await store.usuarioStore.confirmarEmail(tokenProvisorio, codigoConfirmacao, id, '');
            if (!RC.success) {
              setErroConfirmacao(true);
            }
          }
        } else {
          setErroConfirmacao(true);
        }
      }
      setLoading(false);
    })();
  }, [codigoConfirmacao, id, store, tokenProvisorio, erroParseUrl]);

  const _handleSubmitConfirmarEmail = async event => {
    event.preventDefault();
    const RC = await store.usuarioStore.confirmarEmail('', codigoConfirmacao, id, senha);
    if (!RC.success) {
      setErroConfirmacao(true);
    }
  };

  if (isLoading) {
    return <LoadingBalls />;
  }

  if (store.usuarioStore.sessao.loggedIn) {
    return <Redirect to="/painel" />;
  }

  if (emailJaConfirmado) {
    return (
      <Container component="main" maxWidth="md" className={classes.paper}>
        <Grid container spacing={5} className={classes.paper}>
          <Grid item xs={12}>
            <PsiqueAvatar />
          </Grid>
          <Grid item xs={12}>
            <Typography component="h1" variant="h5">
              Seu email já havia sido confirmado.
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography component="h1" variant="h6">
              {'Clique '}
              <Link component={RouterLink} to="/login" variant="h6">
                aqui
              </Link>
              {' para acessar sua conta.'}
            </Typography>
          </Grid>
        </Grid>
      </Container>
    );
  }

  if (erroConfirmacao || erroParseUrl) {
    return (
      <Container component="main" maxWidth="md" className={classes.paper}>
        <Grid container spacing={5} className={classes.paper}>
          <Grid item xs={12}>
            <PsiqueAvatar />
          </Grid>
          <Grid item xs={12}>
            <Typography component="h1" variant="h5">
              Link de confirmação de email do cadastramento expirado ou incorreto.
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography component="h1" variant="h6">
              Tente acessar o link mais recente recebido no seu email novamente.
            </Typography>
          </Grid>
        </Grid>
      </Container>
    );
  }

  if (tokenProvisorio) {
    return (
      <Container component="main" maxWidth="md" className={classes.paper}>
        <PsiqueAvatar />
        <Typography component="h1" variant="h5">
          Estamos confirmando seu email...
        </Typography>
      </Container>
    );
  }

  return (
    <Container component="main" maxWidth="md" className={classes.paper}>
      <PsiqueAvatar />
      <Typography component="h1" variant="h5">
        Digite sua senha para confirmar seu email
      </Typography>
      <Container className={classes.paper} maxWidth="xs">
        <form className={classes.form} noValidate onSubmit={_handleSubmitConfirmarEmail}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <InputSenhaLogin label="Senha" onChange={e => setSenha(e.target.value)} />
            </Grid>
          </Grid>
          <Button type="submit" fullWidth variant="contained" color="primary" className={classes.submit}>
            Confirmar email
          </Button>
          <Grid container>
            <Grid item xs>
              <Link component={RouterLink} to="/login/esqueci" variant="body2">
                Esqueçeu sua senha?
              </Link>
            </Grid>
            <Grid item>
              <Link component={RouterLink} to="/cadastro" variant="body2">
                {'Não possui conta? Cadastre-se'}
              </Link>
            </Grid>
          </Grid>
        </form>
      </Container>
    </Container>
  );
}
