import React from 'react';
import Link from '@material-ui/core/Link';

export default LinkEmailSuporte;

function LinkEmailSuporte() {
  return (
    <Link href="mailto:suporte@psique.app" target="_blank">
      suporte@psique.app
    </Link>
  );
}
