import React from 'react';
import Drawer from '@material-ui/core/Drawer';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import PsiqueAvatar from './PsiqueAvatar';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import useStores from '../hooks/useStores';
import { observer } from 'mobx-react';
import ItensMenuLateral from './ItensMenuLateral';
import Tooltip from '@material-ui/core/Tooltip';
import GitInfo from 'react-git-info/macro';

const useStyles = makeStyles(theme => ({
  header: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    marginBottom: theme.spacing(2)
  },
  textoNome: {
    fontWeight: 700
  },
  textoEmail: {
    color: theme.palette.grey[700]
  },
  drawerPaper: {
    minWidth: 250,
    width: '15%',
    [theme.breakpoints.down('xs')]: {
      width: '80%'
    }
  },
  drawerContainer: {
    overflow: 'auto'
  },
  drawerCloseButton: {
    position: 'absolute',
    zIndex: theme.zIndex.drawer + 1
  },
  versionText: {
    marginTop: 'auto',
    padding: '1rem'
  }
}));
function MenuLateral(props) {
  const { window } = props;
  const store = useStores();
  const classes = useStyles();
  const theme = useTheme();
  const gitInfo = GitInfo();

  const container = window !== undefined ? () => window().document.body : undefined;

  if (!store.usuarioStore.sessao.loggedIn) {
    return null;
  }

  return (
    <nav className={classes.drawer} aria-label="menu do aplicativo">
      <Drawer
        container={container}
        variant="temporary"
        anchor={theme.direction === 'rtl' ? 'right' : 'left'}
        open={store.interfaceStore.menus.menuLateral.isOpen}
        onClose={store.interfaceStore.menus.menuLateral.toggle}
        classes={{
          paper: classes.drawerPaper
        }}
        ModalProps={{
          keepMounted: true
        }}>
        <IconButton className={classes.drawerCloseButton} onClick={store.interfaceStore.menus.menuLateral.toggle}>
          <CloseIcon fontSize="small" />
        </IconButton>
        <Toolbar className={classes.header}>
          <PsiqueAvatar />
          <div className={classes.textoNome}>{formatarNomeMenu(store.usuarioStore.usuario.nome)}</div>
          <div className={classes.textoEmail}>{store.usuarioStore.usuario.email}</div>
        </Toolbar>
        <ItensMenuLateral />
        <Box className={classes.versionText}>
          <Tooltip title={gitInfo.commit.shortHash} placement="top" arrow>
            <Typography color="textSecondary" align="center" variant="subtitle2">
              v{process.env.REACT_APP_VERSION}
            </Typography>
          </Tooltip>
        </Box>
      </Drawer>
    </nav>
  );
}

const maxLengthNomeMenu = 26;
function formatarNomeMenu(nomeCompleto) {
  if (nomeCompleto.length <= maxLengthNomeMenu) {
    return nomeCompleto;
  }

  const partes = nomeCompleto.split(' ');
  if (partes.length > 1) {
    const nomeComUltimoSobrenome = `${partes[0]} ${partes[partes.length - 1]}`;
    if (nomeComUltimoSobrenome.length <= maxLengthNomeMenu) {
      return nomeComUltimoSobrenome;
    } else {
      return partes[0].length <= maxLengthNomeMenu ? partes[0] : null;
    }
  }
  return null;
}

export default observer(MenuLateral);
