import React from 'react';
import IconButton from '@material-ui/core/IconButton';
import PersonAddDisabledIcon from '@material-ui/icons/PersonAddDisabled';
import { useHistory } from 'react-router-dom';
import Tooltip from '@material-ui/core/Tooltip';

export function BotaoPequenoRemoverPaciente(props) {
  const { idPaciente } = props;
  const history = useHistory();

  const handleRemoverPaciente = idPaciente => {
    history.push(`/pacientes/${idPaciente}/remover`);
  };

  return (
    <Tooltip title="Remover Paciente">
      <IconButton size="small" onClick={x => handleRemoverPaciente(idPaciente)}>
        <PersonAddDisabledIcon fontSize="small" />
      </IconButton>
    </Tooltip>
  );
}
