import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import { useHistory } from 'react-router-dom';

const useStyles = makeStyles({
  fixedHeight: {
    height: 240
  },
  alinhaCentro: {
    alignItems: 'center'
  },
  link: {
    cursor: 'pointer'
    // '&:hover': {
    //   backgroundColor: 'inherit'
    // }
  }
});

export default function CardT2P2(props) {
  const classes = useStyles();
  const history = useHistory();

  const handleGoToPage = pagina => {
    history.push(pagina);
  };

  if (!props.tipo) {
    if (!props.titulo) {
      return (
        <Card {...props}>
          <CardContent {...props}>{props.children}</CardContent>
        </Card>
      );
    }

    return (
      <Card {...props}>
        <CardHeader title={props.titulo} titleTypographyProps={{ variant: 'h6' }} style={{ paddingBottom: '0px' }} />
        <CardContent {...props}>{props.children}</CardContent>
      </Card>
    );
  }

  if (props.tipo === 'grafico' && props.fixed) {
    return (
      <Card>
        <CardHeader title={props.titulo} />
        <CardContent className={classes.fixedHeight}>{props.children}</CardContent>
      </Card>
    );
  }

  if (props.tipo === 'grafico' && props.centered) {
    return (
      <Card>
        <CardHeader title={props.titulo} className={classes.alinhaCentro} />
        <CardContent>{props.children}</CardContent>
      </Card>
    );
  }

  if (props.tipo === 'tabela') {
    return (
      <Card onClick={props.link && (() => handleGoToPage(props.link))} className={props.link && classes.link}>
        {!props.menor && <CardHeader title={props.titulo} />}
        {props.menor && (
          <CardHeader title={props.titulo} titleTypographyProps={{ variant: 'h6' }} style={{ paddingBottom: '0px' }} />
        )}
        <CardContent>{props.children}</CardContent>
      </Card>
    );
  }
}
