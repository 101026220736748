import { observable, action } from 'mobx';
import { Returner } from '../components/PureJSUtils';
import FetchCache from '../components/FetchCache';
import AppConfig from '../config/AppConfig';

export default class EnderecoStore {
  constructor(parent) {
    this.parent = parent;
  }

  endereco = observable({ dados: '' });
  cep = observable({ dados: '' });

  reset = action(async () => {
    this.endereco.dados = '';
    this.cep.dados = '';
  });

  listarEnderecos = action(async (idDoctor, idEndereco) => {
    this.parent.interfaceStore.iniciaLoading();
    const response = await fetch(`${AppConfig.API_URL}/api/psicologos/${idDoctor}/enderecos`, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      }
    });
    this.parent.interfaceStore.finalizaLoading();
    if (response.status === 200) {
      const dados = await response.json();
      this.endereco.dados = dados;
      return Returner(true, response.status);
    } else if (response.status === 404) {
      this.endereco = [];
      return Returner(true, response.status);
    } else {
      this.parent.interfaceStore.incluiMensagem('Erro ao buscar endereços.', 'error');
      return Returner(false, response.status);
    }
  });

  cadastrarEndereco = action(async (idDoctor, cep, logradouro, numero, complemento, bairro, cidade, uf) => {
    this.parent.interfaceStore.iniciaLoading();
    const response = await fetch(
      `${AppConfig.API_URL}/api/psicologos/${idDoctor}/enderecos`,
      {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          cep: cep,
          logradouro: logradouro,
          numero: numero,
          complemento: complemento,
          bairro: bairro,
          cidade: cidade,
          uf: uf
        })
      },
      5
    );
    this.parent.interfaceStore.finalizaLoading();
    if (response.status === 201) {
      this.parent.interfaceStore.incluiMensagem('Endereço cadastrado.', 'success');
      return Returner(true, response.status);
    } else {
      this.parent.interfaceStore.incluiMensagem('Erro ao cadastrar o endereço.', 'error');
      return Returner(false, response.status);
    }
  });

  excluirEndereco = action(async (idDoctor, idEndereco) => {
    this.parent.interfaceStore.iniciaLoading();
    const response = await fetch(`${AppConfig.API_URL}/api/psicologos/${idDoctor}/enderecos/${idEndereco}`, {
      method: 'DELETE',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      }
    });
    this.parent.interfaceStore.finalizaLoading();
    if (response.status === 204) {
      this.parent.interfaceStore.incluiMensagem('Endereço excluído.', 'success');
      return Returner(true, response.status);
    } else {
      this.parent.interfaceStore.incluiMensagem('Erro ao excluir o endereço.', 'error');
      return Returner(false, response.status);
    }
  });

  alterarEndereco = action(async (idDoctor, idEndereco, cep, logradouro, numero, complemento, bairro, cidade, uf) => {
    this.parent.interfaceStore.iniciaLoading();
    const response = await fetch(
      `${AppConfig.API_URL}/api/psicologos/${idDoctor}/enderecos/${idEndereco}`,
      {
        method: 'PATCH',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          cep: cep,
          logradouro: logradouro,
          numero: numero,
          complemento: complemento,
          bairro: bairro,
          cidade: cidade,
          uf: uf
        })
      },
      5
    );
    this.parent.interfaceStore.finalizaLoading();
    if (response.status === 204) {
      this.parent.interfaceStore.incluiMensagem('Endereço alterado.', 'success');
      return Returner(true, response.status);
    } else {
      this.parent.interfaceStore.incluiMensagem('Erro ao alterar o endereço.', 'error');
      return Returner(false, response.status);
    }
  });

  buscaDadosCep = action(async cep => {
    this.parent.interfaceStore.iniciaLoading();
    const response = await FetchCache(
      'https://viacep.com.br/ws/' + cep + '/json',
      {
        method: 'GET',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json'
        }
      },
      60
    );
    this.parent.interfaceStore.finalizaLoading();
    if (response.status === 200) {
      const dados = await response.json();
      if (dados.erro) {
        this.parent.interfaceStore.incluiMensagem('Erro ao buscar dados do CEP.', 'error');
        return Returner(false, response.status);
      }
      this.cep.dados = dados;
      return Returner(true, response.status);
    } else {
      this.parent.interfaceStore.incluiMensagem('Erro ao buscar dados do CEP.', 'error');
      return Returner(false, response.status);
    }
  });
}
