import React from 'react';
import Link from '@material-ui/core/Link';
import LinkEmailSuporte from '../components/LinkEmailSuporte';
import { observer } from 'mobx-react';
import { useLocation } from 'react-router-dom';
import LayoutTextual from '../layout/LayoutTextual';
import LinkWhatsAppSuporte from '../components/LinkWhatsAppSuporte';

export default observer(NoMatchPage);

function NoMatchPage(props) {
  const location = useLocation();

  return (
    <LayoutTextual
      title="Hmm! Não há nada aqui..."
      iconUrl="/images/icon_page_not_found.svg"
      iconAltText="Página não encontrada">
      <LayoutTextual.Paragrafo>
        Não conseguimos encontrar o caminho <b>'{location.pathname}'</b>, que você tentou acessar. Verifique se digitou
        corretamente e tente novamente, ou então retorne à <Link href="/">página principal</Link> e tente acessar por
        lá.
      </LayoutTextual.Paragrafo>
      <LayoutTextual.Paragrafo>
        Se tiver qualquer problema ou dúvida, entre em contato através do e-mail <LinkEmailSuporte /> ou pelo WhatsApp
        no número <LinkWhatsAppSuporte /> que iremos te ajudar!
      </LayoutTextual.Paragrafo>
    </LayoutTextual>
  );
}
