import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Container from '@material-ui/core/Container';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import MenuIcon from '@material-ui/icons/Menu';
import MoreIcon from '@material-ui/icons/MoreVert';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import Link from '@material-ui/core/Link';
import Box from '@material-ui/core/Box';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import { observer } from 'mobx-react';
import useStores from '../hooks/useStores';
import Fade from '@material-ui/core/Fade';

const useStyles = makeStyles(theme => ({
  grow: {
    flexGrow: 1
  },
  menuButton: {
    marginRight: theme.spacing(2)
  },
  title: {
    display: 'block',
    fontSize: '2rem',
    fontFamily: '"Source Sans Pro"',
    textDecoration: 'none',
    fontWeight: 700,
    marginLeft: theme.spacing(1)
  },
  inputRoot: {
    color: 'inherit'
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 7),
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('md')]: {
      width: 200
    }
  },
  sectionDesktop: {
    display: 'none',
    [theme.breakpoints.up('md')]: {
      display: 'flex'
    }
  },
  sectionMobile: {
    display: 'flex',
    [theme.breakpoints.up('md')]: {
      display: 'none'
    }
  },
  textoNomeUsuario: {
    fontSize: 18
  },

  botaoEsquerdo: {
    position: 'absolute'
  }
}));

export default observer(NavBar);

function NavBar() {
  const location = useLocation();
  const store = useStores();
  const classes = useStyles();
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = React.useState(null);
  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);

  if (location.pathname.startsWith('/admin')) {
    return null;
  }
  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
  };

  const handleMobileMenuOpen = event => {
    setMobileMoreAnchorEl(event.currentTarget);
  };

  const menuLateralId = 'menu-usuario';
  const mobileMenuId = 'mobile-menu-sem-login';

  const renderMobileMenu = (
    <Menu
      transitionDuration={300}
      TransitionComponent={Fade}
      getContentAnchorEl={null}
      anchorEl={mobileMoreAnchorEl}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      id={mobileMenuId}
      keepMounted
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      open={isMobileMenuOpen}
      onClose={handleMobileMenuClose}>
      {/* Items de menu para usuários não logados */}
      {!store.usuarioStore.sessao.loggedIn && (
        <span>
          <Link component={RouterLink} to="/login" color="inherit" onClick={handleMobileMenuClose} underline="none">
            <MenuItem>
              <Typography variant="button">Acesse sua conta</Typography>
            </MenuItem>
          </Link>
          <Link component={RouterLink} to="/cadastro" color="inherit" onClick={handleMobileMenuClose} underline="none">
            <MenuItem>
              <Typography variant="button">Assine já!</Typography>
            </MenuItem>
          </Link>
        </span>
      )}
    </Menu>
  );

  return (
    <div className={classes.grow}>
      <AppBar position="fixed" style={{ background: 'linear-gradient(90deg, #272255 0%, #4e45aa 100%)' }}>
        <Container maxWidth="lg">
          <Toolbar>
            {store.usuarioStore.sessao.loggedIn && (
              <IconButton
                edge="start"
                className={classes.menuButton}
                color="secondary"
                onClick={store.interfaceStore.menus.menuLateral.toggle}
                aria-controls={menuLateralId}
                aria-haspopup="true">
                <MenuIcon />
              </IconButton>
            )}
            <img src="/images/psi_icon.png" alt="Psiquê" height="40vmin" align="middle" />
            <Typography className={classes.title} noWrap>
              <Link component={RouterLink} to="/" color="inherit" underline="none">
                psiquê
              </Link>
            </Typography>
            <div className={classes.grow} />
            {/* Items de menu para usuários logados */}
            {store.usuarioStore.sessao.loggedIn && (
              <div className={classes.sectionDesktop}>
                <Box ml={2} mt={1.5}>
                  <Link component={RouterLink} to="/sair" color="inherit" underline="none">
                    <MenuItem style={{ backgroundColor: 'transparent' }} disableRipple>
                      Sair
                      <ExitToAppIcon style={{ marginLeft: 7 }} />
                    </MenuItem>
                  </Link>
                </Box>
              </div>
            )}
            {/* Items de menu para usuários não logados */}
            {!store.usuarioStore.sessao.loggedIn && (
              <>
                <div className={classes.sectionDesktop}>
                  <Box ml={2} mt={1.5}>
                    <Typography variant="button">
                      <Link component={RouterLink} to="/login" color="inherit">
                        Acesse sua conta
                      </Link>
                    </Typography>
                  </Box>
                  <Box ml={2} mt={1.5}>
                    <Typography variant="button">
                      <Link component={RouterLink} to="/cadastro" color="inherit">
                        Assine já!
                      </Link>
                    </Typography>
                  </Box>
                </div>
                <div className={classes.sectionMobile}>
                  <IconButton
                    aria-label="show more"
                    aria-controls={mobileMenuId}
                    aria-haspopup="true"
                    onClick={handleMobileMenuOpen}
                    color="inherit">
                    {!store.usuarioStore.sessao.loggedIn && <MoreIcon />}
                  </IconButton>
                </div>
              </>
            )}
          </Toolbar>
        </Container>
      </AppBar>
      <Toolbar />
      {renderMobileMenu}
    </div>
  );
}
