import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles(theme => ({
  paper: {
    display: 'flex',
    flexDirection: 'column'
  }
}));

function LayoutTextual(props) {
  const classes = useStyles();
  const width = props.size === 'small' ? '75rem' : '250rem';

  return (
    <Container component="main" maxWidth="md" className={classes.paper}>
      <Container className={classes.paper} maxWidth="md">
        <Box style={{ textAlign: 'center' }}>
          <img src={props.iconUrl} alt={props.iconAltText} width={width} height="auto" />
        </Box>
        <Box mt={5} style={{ textAlign: 'center' }}>
          <Typography component="h1" variant="h5">
            {props.title}
          </Typography>
        </Box>
        {props.children}
      </Container>
    </Container>
  );
}

function Paragrafo(props) {
  const { variant, style } = props;
  return (
    <Box mt={3}>
      <Typography component="p" variant={variant} style={style}>
        {props.children}
      </Typography>
    </Box>
  );
}

function TituloSecao(props) {
  return (
    <Box mt={5}>
      <Typography component="h6" variant="h6" style={props.style}>
        {props.children}
      </Typography>
    </Box>
  );
}

LayoutTextual.Paragrafo = Paragrafo;
LayoutTextual.TituloSecao = TituloSecao;

export default LayoutTextual;
