import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import useStores from '../hooks/useStores';
import { observer } from 'mobx-react';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import { InputEmailAddPaciente } from '../components/Input';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import { useHistory } from 'react-router-dom';

const useStyles = makeStyles(theme => ({
  paper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  container: {
    paddingLeft: '0.75rem',
    paddingRight: '0.75rem'
  },
  centro: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginTop: '0.5rem',
    marginBottom: '0.5rem'
  },
  paragrafo: {
    marginBottom: '0.5rem',
    paddingRight: '0.25rem',
    paddingLeft: '0.25rem'
  },
  action: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between'
  }
}));

export default observer(function PacientesPendentesPage(props) {
  const store = useStores();
  const classes = useStyles();
  const history = useHistory();
  const [emailPaciente, setEmailPaciente] = useState(null);
  const [nomePaciente, setNomePaciente] = useState(null);
  const [pacienteIncluido, setPacienteIncluido] = useState(false);
  const [ofertarEnvioConvite, setOfertarEnvioConvite] = useState(false);
  const [conviteEnviado, setConviteEnviado] = useState(false);

  const handleIncluirPaciente = async event => {
    event.preventDefault();
    const RC = await store.tratamentoStore.iniciarTratamento(store.usuarioStore.usuario.id, emailPaciente);
    if (RC.success) {
      setPacienteIncluido(true);
    } else if (RC.httpStatus === 404) {
      setOfertarEnvioConvite(true);
    }
  };

  const handleEnviarConvite = async event => {
    event.preventDefault();
    const RC = await store.tratamentoStore.enviarConvite(store.usuarioStore.usuario.id, emailPaciente, nomePaciente);
    if (RC.success) {
      setConviteEnviado(true);
      setOfertarEnvioConvite(false);
    }
  };

  const handleIncluirOutroPaciente = async event => {
    event.preventDefault();
    setEmailPaciente(null);
    setPacienteIncluido(false);
    setOfertarEnvioConvite(false);
    setConviteEnviado(false);
  };

  const handleVisualizarPendentes = async () => {
    history.push('/pacientes/pendentes');
  };

  if (pacienteIncluido) {
    return (
      <Container component="main" maxWidth="sm" className={classes.paper}>
        <Card>
          <form noValidate onSubmit={handleIncluirOutroPaciente}>
            <CardContent>
              <Grid container spacing={2} className={classes.container}>
                <Grid item xs={12} className={classes.centro}>
                  <img src="/images/icon_inclusao_pendente.svg" alt="Incluir Paciente" height="120rem" />
                </Grid>
                <Grid item xs={12} className={classes.centro}>
                  <Typography variant="body1">Solicitação enviada com sucesso!</Typography>
                  <Typography component="br" />
                  <Typography component="br" />
                </Grid>
                <Grid item xs={12} className={classes.centro}>
                  <Typography variant="subtitle2">
                    Nome: {store.tratamentoStore.tratamento.pacienteIncluido.nome}
                  </Typography>
                  <Typography variant="subtitle2">
                    E-mail: {store.tratamentoStore.tratamento.pacienteIncluido.email}
                  </Typography>
                </Grid>
                <Grid item xs={12} className={classes.centro}>
                  <Typography component="br" />
                  <Typography component="br" />
                  <Typography variant="subtitle2">
                    Estamos quase lá! Agora, basta solicitar ao paciente que efetue confirmação da inclusão pelo
                    aplicativo.
                  </Typography>
                  <Typography component="br" />
                </Grid>
              </Grid>
            </CardContent>
            <CardActions className={classes.action}>
              <Button variant="text" color="primary" onClick={handleVisualizarPendentes}>
                Consultar Pendentes
              </Button>
              <Button type="submit" variant="text" color="primary" className={classes.submit}>
                Incluir Outro
              </Button>
            </CardActions>
          </form>
        </Card>
      </Container>
    );
  }

  if (ofertarEnvioConvite) {
    return (
      <Container component="main" maxWidth="sm" className={classes.paper}>
        <Card>
          <form noValidate onSubmit={handleEnviarConvite}>
            <CardContent>
              <Grid container spacing={2}>
                <Grid item xs={12} className={classes.centro}>
                  <Typography variant="h6">Usuário não encontrado. Convidar?</Typography>
                </Grid>
                <Grid item xs={12} className={classes.centro}>
                  <img src="/images/icon_enviar_convite_tratamento.svg" alt="Enviar Convite" height="120rem" />
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="body2" className={classes.paragrafo}>
                    O usuário do e-mail <b>{emailPaciente}</b> ainda não possui cadastro no Psiquê. Você gostaria de
                    enviar um convite?
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="body2" className={classes.paragrafo}>
                    Basta digitar nome do seu paciente abaixo que iremos enviar um e-mail com instruções para baixar e
                    instalar o Psiquê. Assim que a conta for criada, já será adicionado automaticamente à sua lista de
                    pacientes!
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Container maxWidth="xs" className={classes.centro}>
                    <InputEmailAddPaciente
                      label="Nome do Paciente"
                      onChange={e => setNomePaciente(e.target.value)}
                      value={nomePaciente}
                    />
                  </Container>
                </Grid>
              </Grid>
            </CardContent>
            <CardActions className={classes.action}>
              <Button variant="text" color="primary" onClick={handleIncluirOutroPaciente}>
                Incluir Outro
              </Button>
              <Button type="submit" variant="text" color="primary" className={classes.submit}>
                Enviar Convite
              </Button>
            </CardActions>
          </form>
        </Card>
      </Container>
    );
  }

  if (conviteEnviado) {
    return (
      <Container component="main" maxWidth="sm" className={classes.paper}>
        <Card>
          <form noValidate onSubmit={handleIncluirOutroPaciente}>
            <CardContent>
              <Grid container spacing={2}>
                <Grid item xs={12} className={classes.centro}>
                  <Typography variant="h6">Convite Enviado!</Typography>
                </Grid>
                <Grid item xs={12} className={classes.centro}>
                  <img src="/images/icon_convite_tratamento_enviado.svg" alt="Convite Enviado" height="120rem" />
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="body2" className={classes.paragrafo}>
                    Pronto! Enviamos um e-mail para <b>{emailPaciente}</b> com as instruções para instalação do Psiquê.
                  </Typography>
                </Grid>
              </Grid>
            </CardContent>
            <CardActions className={classes.action}>
              <Button variant="text" color="primary" onClick={handleVisualizarPendentes}>
                Consultar Pendentes
              </Button>
              <Button type="submit" variant="text" color="primary" className={classes.submit}>
                Incluir Outro
              </Button>
            </CardActions>
          </form>
        </Card>
      </Container>
    );
  }

  return (
    <Container component="main" maxWidth="sm" className={classes.paper}>
      <Card>
        <form noValidate onSubmit={handleIncluirPaciente}>
          <CardContent>
            <Grid container spacing={2}>
              <Grid item xs={12} className={classes.centro}>
                <img src="/images/icon_adicionar_paciente.svg" alt="Incluir Paciente" height="120rem" />
              </Grid>
              <Grid item xs={12} className={classes.centro}>
                <Typography variant="subtitle2">Informe o e-mail do seu paciente</Typography>
              </Grid>
              <Grid item xs={12}>
                <Container maxWidth="xs" className={classes.centro}>
                  <InputEmailAddPaciente
                    variant="outlined"
                    label="Email do Paciente"
                    onChange={e => setEmailPaciente(e.target.value)}
                    value={emailPaciente}
                  />
                </Container>
              </Grid>
            </Grid>
          </CardContent>
          <CardActions className={classes.action}>
            <span></span>
            <Button type="submit" variant="text" color="primary" className={classes.submit}>
              Incluir Paciente
            </Button>
          </CardActions>
        </form>
      </Card>
    </Container>
  );
});
