import UsuarioStore from './UsuarioStore';
import InterfaceStore from './InterfaceStore';
import AssinaturaStore from './AssinaturaStore';
import TratamentoStore from './TratamentoStore';
import EnderecoStore from './EnderecoStore';
import AdminStore from './AdminStore';
import { action } from 'mobx';
import CadastroStore from './CadastroStore';

export default class RootStore {
  constructor() {
    this.interfaceStore = new InterfaceStore();
    this.usuarioStore = new UsuarioStore(this);
    this.tratamentoStore = new TratamentoStore(this);
    this.assinaturaStore = new AssinaturaStore(this);
    this.enderecoStore = new EnderecoStore(this);
    this.adminStore = new AdminStore(this);
    this.cadastroStore = new CadastroStore(this);
  }

  reset = action(async () => {
    this.usuarioStore.reset();
    this.tratamentoStore.reset();
    this.assinaturaStore.reset();
    this.enderecoStore.reset();
    this.adminStore.logout();
    this.cadastroStore.reset();
  });
}
