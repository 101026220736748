import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import useStores from '../../hooks/useStores';
import { useParams } from 'react-router-dom';
import { Radar, RadarChart, PolarGrid, PolarAngleAxis, PolarRadiusAxis, ResponsiveContainer } from 'recharts';
import LoadingBalls from '../LoadingBalls';

export default observer(function RadarChartSentimentos(props) {
  const store = useStores();
  const { idPaciente } = useParams();
  const diarios = store.tratamentoStore.tratamento.diarios;
  const sentimentos = store.tratamentoStore.tratamento.sentimentos;
  const [isLoading, setLoading] = useState(true);

  useEffect(() => {
    (async function() {
      await Promise.all([
        store.tratamentoStore.obterSentimentos(),
        store.tratamentoStore.listarDiarios(store.usuarioStore.usuario.id, idPaciente)
      ]);
      setLoading(false);
    })();
  }, [store, idPaciente]);

  if (isLoading || diarios === '') {
    return <LoadingBalls />;
  }

  return (
    <ResponsiveContainer>
      <RadarChart data={montaDadosRadarChart(diarios, sentimentos)} margin={{ top: 30, right: 5, left: 5, bottom: 5 }}>
        <PolarGrid gridType="circle" />
        <PolarAngleAxis dataKey="subject" tick={true} width="60" />
        <PolarRadiusAxis />
        <Radar name="Paciente" dataKey="qtd" stroke="#8884d8" fill="#8884d8" fillOpacity={0.6} />
      </RadarChart>
    </ResponsiveContainer>
  );
});

function montaDadosRadarChart(diarios, sentimentos) {
  const dadosRadarChart = [];
  // const dadosRadarChart = [{ subject: '', qtd: 0 }];

  Array.from(sentimentos).forEach(sentimento => {
    dadosRadarChart.push({ subject: sentimento.texto, qtd: 0 });
  });
  Array.from(diarios).forEach(diario => {
    let index = dadosRadarChart.findIndex(x => x.subject === diario.sentimento);
    if (index === -1) {
      dadosRadarChart.push({ subject: diario.sentimento, qtd: 1 });
    } else {
      dadosRadarChart[index].qtd += 1;
    }
  });
  return dadosRadarChart;
}
