import React from 'react';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import Box from '@material-ui/core/Box';
import PersonAddIcon from '@material-ui/icons/PersonAdd';
import { useHistory } from 'react-router-dom';

export function BotaoIncluirPaciente() {
  const history = useHistory();

  const handleIncluirPaciente = async () => {
    history.push('/pacientes/novo');
  };

  return (
    <IconButton onClick={handleIncluirPaciente}>
      <PersonAddIcon />
      <Box ml={2}>
        <Typography variant="body1" display="block" noWrap>
          Incluir Paciente
        </Typography>
      </Box>
    </IconButton>
  );
}
