import React, { useState } from 'react';
import Button from '@material-ui/core/Button';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Box from '@material-ui/core/Box';
import { Link as RouterLink, useHistory } from 'react-router-dom';
import useStores from '../hooks/useStores';
import { InputSenhaLogin, InputEmailLogin, InputManterConectadoLogin } from '../components/Input';
import { useLocation } from 'react-router-dom';
import { observer } from 'mobx-react';
import PsiqueAvatar from '../components/PsiqueAvatar';
import LinkEmailSuporte from '../components/LinkEmailSuporte';
import AvisoCategorizacaoGmail from '../components/AvisoCategorizacaoGmail';
import LinkWhatsAppSuporte from '../components/LinkWhatsAppSuporte';

const useStyles = makeStyles(theme => ({
  paper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  nomePagina: {
    color: theme.palette.primary.main
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(3)
  },
  submit: {
    margin: theme.spacing(3, 0, 2)
  }
}));

export default observer(LoginPage);
function LoginPage() {
  const store = useStores();
  const classes = useStyles();
  const location = useLocation();
  const history = useHistory();

  const [email, setEmail] = useState(null);
  const [senha, setSenha] = useState(null);
  const [manter, setManter] = useState(false);
  const [emailNaoConfirmado, setEmailNaoConfirmado] = useState(false);
  const [novoEmailConfirmacaoEnviado, setNovoEmailConfirmacaoEnviado] = useState(false);

  if (location.pathname !== '/login') {
    store.interfaceStore.setHideTitle(true);
  }

  const efetuaLogin = async event => {
    event.preventDefault();
    const RC = await store.usuarioStore.login(email, senha, manter);
    if (RC.success) {
      location.pathname === '/login' && history.push('/painel');
    } else if (RC.httpStatus === 403) {
      setEmailNaoConfirmado(true);
    }
  };

  const enviaNovoEmailConfirmacao = async () => {
    const tokenProvisorio = window.localStorage.getItem('psiqueEmailConfirmar' + store.usuarioStore.usuario.id);
    const RC = await store.usuarioStore.reenviarEmailConfirmacao(tokenProvisorio);
    if (RC.success) {
      setNovoEmailConfirmacaoEnviado(true);
    }
  };

  if (novoEmailConfirmacaoEnviado) {
    return (
      <Container component="main" maxWidth="md" className={classes.paper}>
        <Grid container spacing={7} className={classes.paper}>
          <PsiqueAvatar />
          <Grid item xs={12}>
            <Typography component="h1" variant="h5">
              Pronto, o e-mail foi enviado!
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography component="p" variant="subtitle1">
              Enviamos um novo e-mail para <b>{store.usuarioStore.usuario.email}</b> com as instruções para liberar o
              acesso à sua conta!
            </Typography>
            <Typography component="p" variant="subtitle1" style={{ marginTop: '2rem' }}>
              Pode ser que você encontre o e-mail que havíamos enviado anteriormente na sua caixa de entrada.
              Certifique-se de usar o link contido no e-mail mais recente, que enviamos agora, pois o link anterior
              agora está inválido.
            </Typography>
            <AvisoCategorizacaoGmail email={store.usuarioStore.usuario.email} style={{ marginTop: '2rem' }} />
            <Typography component="p" style={{ marginTop: '2rem' }}>
              Se estiver com dificuldades para confirmar o seu e-mail, ou qualquer outro problema, por favor entre em
              contato através do e-mail <LinkEmailSuporte />, ou pelo WhatsApp no número <LinkWhatsAppSuporte /> que
              iremos te ajudar!
            </Typography>
          </Grid>
        </Grid>
      </Container>
    );
  }

  if (emailNaoConfirmado) {
    return (
      <Container component="main" maxWidth="md" className={classes.paper}>
        <Grid container spacing={7} className={classes.paper}>
          <PsiqueAvatar />
          <Grid item xs={12}>
            <Typography component="h1" variant="h5">
              Seu e-mail ainda não foi confirmado
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography component="p" variant="subtitle1">
              Nós havíamos enviado um e-mail para <b>{store.usuarioStore.usuario.email}</b> com as instruções para
              liberar o acesso à sua conta. Por favor, verifique se ele está na sua caixa de entrada.
            </Typography>
            <AvisoCategorizacaoGmail email={store.usuarioStore.usuario.email} style={{ marginTop: '2rem' }} />
            <Typography component="p" style={{ marginTop: '2rem' }}>
              Se estiver com dificuldades para confirmar o seu e-mail, ou qualquer outro problema, entre em contato
              através do e-mail <LinkEmailSuporte />, ou pelo WhatsApp no número <LinkWhatsAppSuporte /> que iremos te
              ajudar!
            </Typography>
            <Typography component="p" variant="subtitle2" style={{ marginTop: '2rem', textAlign: 'center' }}>
              Se você não recebeu nosso e-mail,{' '}
              <Link href="#" onClick={enviaNovoEmailConfirmacao}>
                clique aqui
              </Link>{' '}
              para enviar novamente.
            </Typography>
          </Grid>
        </Grid>
      </Container>
    );
  }

  return (
    <Container component="main" maxWidth="md" className={classes.paper}>
      <PsiqueAvatar />
      <Typography component="h1" variant="h5">
        Acesse sua conta
      </Typography>
      {location.pathname !== '/login' && (
        <Box marginTop={3} marginBottom={1}>
          <Typography component="p" variant="body2">
            Para acessar a página <span className={classes.nomePagina}>{store.interfaceStore.interface.title}</span> é
            necessário fazer login.
          </Typography>
        </Box>
      )}

      <Container className={classes.paper} maxWidth="xs">
        <form className={classes.form} noValidate onSubmit={efetuaLogin}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <InputEmailLogin label="Email" onChange={e => setEmail(e.target.value)} />
            </Grid>
            <Grid item xs={12}>
              <InputSenhaLogin label="Senha" onChange={e => setSenha(e.target.value)} />
            </Grid>
            <Grid item xs={12}>
              <InputManterConectadoLogin label="Mantenha-me conectado" onChange={() => setManter(!manter)} />
            </Grid>
          </Grid>
          <Button type="submit" fullWidth variant="contained" color="primary" className={classes.submit}>
            Entrar
          </Button>
          <Grid container>
            <Grid item xs>
              <Link component={RouterLink} to="/login/esqueci" variant="body2">
                Esqueçeu sua senha?
              </Link>
            </Grid>
            <Grid item>
              <Link component={RouterLink} to="/cadastro" variant="body2">
                {'Não possui conta? Cadastre-se'}
              </Link>
            </Grid>
          </Grid>
        </form>
      </Container>
    </Container>
  );
}
