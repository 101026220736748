import React from 'react';
import { observer } from 'mobx-react';
import useStores from '../hooks/useStores';
import Snackbar from '@material-ui/core/Snackbar';
import Button from '@material-ui/core/Button';
import MuiAlert from '@material-ui/lab/Alert';
import { makeStyles } from '@material-ui/core/styles';
import { Link as RouterLink } from 'react-router-dom';

const useStyles = makeStyles(theme => ({
  root: {
    width: 'fit-content',
    '& > * + *': {
      marginTop: theme.spacing(2)
    }
  }
}));

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

export default observer(ExibeMensagens);

function ExibeMensagens(props) {
  const classes = useStyles();
  const store = useStores();
  const [key, setKey] = React.useState(0);

  const handleClose = (event, reason) => {
    setKey(key + 1);
    store.interfaceStore.interface.mensagens.shift();
  };

  function montaMensagem() {
    let mensagem = store.interfaceStore.interface.mensagens[0].mensagem;
    let severidade = store.interfaceStore.interface.mensagens[0].severidade;
    let textoBotao = store.interfaceStore.interface.mensagens[0].textoBotao;
    let urlBotao = store.interfaceStore.interface.mensagens[0].urlBotao;

    return (
      <Snackbar
        className={classes.root}
        key={key}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        open={store.interfaceStore.interface.mensagens.length > 0}
        autoHideDuration={6000}
        onClose={handleClose}>
        <>
          {textoBotao && (
            <Alert
              action={
                <RouterLink style={{ color: 'white' }} to={urlBotao}>
                  <Button color="inherit" size="small" onClick={handleClose}>
                    {textoBotao}
                  </Button>
                </RouterLink>
              }
              severity={severidade}>
              {mensagem}
            </Alert>
          )}
          {!textoBotao && (
            <Alert onClose={handleClose} severity={severidade}>
              {mensagem}
            </Alert>
          )}
        </>
      </Snackbar>
    );
  }

  return <div>{store.interfaceStore.interface.mensagens.length > 0 && montaMensagem()}</div>;
}
