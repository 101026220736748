import React from 'react';
import { Redirect } from 'react-router-dom';
import CadastroPage from './pages/CadastroPage';
import LoginPage from './pages/LoginPage';
import RegistrosPacientePage from './pages/RegistrosPacientePage';
import NoMatchPage from './pages/NoMatchPage';
import SairPage from './pages/SairPage';
import PerfilPage from './pages/PerfilPage';
import PainelPage from './pages/PainelPage';
import ListaPacientesPage from './pages/ListaPacientesPage';
import PacientesPendentesPage from './pages/PacientesPendentesPage';
import ErrorTestPage from './pages/ErrorTestPage';
import ConfirmarEmailPage from './pages/ConfirmarEmailPage';
import IncluirPacientePage from './pages/IncluirPacientePage';
import RemoverPacientePage from './pages/RemoverPacientePage';
import RecuperarSenhaPage from './pages/RecuperarSenhaPage';
import EsqueciSenhaPage from './pages/EsqueciSenhaPage';
import HomeIcon from '@material-ui/icons/Home';
import AccountBoxIcon from '@material-ui/icons/AccountBox';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import PeopleIcon from '@material-ui/icons/People';
import GroupAddIcon from '@material-ui/icons/GroupAdd';
import TimerIcon from '@material-ui/icons/Timer';
import HelpIcon from '@material-ui/icons/Help';
import CategoriasMenu from './config/CategoriasMenu';
import PerfilInvalidoPage from './pages/PerfilInvalidoPage';
import TutorialInclusaoPacientePage from './pages/tutoriais/TutorialInclusaoPacientePage';
import TutorialUtilizarComoPaciente from './pages/tutoriais/TutorialUtilizarComoPaciente';
import AdminBouncerPage from './pages/admin/AdminBouncerPage';
import ListaTutoriaisPage from './pages/tutoriais/ListaTutoriaisPage';
import ConcluirCheckoutPage from './pages/ConcluirCheckoutPage';

const routes = [
  {
    route: { path: '/cadastro/parceria/:codigoParceria' },
    page: <CadastroPage />,
    pageTitle: '',
    auth: { requireAuth: false, requireNotAuth: true, otherwise: <SairPage /> }
  },
  {
    route: { path: '/cadastro' },
    page: <CadastroPage />,
    pageTitle: '',
    auth: { requireAuth: false, requireNotAuth: true, otherwise: <SairPage /> }
  },
  {
    route: { path: '/confirmar_email' },
    page: <ConfirmarEmailPage />,
    pageTitle: '',
    auth: { requireAuth: false, requireNotAuth: true, otherwise: <SairPage /> }
  },
  {
    route: { path: '/recuperar_senha' },
    page: <RecuperarSenhaPage />,
    pageTitle: '',
    auth: { requireAuth: false, requireNotAuth: true, otherwise: <SairPage /> }
  },
  {
    route: { path: '/login', exact: true },
    page: <LoginPage />,
    pageTitle: '',
    auth: { requireAuth: false, requireNotAuth: true, otherwise: <Redirect to="/painel" /> }
  },
  {
    route: { path: '/login/esqueci' },
    page: <EsqueciSenhaPage />,
    pageTitle: '',
    auth: { requireAuth: false, requireNotAuth: true, otherwise: <SairPage /> }
  },
  {
    route: { path: '/perfil' },
    page: <PerfilPage />,
    pageTitle: '',
    auth: { requireAuth: true, requireNotAuth: false, otherwise: <LoginPage /> },
    menu: { nome: 'Minha Conta', icone: <AccountBoxIcon />, categoria: CategoriasMenu.USUARIO }
  },
  {
    route: { path: '/checkout/concluir' },
    page: <ConcluirCheckoutPage />,
    pageTitle: '',
    auth: { requireAuth: true, requireNotAuth: false, otherwise: <LoginPage /> }
  },
  {
    route: { path: '/sair' },
    page: <SairPage />,
    pageTitle: 'Volte Sempre',
    auth: { requireAuth: true, requireNotAuth: false, otherwise: <Redirect to="/" /> },
    menu: { nome: 'Sair', icone: <ExitToAppIcon />, categoria: CategoriasMenu.SESSAO }
  },
  {
    route: { path: '/painel' },
    page: <PainelPage />,
    pageTitle: 'Painel do Psicólogo',
    auth: { requireAuth: true, requireNotAuth: false, otherwise: <LoginPage /> },
    menu: { nome: 'Painel', icone: <HomeIcon />, categoria: CategoriasMenu.PAINEL }
  },
  {
    route: { path: '/pacientes/:idPaciente/diarios' },
    page: <RegistrosPacientePage />,
    pageTitle: 'Diários do Paciente',
    auth: { requireAuth: true, requireNotAuth: false, otherwise: <LoginPage /> }
  },
  {
    route: { path: '/pacientes/:idPaciente/remover' },
    page: <RemoverPacientePage />,
    pageTitle: 'Remover Paciente',
    auth: { requireAuth: true, requireNotAuth: false, otherwise: <LoginPage /> }
  },
  {
    route: { path: '/pacientes', exact: true },
    page: <ListaPacientesPage />,
    pageTitle: 'Pacientes',
    auth: { requireAuth: true, requireNotAuth: false, otherwise: <LoginPage /> },
    menu: { nome: 'Meus Pacientes', icone: <PeopleIcon />, categoria: CategoriasMenu.PACIENTES }
  },
  {
    route: { path: '/pacientes/novo' },
    page: <IncluirPacientePage />,
    pageTitle: 'Incluir Paciente',
    auth: { requireAuth: true, requireNotAuth: false, otherwise: <LoginPage /> },
    menu: { nome: 'Incluir Paciente', icone: <GroupAddIcon />, categoria: CategoriasMenu.PACIENTES }
  },
  {
    route: { path: '/pacientes/pendentes' },
    page: <PacientesPendentesPage />,
    pageTitle: 'Pacientes Pendentes',
    auth: { requireAuth: true, requireNotAuth: false, otherwise: <LoginPage /> },
    menu: { nome: 'Ver Pendentes', icone: <TimerIcon />, categoria: CategoriasMenu.PACIENTES }
  },
  {
    route: { path: '/tutoriais/inclusao-paciente' },
    page: <TutorialInclusaoPacientePage />,
    pageTitle: '',
    auth: { requireAuth: true, requireNotAuth: false, otherwise: <LoginPage /> }
  },
  {
    route: { path: '/tutoriais/utilizar-como-paciente' },
    page: <TutorialUtilizarComoPaciente />,
    pageTitle: '',
    auth: { requireAuth: true, requireNotAuth: false, otherwise: <LoginPage /> }
  },
  {
    route: { path: '/tutoriais/', exact: true },
    page: <ListaTutoriaisPage />,
    pageTitle: '',
    auth: { requireAuth: true, requireNotAuth: false, otherwise: <LoginPage /> },
    menu: { nome: 'Tutoriais', icone: <HelpIcon />, categoria: CategoriasMenu.USUARIO }
  },
  {
    route: { path: '/errortest' },
    page: <ErrorTestPage />,
    pageTitle: '',
    auth: { requireAuth: false, requireNotAuth: false, otherwise: null }
  },
  {
    route: { path: '/perfil-invalido', exact: true },
    page: <PerfilInvalidoPage />,
    pageTitle: '',
    auth: { requireAuth: false, requireNotAuth: false, otherwise: null }
  },
  {
    route: { path: '/admin' },
    page: <AdminBouncerPage />,
    pageTitle: '',
    auth: { requireAuth: false, requireNotAuth: false, otherwise: null }
  },
  {
    route: { exact: true, path: '/' },
    page: <Redirect to={{ pathname: '/painel' }} />,
    pageTitle: '',
    auth: { requireAuth: true, requireNotAuth: false, otherwise: <Redirect to={{ pathname: '/login' }} /> }
  },
  {
    route: {},
    page: <NoMatchPage />,
    pageTitle: '',
    auth: { requireAuth: false, requireNotAuth: false, otherwise: null }
  }
];

export default routes;
