import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import { observer } from 'mobx-react';
import { useHistory } from 'react-router-dom';
import Hidden from '@material-ui/core/Hidden';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';

const useStyles = makeStyles(theme => ({
  row: {
    '&:hover': {
      cursor: 'pointer'
    }
  },
  cell: {
    fontSize: '0.9rem',
    padding: '0.5rem'
  },
  firstCell: {
    fontSize: '0.9rem',
    padding: '0.5rem 1.25rem'
  },
  lastCell: {
    fontSize: '0.9rem',
    padding: '0.5rem 0.7rem'
  }
}));

export default observer(function RowTablePacientes(props) {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const { row } = props;
  const classes = useStyles();
  const history = useHistory();

  const handleRowClick = idPaciente => {
    history.push(`/pacientes/${idPaciente}/diarios`);
  };

  const handleMenuClick = event => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = event => {
    event.stopPropagation();
    setAnchorEl(null);
  };

  const handleMenuRemoverPaciente = (event, idPaciente) => {
    event.stopPropagation();
    history.push(`/pacientes/${idPaciente}/remover`);
  };

  return (
    <TableRow
      key={row.id}
      hover
      onClick={() => handleRowClick(row.id)}
      className={classes.row}
      onFocus={event => event.stopPropagation()}>
      <TableCell scope="row" className={classes.firstCell}>
        {row.nome}
      </TableCell>
      <Hidden smDown implementation="js">
        <TableCell align="right" className={classes.cell}>
          {row.diariosNaoLidos}
        </TableCell>
        <TableCell align="right" className={classes.cell}>
          {row.diariosEnviados}
        </TableCell>
        <TableCell align="right" className={classes.cell}>
          {formataData(new Date(row.dataInicio))}
        </TableCell>
      </Hidden>
      <TableCell align="right" className={classes.lastCell}>
        <IconButton
          size="small"
          aria-label="mais"
          aria-controls="menu-opcoes"
          aria-haspopup="true"
          onClick={event => handleMenuClick(event)}
          onFocus={event => event.stopPropagation()}>
          <MoreVertIcon />
        </IconButton>
        <Menu
          id="menu-opcoes"
          anchorEl={anchorEl}
          keepMounted
          open={open}
          onClose={event => handleMenuClose(event)}
          PaperProps={{
            style: {
              width: 200
            }
          }}>
          <MenuItem
            className={classes.cell}
            key="menu-remover-paciente"
            onClick={event => handleMenuRemoverPaciente(event, row.id)}
            onFocus={event => event.stopPropagation()}>
            Remover Paciente
          </MenuItem>
        </Menu>
      </TableCell>
    </TableRow>
  );
});

const formatoData = new Intl.DateTimeFormat('pt-BR', {
  timeZone: 'America/Sao_Paulo',
  format: 'short'
});
function formataData(data) {
  return formatoData.format(data);
}
