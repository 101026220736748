import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import Button from '@material-ui/core/Button';

const useStyles = makeStyles(theme => ({
  paper: {
    position: 'absolute',
    width: '95%',
    [theme.breakpoints.up('md')]: {
      width: 800
    },
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3)
  },
  tituloModal: {
    marginBottom: '2rem'
  },
  distorcoesChip: {
    padding: '0.75rem',
    margin: '0.25rem'
  },
  action: {
    width: 'auto',
    marginTop: '1rem',
    display: 'flex',
    justifyContent: 'right'
  }
}));

export default function ModalDesafioPensamento(props) {
  const classes = useStyles();
  const { pensamento, isOpen, onClose } = props;

  const fecharModal = () => {
    onClose(false);
  };

  if (!pensamento) {
    return null;
  }

  return (
    <Modal
      open={isOpen}
      onClose={fecharModal}
      style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
      <div className={classes.paper}>
        <h2 className={classes.tituloModal}>
          <i>"{pensamento.textoPensamento}"</i>
        </h2>

        <h4>Evidências a favor</h4>
        <p>{pensamento.evidenciasFavor}</p>

        <h4>Evidências contrárias</h4>
        <p>{pensamento.evidenciasContra}</p>

        <h4>Pensamento reformulado</h4>
        <p>{pensamento.pensamentoReformulado}</p>

        <h4>Confiança no pensamento inicial</h4>
        <p>{getDescricaoConfianca(pensamento.confiancaInicial)}</p>

        <h4>Confiança no pensamento reformulado</h4>
        <p>{getDescricaoConfianca(pensamento.confiancaReformulado)}</p>

        <div className={classes.action}>
          <Button onClick={fecharModal}>Fechar</Button>
        </div>
      </div>
    </Modal>
  );
}

function getDescricaoConfianca(confianca) {
  const escala = `(${confianca}/10)`;

  if (confianca <= 2) {
    return `Muito Baixa ${escala}`;
  }
  if (confianca <= 4) {
    return `Baixa ${escala}`;
  }
  if (confianca <= 6) {
    return `Média ${escala}`;
  }
  if (confianca <= 8) {
    return `Alta ${escala}`;
  }
  return `Muito Alta ${escala}`;
}
