import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import Chip from '@material-ui/core/Chip';
import Button from '@material-ui/core/Button';
import useStores from '../../hooks/useStores';

const useStyles = makeStyles(theme => ({
  paper: {
    position: 'absolute',
    width: '95%',
    [theme.breakpoints.up('md')]: {
      width: 800
    },
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3)
  },
  distorcoesChip: {
    padding: '0.75rem',
    margin: '0.25rem'
  },
  action: {
    width: 'auto',
    marginTop: '1rem',
    display: 'flex',
    justifyContent: 'space-between'
  }
}));

export default function ModalMapearDistorcoes(props) {
  const [loading, setLoading] = useState(false);
  const store = useStores();
  const classes = useStyles();
  const { idTratamento, pensamentoEmocoes, distorcoes, isOpen, onClose } = props;
  const [distorcoesMapeadas, setDistorcoesMapeadas] = useState(new Set(pensamentoEmocoes?.idDistorcoes));

  useEffect(() => {
    setDistorcoesMapeadas(new Set(pensamentoEmocoes?.idDistorcoes));
  }, [pensamentoEmocoes]);

  const distorcoesSalvas = new Set(pensamentoEmocoes?.idDistorcoes);

  const toggleDistorcao = id => {
    const novoEstado = new Set(distorcoesMapeadas);
    if (novoEstado.has(id)) {
      novoEstado.delete(id);
    } else {
      novoEstado.add(id);
    }
    setDistorcoesMapeadas(novoEstado);
  };

  const houveAlteracao = !areSetsEqual(distorcoesSalvas, distorcoesMapeadas);

  const salvarDistorcoes = async () => {
    const arrayDistorcoes = Array.from(distorcoesMapeadas);
    setLoading(true);
    const resultado = await store.tratamentoStore.mapearDistorcoes(
      store.usuarioStore.usuario.id,
      idTratamento,
      pensamentoEmocoes.idPensamento,
      arrayDistorcoes
    );
    setLoading(false);
    if (resultado.success) {
      onClose(houveAlteracao, pensamentoEmocoes.idPensamento, arrayDistorcoes);
    }
  };

  const fecharModal = () => {
    onClose(false);
    setDistorcoesMapeadas(distorcoesSalvas);
  };

  if (!pensamentoEmocoes) {
    return null;
  }

  return (
    <Modal
      open={isOpen}
      onClose={fecharModal}
      style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
      <div className={classes.paper}>
        <h2>
          <i>"{pensamentoEmocoes.textoPensamento}"</i>
        </h2>
        <h4>Selecione as distorções que deseja mapear a este pensamento:</h4>
        <div>
          {distorcoes.map((distorcao, index) => (
            <Chip
              key={index}
              label={distorcao.nome}
              className={classes.distorcoesChip}
              color="primary"
              variant={distorcoesMapeadas.has(distorcao.id) ? 'default' : 'outlined'}
              size="small"
              onClick={() => toggleDistorcao(distorcao.id)}
            />
          ))}
        </div>
        <div className={classes.action}>
          <Button color="primary" variant="contained" disabled={!houveAlteracao || loading} onClick={salvarDistorcoes}>
            {loading ? 'Salvando...' : houveAlteracao ? 'Salvar' : 'Salvo'}
          </Button>
          <Button onClick={fecharModal}>Fechar</Button>
        </div>
      </div>
    </Modal>
  );
}

function areSetsEqual(setA, setB) {
  if (setA.size !== setB.size) return false;
  for (var a of setA) if (!setB.has(a)) return false;
  return true;
}
