import React, { useState } from 'react';
import { observer } from 'mobx-react';
import Link from '@material-ui/core/Link';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import LayoutTextual from '../../layout/LayoutTextual';
import { makeStyles } from '@material-ui/core/styles';
import { Link as RouterLink } from 'react-router-dom';
import useStores from '../../hooks/useStores';
import AdminPainelPage from './AdminPainelPage';
import LoadingBalls from '../../components/LoadingBalls';

export default observer(AdminBouncerPage);

const useStyles = makeStyles(theme => ({
  paper: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: '2rem',
    alignItems: 'center'
  },
  form: {
    width: '100%'
  },
  submit: {
    margin: theme.spacing(3, 0, 2)
  }
}));

function AdminBouncerPage(props) {
  const stores = useStores();
  const classes = useStyles();
  const [inputCredencial, setInputCredencial] = useState(null);

  const doSubmit = async event => {
    event.preventDefault();
    await stores.adminStore.login(inputCredencial);
  };

  if (stores.interfaceStore.isLoading) {
    return <LoadingBalls />;
  }

  if (stores.adminStore.isLoggedIn()) {
    return <AdminPainelPage />;
  }

  return (
    <>
      <LayoutTextual title="Área Restrita" iconUrl="/images/icon_admin_bouncer.svg" iconAltText="Acesso restrito" />
      <Container component="main" maxWidth="xs" className={classes.paper}>
        <form className={classes.form} noValidate onSubmit={doSubmit}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                id="senha"
                name="senha"
                type="password"
                variant="outlined"
                label="Informe a credencial"
                onChange={e => setInputCredencial(e.target.value)}
                fullWidth
              />
            </Grid>
            <Grid item xs={12}>
              <Button type="submit" fullWidth variant="contained" color="primary" className={classes.submit}>
                Acessar
              </Button>
            </Grid>
          </Grid>
          <Grid container style={{ justifyContent: 'right' }}>
            <Grid item style={{ alignItems: 'center' }}>
              <Link component={RouterLink} to="/" variant="body2">
                Retornar à página principal
              </Link>
            </Grid>
          </Grid>
        </form>
      </Container>
    </>
  );
}
