import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Pagination from '@material-ui/lab/Pagination';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Grid from '@material-ui/core/Grid';
import PropTypes from 'prop-types';
import { chainPropTypes } from '@material-ui/utils';

const useStyles = makeStyles(theme => ({
  root: {
    alignItems: 'baseline'
  },
  menu: {
    display: 'flex',
    justifyContent: 'center',
    [theme.breakpoints.up('md')]: {
      justifyContent: 'flex-start'
    },
    alignItems: 'baseline'
  },
  paginacao: {
    justifyContent: 'center',
    [theme.breakpoints.up('md')]: {
      justifyContent: 'flex-end'
    },
    alignItems: 'baseline'
  },
  infoPaginacao: {
    display: 'none',
    [theme.breakpoints.up('md')]: {
      display: 'block'
    }
  },

  select: {
    fontSize: 'inherit'
  }
}));

const PaginacaoT2P2 = function PaginacaoT2P2(props) {
  const classes = useStyles();

  const { count, onChangePage, onChangeRowsPerPage, page, rowsPerPage } = props;

  const pages = count % rowsPerPage === 0 ? count / rowsPerPage : Math.ceil(count / rowsPerPage);

  return (
    <Grid container className={classes.root} spacing={1} style={props.style} justify="space-between">
      <Grid container item className={classes.menu} xs={12} md={4} spacing={1}>
        {props.menuItems.map((item, index) => {
          return (
            <Grid md={6} item key={'menu-diarios-' + index}>
              {item}
            </Grid>
          );
        })}
      </Grid>
      <Grid container item className={classes.paginacao} xs={12} md={8} spacing={2}>
        <Grid item className={classes.infoPaginacao}>
          <Typography variant="body2" display="inline">
            Diários por página
          </Typography>{' '}
          <Select
            disabled={count <= 10}
            SelectDisplayProps={{ style: { overflow: 'inherit', color: 'black' } }}
            className={classes.select}
            labelId="demo-customized-select-label"
            id="demo-customized-select"
            value={rowsPerPage}
            onChange={onChangeRowsPerPage}
            disableUnderline>
            <MenuItem value={10}>10</MenuItem>
            {count > 10 ? <MenuItem value={25}>25</MenuItem> : ''}
            {count > 25 ? <MenuItem value={50}>50</MenuItem> : ''}
            {count > 50 ? <MenuItem value={100}>100</MenuItem> : ''}
          </Select>
        </Grid>
        <Grid item className={classes.infoPaginacao}>
          <Typography variant="body2" display="inline">
            Diários {page * rowsPerPage + 1 - rowsPerPage} a {page * rowsPerPage <= count ? page * rowsPerPage : count}{' '}
            de {count}
          </Typography>
        </Grid>
        <Grid item>
          <Pagination count={pages} page={page} size="small" siblingCount={0} onChange={onChangePage} />
        </Grid>
      </Grid>
    </Grid>
  );
};

PaginacaoT2P2.propTypes = {
  count: PropTypes.number.isRequired,

  onChangePage: PropTypes.func.isRequired,

  onChangeRowsPerPage: PropTypes.func,

  page: chainPropTypes(PropTypes.number.isRequired, props => {
    const { count, page, rowsPerPage } = props;

    if (count === -1) {
      return null;
    }

    const pageMax = Math.max(1, Math.ceil(count / rowsPerPage));
    if (page < 1 || page > pageMax) {
      return new Error('PaginacaoT2P2: The page prop is out of range '`(1 to ${pageMax}, but page is ${page}).`);
    }
    return null;
  }),

  rowsPerPage: PropTypes.number.isRequired
};

export default PaginacaoT2P2;
