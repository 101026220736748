import React from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableRow from '@material-ui/core/TableRow';
import { useHistory } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  row: {
    '&:hover': {
      cursor: 'pointer'
    }
  }
}));

export default function MontaTabela(props) {
  const history = useHistory();
  const classes = useStyles();

  const handleGoToPage = pagina => {
    history.push(pagina);
  };

  return (
    <TableContainer>
      <Table>
        <TableBody>
          {props.dadosTabela.map((row, index) => (
            <TableRow key={index} hover onClick={() => handleGoToPage(row.link)} className={row.link && classes.row}>
              <TableCell style={{ paddingRight: 0, paddingLeft: 0 }} size="small" align="left">
                {row.nome}
              </TableCell>
              <TableCell style={{ paddingRight: 0, paddingLeft: 0 }} size="small" align="right">
                {row.valor}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
