import React, { useState, useEffect } from 'react';
import { observer } from 'mobx-react';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Paper from '@material-ui/core/Paper';
import LayoutTextual from '../../layout/LayoutTextual';
import { makeStyles } from '@material-ui/core/styles';
import useStores from '../../hooks/useStores';
import { DataGrid } from '@material-ui/data-grid';

export default observer(AdminPainelPage);

const useStyles = makeStyles(theme => ({
  table: {
    minWidth: 650
  }
}));

function AdminPainelPage(props) {
  const adminStore = useStores().adminStore;
  const classes = useStyles();
  const [activeTab, setActiveTab] = useState(0);

  useEffect(() => {
    adminStore.buscarRelatorioUsuarios();
    adminStore.buscarLogEventos();
  }, [adminStore]);

  const handleChangeTab = (event, newActiveTab) => {
    setActiveTab(newActiveTab);
  };

  const isTabActive = index => {
    return index === activeTab;
  };

  return (
    <LayoutTextual
      title="Psiquê Admin"
      iconUrl="/images/psi_icon.png"
      iconAlt="Área de administração do Psiquê"
      size="small">
      <LayoutTextual.TituloSecao>Últimos Cadastros</LayoutTextual.TituloSecao>
      <Paper className={classes.root} style={{ marginTop: '1.5rem' }}>
        <Tabs value={activeTab} onChange={handleChangeTab} indicatorColor="primary">
          <Tab label="Psicólogos" />
          <Tab label="Pacientes" />
          <Tab label="Log de Eventos" />
        </Tabs>
      </Paper>
      <Paper style={{ marginTop: '1.2rem' }} hidden={!isTabActive(0)}>
        <DataGrid
          rows={adminStore.relatorios.psicologosRecentes}
          columns={colunasPsicologo}
          density="compact"
          pageSize={10}
          autoHeight
          disableColumnMenu
          disableSelectionOnClick></DataGrid>
      </Paper>

      <Paper style={{ marginTop: '1.2rem' }} hidden={!isTabActive(1)}>
        <DataGrid
          rows={adminStore.relatorios.pacientesRecentes}
          columns={colunasPacientes}
          density="compact"
          pageSize={10}
          autoHeight
          disableColumnMenu
          disableSelectionOnClick></DataGrid>
      </Paper>

      <Paper style={{ marginTop: '1.2rem' }} hidden={!isTabActive(2)}>
        <DataGrid
          rows={adminStore.logEventos}
          columns={colunasLogEventos}
          density="compact"
          pageSize={10}
          autoHeight
          disableColumnMenu
          disableSelectionOnClick></DataGrid>
      </Paper>
    </LayoutTextual>
  );
}

const dateFormat = new Intl.DateTimeFormat('pt-BR', {
  timeZone: 'America/Sao_Paulo',
  year: 'numeric',
  month: 'numeric',
  day: 'numeric',
  hour: 'numeric',
  minute: 'numeric'
});

const dateFormatWithSeconds = new Intl.DateTimeFormat('pt-BR', {
  timeZone: 'America/Sao_Paulo',
  year: 'numeric',
  month: 'numeric',
  day: 'numeric',
  hour: 'numeric',
  minute: 'numeric',
  second: 'numeric'
});

const colunasComuns = [
  { field: 'id', headerName: 'ID', width: 70 },
  {
    field: 'primeiroNome',
    headerName: 'Nome',
    width: 100,
    valueGetter: params => {
      return params.row.nome.split(' ')[0];
    }
  },
  { field: 'email', headerName: 'E-mail', width: 280 },
  {
    field: 'dtInscricao',
    headerName: 'Inscrição',
    width: 150,
    valueGetter: params => {
      return dateFormat.format(new Date(params.row.dataInscricao));
    }
  },
  {
    field: 'confirmado',
    headerName: 'Conf.',
    description: 'E-mail confirmado',
    width: 80,
    valueGetter: params => {
      return params.row.emailConfirmado ? 'Sim' : 'Não';
    }
  }
];

const colunasPsicologo = [
  ...colunasComuns,
  { field: 'crp', headerName: 'CRP', width: 125 },
  { field: 'pacientesAdicionados', headerName: 'Pacientes', width: 100 }
];

const colunasPacientes = [...colunasComuns, { field: 'diariosEnviados', headerName: 'Diários', width: 85 }];

const colunasLogEventos = [
  {
    field: 'id',
    headerName: 'ID',
    width: 80
  },
  {
    field: 'timestamp',
    headerName: 'Timestamp',
    width: 170,
    valueGetter: params => {
      return dateFormatWithSeconds.format(new Date(params.row.timestamp));
    }
  },
  {
    field: 'codigo',
    headerName: 'Código',
    width: 200
  },
  { field: 'versao', headerName: 'Vrs', width: 60 },
  {
    field: 'dadosAdicionais',
    headerName: 'Dados Adicionais',
    width: 2000,
    valueGetter: params => {
      return params.row.dadosAdicionais ? JSON.stringify(params.row.dadosAdicionais) : '';
    }
  }
];
