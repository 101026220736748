import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import { makeStyles } from '@material-ui/core/styles';
import useStores from '../../hooks/useStores';
import { useParams } from 'react-router-dom';
import Typography from '@material-ui/core/Typography';
import LoadingBalls from '../LoadingBalls';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%'
  }
}));

export default observer(function DadosPaciente(props) {
  const store = useStores();
  const { idPaciente } = useParams();
  const classes = useStyles();
  const [isLoading, setLoading] = useState(true);

  useEffect(() => {
    (async function() {
      await store.tratamentoStore.detalharTratamentos(store.usuarioStore.usuario.id, idPaciente);
      setLoading(false);
    })();
  }, [store, idPaciente]);

  if (isLoading) {
    return <LoadingBalls />;
  }

  return (
    <div className={classes.root} justify="center">
      <Typography component="h1" variant="h6">
        {store.tratamentoStore.tratamento.paciente.nome}
      </Typography>
      <Typography component="h1" variant="body1">
        Email: {store.tratamentoStore.tratamento.paciente.email}
      </Typography>
      <Typography component="h1" variant="body1">
        Início do Tratamento: {new Date(store.tratamentoStore.tratamento.paciente.dataInicio).toLocaleString('pt-BR')}
      </Typography>
    </div>
  );
});
