import React from 'react';
import LayoutTextual from '../layout/LayoutTextual';
import Link from '@material-ui/core/Link';
import LinkEmailSuporte from '../components/LinkEmailSuporte';
import { withRouter } from 'react-router';
import LinkWhatsAppSuporte from './LinkWhatsAppSuporte';
import AppConfig from '../config/AppConfig';
import AppContext from '../contexts/AppContext';

class ErrorBoundary extends React.Component {
  static contextType = AppContext;
  constructor(props) {
    super(props);
    this.props.history.listen((location, action) => {
      if (this.state.hasError) {
        this.setState({ hasError: false });
      }
    });
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    return { hasError: true };
  }

  componentDidCatch(error, info) {
    const usuarioStore = this.context.usuarioStore;
    fetch(`${AppConfig.API_URL}/api/log-eventos`, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        codigo: 'erro_psique_web',
        versao: 1,
        dadosAdicionais: {
          usuario: usuarioStore?.usuario?.id,
          message: `${error}`,
          location: this.props.location
        }
      })
    });
  }

  render() {
    if (this.state.hasError) {
      return (
        <LayoutTextual title="Ops, desculpe!" iconUrl="/images/icon_unknown_error.svg" iconAltText="Ocorreu um erro">
          <LayoutTextual.Paragrafo>
            Encontramos um problema ao carregar esta página. É possível que seja algo temporário, tente{' '}
            <Link href="#" onClick={() => window.location.reload()}>
              atualizar a página
            </Link>
            , ou retorne à nossa <Link href="/">página inicial</Link> e tente acessar novamente.
          </LayoutTextual.Paragrafo>
          <LayoutTextual.Paragrafo>
            Se o problema persistir, entre em contato através do e-mail <LinkEmailSuporte /> ou pelo WhatsApp no número{' '}
            <LinkWhatsAppSuporte /> que iremos te ajudar!
          </LayoutTextual.Paragrafo>
        </LayoutTextual>
      );
    }
    return this.props.children;
  }
}

export default withRouter(ErrorBoundary);
