import { observable, action } from 'mobx';

export default class InterfaceStore {
  constructor() {
    this.qtdLoading = 0;
  }

  interface = observable({ title: '', isLoading: false, mensagens: [], hideTitle: false });
  oneTrueToManyFalse = observable({ e: '' });

  menus = observable({
    menuLateral: {
      itens: [],
      isOpen: false,
      toggle: action(async () => {
        this.menus.menuLateral.isOpen = !this.menus.menuLateral.isOpen;
      })
    }
  });
  isMenuLateralOpen = observable.box(false);

  setTitle = action(async title => {
    this.interface.title = title;
  });

  setHideTitle = action(async hideTitle => {
    this.interface.hideTitle = hideTitle;
  });

  setItensMenuLateral = action(async lista => {
    this.menus.menuLateral.itens = lista;
  });

  iniciaLoading = action(async () => {
    this.qtdLoading += 1;
    this.setLoading();
  });

  finalizaLoading = action(async () => {
    this.qtdLoading -= 1;
    this.setLoading();
  });

  setLoading = action(async () => {
    if (this.qtdLoading === 0) {
      if (this.interface.isLoading) {
        this.interface.isLoading = false;
      }
    } else {
      if (!this.interface.isLoading) {
        this.interface.isLoading = true;
      }
    }
  });

  incluiMensagem = action(async (mensagem, severidade, textoBotao = null, urlBotao = null) => {
    if (this.interface.mensagens.findIndex(i => i.mensagem === mensagem && i.severidade === severidade) === -1) {
      this.interface.mensagens.push({ mensagem, severidade, textoBotao, urlBotao });
    }
  });

  setOneTrueToManyFalse = action(async (chv, vlr, boolOneTrue) => {
    this.oneTrueToManyFalse[chv] = vlr;
    if (vlr && boolOneTrue) {
      Object.entries(this.oneTrueToManyFalse).forEach(([key, value]) => {
        if (vlr && value && chv !== key) {
          this.oneTrueToManyFalse[key] = false;
        }
      });
    }
  });
}
