import React, { useEffect, useState } from 'react';
import useStores from '../../hooks/useStores';
import LoadingBalls from '../LoadingBalls';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Collapse from '@material-ui/core/Collapse';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import currency from 'currency.js';

const useStyles = makeStyles(theme => ({
  container: {
    width: '100%',
    backgroundColor: 'white',
    borderRadius: '0.5rem',
    marginTop: '1.25rem'
  },
  destaqueBox: {
    background: 'linear-gradient(25deg, #4e45aa 30%, #716abb 80%)',
    color: 'white',
    paddingLeft: '1rem',
    paddingRight: '1rem',
    marginBottom: '0.1rem',
    borderTopLeftRadius: '0.5rem',
    borderTopRightRadius: '0.5rem',
    boxShadow: '0px 3px 3px -3px #4e45aa',
    textAlign: 'center'
  },
  detalhesBox: {
    color: theme.palette.grey[800],
    paddingLeft: '1rem',
    paddingRight: '1rem',
    paddingTop: '0.5rem',
    marginTop: '0',
    marginBottom: '0.5rem',
    textAlign: 'center'
  }
}));

export default function CardOferta(props) {
  const { tipoOferta, codigoOferta, setOfertaNaoEncontrada } = props;
  const [parceria, setParceria] = useState(null);
  const [cupom, setCupom] = useState(null);
  const [loading, setLoading] = useState(false);
  const [exibirDetalhes, setExibirDetalhes] = useState(false);
  const store = useStores();
  const classes = useStyles();

  useEffect(() => {
    async function fetchData() {
      if (tipoOferta === 'parceria') {
        const { success, dados } = await store.cadastroStore.getParceria(codigoOferta);
        if (success && dados.cupom.isAtivo) {
          setParceria(dados);
        } else {
          setOfertaNaoEncontrada(true);
        }
      } else if (tipoOferta === 'cupom') {
        if (tipoOferta === 'cupom') {
          const { success, dados } = await store.cadastroStore.getCupom(codigoOferta);
          if (success && dados.isAtivo) {
            setCupom(dados);
          } else {
            setOfertaNaoEncontrada(true);
          }
        }
      }
      setLoading(false);
    }
    setLoading(true);
    fetchData();
  }, [tipoOferta, codigoOferta, store.cadastroStore, setOfertaNaoEncontrada]);

  if (loading) {
    return <LoadingBalls />;
  }

  if (parceria) {
    return (
      <CardDetalhesOferta titulo={parceria.textoTitulo} textoDetalhe={parceria.textoDetalhe} cupom={parceria.cupom} />
    );
  }

  if (cupom) {
    return <CardDetalhesOferta titulo={cupom.descricao} cupom={cupom} />;
  }

  return null;

  function CardDetalhesOferta(props) {
    const { titulo, textoDetalhe, cupom } = props;
    const valorDescontoFormatado = calcularValorComDesconto('29,90', cupom.valorDesconto, cupom.tipoDesconto);
    return (
      <Box boxShadow={1} className={classes.container}>
        <Grid container>
          <Grid item xs={12} className={classes.destaqueBox}>
            <Typography variant="overline" style={{ fontWeight: 'bold' }}>
              {titulo}
            </Typography>
            <Typography variant="h3" component="p" style={{ fontWeight: 'bold' }}>
              {cupom.tipoDesconto === 'percentual' ? `${cupom.valorDesconto}% OFF` : `R$ ${cupom.valorDesconto} OFF`} *
            </Typography>
            <Typography variant="overline" style={{ fontWeight: 'bold' }}>
              Nos primeiros {cupom.duracaoMeses} meses
            </Typography>
          </Grid>
          <Grid item xs={12} className={classes.detalhesBox}>
            <Typography variant="body2" component="p" style={{ textDecoration: 'line-through' }}>
              R$&nbsp;29,90 / mês
            </Typography>
            <Typography variant="h5" component="p" style={{ fontWeight: 'bold', marginBottom: '0.5rem' }}>
              R$&nbsp;{valorDescontoFormatado} / mês
            </Typography>
            <Typography variant="body2" style={{ marginBottom: '0.75rem' }}>
              Experimente grátis por {cupom.diasPeriodoGratis} dias!
            </Typography>
            <Typography
              variant="subtitle1"
              style={{ textAlign: 'center', fontSize: '8pt', marginBottom: '0.5rem', fontWeight: 'bold' }}>
              * Oferta válida somente para assinaturas realizadas na Plataforma do Psiquê. Não aplicável a assinaturas
              realizadas pela App Store ou Play Store.
            </Typography>
            <Collapse in={exibirDetalhes}>
              <Grid item xs={12}>
                {textoDetalhe && (
                  <Typography variant="subtitle2" style={{ textAlign: 'left', marginBottom: '0.5rem' }}>
                    {textoDetalhe}
                  </Typography>
                )}
                <Typography variant="subtitle1" style={{ textAlign: 'left', fontSize: '8pt', marginBottom: '0.5rem' }}>
                  Você poderá testar o Psiquê à vontade por {cupom.diasPeriodoGratis} dias. Não é necessário informar um
                  cartão de crédito para se cadastar.
                </Typography>
                <Typography variant="subtitle1" style={{ textAlign: 'left', fontSize: '8pt', marginBottom: '1rem' }}>
                  Após o período de testes, você irá usufruir do valor promocional de R$&nbsp;{valorDescontoFormatado}{' '}
                  nas primeiras {cupom.duracaoMeses} mensalidades da sua assinatura. Ao término do período promocional,
                  será cobrado o valor normal vigente.
                </Typography>
              </Grid>
            </Collapse>
            <Button color="primary" size="small" onClick={() => setExibirDetalhes(!exibirDetalhes)}>
              {exibirDetalhes ? 'Ocultar' : 'Mais Detalhes'}
            </Button>
          </Grid>
        </Grid>
      </Box>
    );
  }
}

const currencyOptions = { decimal: ',', symbol: '' };

function calcularValorComDesconto(valorOriginal, valorDesconto, tipoDesconto) {
  if (tipoDesconto === 'percentual') {
    const multiplicadorDesconto = currency('100')
      .subtract(valorDesconto)
      .divide(100);
    return currency(valorOriginal, currencyOptions)
      .multiply(multiplicadorDesconto)
      .format();
  } else if (tipoDesconto === 'fixo') {
    return currency(valorOriginal, currencyOptions)
      .subtract(valorDesconto)
      .format();
  }
  return valorOriginal;
}
