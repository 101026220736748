import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import useStores from '../hooks/useStores';

//TODO: Componente para escutar mudanças de página, até encontrar solução melhor;
export default function NavigationListener() {
  const store = useStores();
  const { pathname } = useLocation();
  useEffect(() => {
    store.interfaceStore.setHideTitle(false);
    window.scrollTo(0, 0);
  }, [pathname, store]);
  return null;
}
