import React from 'react';
import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';
import CardT2P2 from '../components/CardT2P2';
import Diarios from '../components/RegistrosPacientePage/Diarios';
import RadarChartSentimentos from '../components/RegistrosPacientePage/RadarChartSentimentos';
import LineChartSentimentoTempo from '../components/RegistrosPacientePage/LineChartSentimentoTempo';
import CloudEmocoes from '../components/RegistrosPacientePage/CloudEmocoes';
import DadosPaciente from '../components/RegistrosPacientePage/DadosPaciente';

export default function RelatorioPacientePage(props) {
  return (
    <Container>
      <Grid container component="main" spacing={3}>
        <Grid xs={12} md={12} item>
          <CardT2P2>
            <DadosPaciente />
          </CardT2P2>
        </Grid>
        <Grid xs={12} md={4} item>
          <CardT2P2 tipo="grafico" titulo="Sentimentos" fixed>
            <RadarChartSentimentos />
          </CardT2P2>
        </Grid>
        <Grid xs={12} md={8} item>
          <CardT2P2 tipo="grafico" titulo="Sentimento ao longo do tempo" fixed>
            <LineChartSentimentoTempo />
          </CardT2P2>
        </Grid>
        <Grid xs={12} md={12} item>
          <CardT2P2 tipo="grafico" titulo="Nuvem de Emoções" centered>
            <CloudEmocoes />
          </CardT2P2>
        </Grid>
        <Grid xs={12} md={12} item>
          <CardT2P2 tipo="tabela" titulo="Diários">
            <Diarios />
          </CardT2P2>
        </Grid>
      </Grid>
    </Container>
  );
}
