import { observable, action } from 'mobx';
import FetchCache from '../components/FetchCache';
import AppConfig from '../config/AppConfig';

const initialState = {
  credencial: null,
  relatorios: { pacientesRecentes: [], psicologosRecentes: [] },
  logEventos: []
};

export default class AdminStore {
  #state;

  constructor(parent) {
    this.parent = parent;
    this.#state = observable(initialState);
    this.#state.credencial = sessionStorage.getItem('adminToken');
  }

  isLoggedIn() {
    return this.credencial !== null;
  }

  get credencial() {
    return this.#state.credencial;
  }

  get relatorios() {
    return this.#state.relatorios;
  }

  get logEventos() {
    return this.#state.logEventos;
  }

  login = action(async credencialInformada => {
    this.parent.interfaceStore.iniciaLoading();
    const response = await fetch(`${AppConfig.API_URL}/api/admin/validar-token`, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'x-admin-token': credencialInformada,
        'Content-Type': 'application/json'
      }
    });
    this.parent.interfaceStore.finalizaLoading();
    if (response.status === 200) {
      this.#state.credencial = credencialInformada;
      sessionStorage.setItem('adminToken', credencialInformada);
    } else {
      this.#tratarErro(response, 'Erro ao fazer login');
    }
  });

  logout = action(() => {
    Object.assign(this.#state, initialState);
    sessionStorage.removeItem('adminToken');
  });

  buscarLogEventos = action(async () => {
    this.parent.interfaceStore.iniciaLoading();
    const response = await FetchCache(
      `${AppConfig.API_URL}/api/admin/log-eventos`,
      {
        method: 'GET',
        headers: {
          Accept: 'application/json',
          'x-admin-token': this.credencial,
          'Content-Type': 'application/json'
        }
      },
      60
    );
    this.parent.interfaceStore.finalizaLoading();
    if (response.status === 200) {
      const eventos = await response.json();
      this.#state.logEventos = eventos;
    } else {
      this.#tratarErro(response, 'Erro ao buscar log de eventos');
    }
  });

  buscarRelatorioUsuarios = action(async () => {
    this.parent.interfaceStore.iniciaLoading();
    const response = await FetchCache(
      `${AppConfig.API_URL}/api/admin/relatorios/usuarios`,
      {
        method: 'GET',
        headers: {
          Accept: 'application/json',
          'x-admin-token': this.credencial,
          'Content-Type': 'application/json'
        }
      },
      60
    );
    this.parent.interfaceStore.finalizaLoading();
    if (response.status === 200) {
      const relatorios = await response.json();
      this.#state.relatorios = relatorios;
    } else {
      this.#tratarErro(response, 'Erro ao buscar relatório de usuários');
    }
  });

  #tratarErro = (response, mensagem) => {
    if (response.status === 401) {
      this.logout();
      this.parent.interfaceStore.incluiMensagem('Credencial inválida', 'error');
    } else {
      this.parent.interfaceStore.incluiMensagem(`${mensagem}: ${response.body}`, 'error');
    }
  };
}
