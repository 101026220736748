import React from 'react';
import LinkEmailSuporte from '../../components/LinkEmailSuporte';
import LayoutTextual from '../../layout/LayoutTextual';
import useStores from '../../hooks/useStores';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Link from '@material-ui/core/Link';
import LinkWhatsAppSuporte from '../../components/LinkWhatsAppSuporte';

export default function TutorialUtilizarComoPaciente() {
  const store = useStores();

  return (
    <LayoutTextual
      title="Adicione sua Própria Conta como Paciente"
      iconUrl="/images/icon_utilizar_como_paciente.svg"
      iconAltText="Tutorial Utilizar a Própria Conta como Paciente">
      <LayoutTextual.Paragrafo>
        Quer uma maneira simples de testar todas as funcionalidades do Psiquê antes de adicionar os seus pacientes? Que
        tal adicionar a sua própria conta como paciente? Dessa forma, você poderá explorar melhor o aplicativo do
        Psiquê, que seus pacientes irão utilizar, e poderá também enviar registros e ver como eles irão aparecer no seu
        painel do Psiquê.
      </LayoutTextual.Paragrafo>
      <LayoutTextual.Paragrafo>
        Incluir a sua conta como paciente é muito simples, basta seguir os passos abaixo! Se ficar alguma dúvida, ou
        você encontrar algum problema, entre em contato a qualquer momento através do e-mail <LinkEmailSuporte /> ou
        pelo WhatsApp no número <LinkWhatsAppSuporte /> que iremos te ajudar!
      </LayoutTextual.Paragrafo>
      <LayoutTextual.TituloSecao>Incluindo sua conta como paciente</LayoutTextual.TituloSecao>
      <LayoutTextual.Paragrafo>
        O processo de inclusão é exatamente o mesmo que você utilizará para incluir seus pacientes: primeiro, acesse o
        menu principal do Psiquê, no canto superior esquerdo da tela, e escolha a opção <b>Incluir Paciente</b>.
      </LayoutTextual.Paragrafo>
      <LayoutTextual.Paragrafo style={{ textAlign: 'center' }}>
        <img
          src="/images/tutoriais/menu_incluir_paciente.png"
          alt="Menu Incluir Paciente"
          style={{ maxWidth: '100%' }}
        />
      </LayoutTextual.Paragrafo>
      <LayoutTextual.Paragrafo>
        Na tela a seguir, digite o seu e-mail de cadastro no Psiquê (<b>{store.usuarioStore.usuario.email}</b>) e clique
        no botão <b>Incluir Paciente</b>.
      </LayoutTextual.Paragrafo>
      <LayoutTextual.Paragrafo variant="subtitle2">
        Dica: Se quiser mais detalhes sobre a inclusão de pacientes, temos um tutorial tratando especialmente disso, que
        você pode acessar a partir do menu de <b>Tutoriais</b>
      </LayoutTextual.Paragrafo>
      <LayoutTextual.TituloSecao>Instalação do Aplicativo</LayoutTextual.TituloSecao>
      <LayoutTextual.Paragrafo>
        Agora, você precisa baixar o aplicativo do Psiquê no seu celular. Ele está disponível nas lojas oficiais para
        Android e iOS.
      </LayoutTextual.Paragrafo>
      <Box mt={3} style={{ textAlign: 'center' }}>
        <Grid item container spacing={3} style={{ display: 'flex', justifyContent: 'center' }}>
          <Grid item md={3} xs={12}>
            <Link href="https://apps.apple.com/br/app/psique/id1516604766" target="_blank">
              <img alt="Baixar na App Store" src="/images/appstore_download_bage.png" height="50rem" />
            </Link>
          </Grid>
          <Grid item md={3} xs={12}>
            <Link
              href="https://play.google.com/store/apps/details?id=com.t2p2.psiqueapp&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1"
              target="_blank">
              <img alt="Disponível no Google Play" src="/images/playstore_download_badge.png" height="50rem" />
            </Link>
          </Grid>
        </Grid>
      </Box>
      <LayoutTextual.Paragrafo>
        Depois de baixado, abra o aplicativo, e na tela inicial escolha a opção <b>Sou Usuário</b>. Quando for
        solicitado seu e-mail e senha, utilize os mesmos que você usa para acessar o Psiquê aqui pelo seu navegador.{' '}
      </LayoutTextual.Paragrafo>
      <LayoutTextual.Paragrafo>
        Por fim, clique na aba <b>Ajustes</b> na parte inferior do aplicativo, e entre na opção{' '}
        <b>Solicitações pendentes</b>. Basta confirmar a sua própria solicitação, e você poderá visualizar sua própria
        conta como paciente na opção <b>Meus Pacientes</b> do Psiquê.
      </LayoutTextual.Paragrafo>
      <LayoutTextual.Paragrafo variant="subtitle2">
        Dica: Além de utilizar o aplicativo para testar as funcionalidades disponíveis para seus pacientes, você também
        pode utilizá-lo para acessar as informações dos seus pacientes de qualquer lugar! Basta fazer o login no
        aplicativo pela opção <b>Sou Psicólogo</b>!
      </LayoutTextual.Paragrafo>
    </LayoutTextual>
  );
}
