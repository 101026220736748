import React from 'react';
import Loader from 'react-loader-spinner';
import { makeStyles } from '@material-ui/core/styles';
import { useTheme } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';

const useStyles = makeStyles(theme => ({
  root: {
    height: '100%',
    display: 'flex'
  }
}));

export default function LoadingBalls() {
  const classes = useStyles();
  const theme = useTheme();

  return (
    <Box className={classes.root}>
      <Box m="auto">
        <Loader type="ThreeDots" color={theme.palette.secondary.main} height="60" width="60" />
      </Box>
    </Box>
  );
}
