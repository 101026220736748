import React from 'react';
import LinkEmailSuporte from '../../components/LinkEmailSuporte';
import LinkWhatsAppSuporte from '../../components/LinkWhatsAppSuporte';
import LayoutTextual from '../../layout/LayoutTextual';

export default function TutorialInclusaoPacientePage() {
  return (
    <LayoutTextual
      title="Passo a Passo: Incluindo Pacientes"
      iconUrl="/images/icon_tutorial_passo_a_passo.svg"
      iconAltText="Tutorial Inclusão Paciente">
      <LayoutTextual.Paragrafo>
        Para aproveitar as vantagens que o Psiquê oferece, precisamos incluir os seus pacientes. Aqui neste tutorial
        iremos te mostrar passo a passo como fazer isso. Se ficar alguma dúvida, ou você encontrar algum problema, entre
        em contato a qualquer momento através do e-mail <LinkEmailSuporte /> ou pelo WhatsApp no número{' '}
        <LinkWhatsAppSuporte /> que iremos te ajudar!
      </LayoutTextual.Paragrafo>
      <LayoutTextual.TituloSecao>Incluindo o Paciente no Psiquê</LayoutTextual.TituloSecao>
      <LayoutTextual.Paragrafo>
        Para começar o processo de inclusão, acesse o menu principal do Psiquê, no canto superior esquerdo da tela, e
        escolha a opção <b>Incluir Paciente</b>.
      </LayoutTextual.Paragrafo>
      <LayoutTextual.Paragrafo style={{ textAlign: 'center' }}>
        <img
          src="/images/tutoriais/menu_incluir_paciente.png"
          alt="Menu Incluir Paciente"
          style={{ maxWidth: '100%' }}
        />
      </LayoutTextual.Paragrafo>
      <LayoutTextual.Paragrafo>
        Na tela a seguir, digite o e-mail do seu paciente, e clique no botão <b>Incluir Paciente</b>. Agora, o próximo
        passo depende se o seu paciente já possui conta no Psiquê ou não.
      </LayoutTextual.Paragrafo>
      <LayoutTextual.TituloSecao>Caso seu paciente não possua conta no Psiquê</LayoutTextual.TituloSecao>
      <LayoutTextual.Paragrafo>
        Nesse caso, na próxima tela você poderá enviar um convite para o e-mail do seu paciente. No convite nós iremos
        enviar todas as informações para que ele consiga baixar o aplicativo e criar a conta no Psiquê. Para isso, basta
        informar o nome dele, e clicar no botão <b>Enviar Convite</b>.
      </LayoutTextual.Paragrafo>
      <LayoutTextual.Paragrafo style={{ textAlign: 'center' }}>
        <img src="/images/tutoriais/tela_enviar_convite.png" alt="Tela envio convite" style={{ maxWidth: '100%' }} />
      </LayoutTextual.Paragrafo>
      <LayoutTextual.Paragrafo>
        Feito isso, você poderá acompanhar a situação do convite através do menu <b>Ver Pendentes</b>. Assim que seu
        paciente criar a conta dele, a sua solicitação de tratamento já estará disponível para ele.
      </LayoutTextual.Paragrafo>
      <LayoutTextual.TituloSecao>Caso seu paciente já possua conta no Psiquê</LayoutTextual.TituloSecao>
      <LayoutTextual.Paragrafo>
        Nesse caso, você verá uma tela confirmando que sua solicitação foi enviada ao seu paciente. Agora, basta
        solicitar que ele confirme a inclusão. Para isso, basta ele abrir o aplicativo, escolher a aba <b>Ajustes</b> na
        parte inferior, e entrar na opção <b>Solicitações pendentes</b>. Lá, ele poderá visualizar os seus dados e
        confirmar a inclusão.
      </LayoutTextual.Paragrafo>
      <LayoutTextual.Paragrafo style={{ textAlign: 'center' }}>
        <img
          src="/images/tutoriais/tela_solicitacao_enviada.png"
          alt="Tela solicitação enviada"
          style={{ maxWidth: '100%' }}
        />
      </LayoutTextual.Paragrafo>
      <LayoutTextual.TituloSecao>Pronto!</LayoutTextual.TituloSecao>
      <LayoutTextual.Paragrafo>
        Assim que seu paciente aprovar a sua solicitação, você poderá visualizar os diários dele através do menu{' '}
        <b>Meus Pacientes</b>. Sempre que ele enviar um diário, estará disponível imediatamente para você!
      </LayoutTextual.Paragrafo>
    </LayoutTextual>
  );
}
