import { useEffect } from 'react';
import { observer } from 'mobx-react';
import useStores from '../hooks/useStores';

export default observer(SetPageMenus);

function SetPageMenus(props) {
  const store = useStores();

  useEffect(() => {
    const menuLateral = [];

    if (!store.usuarioStore.sessao.loggedIn) {
      return;
    }

    props.routes.forEach(rota => {
      if (rota.menu) {
        const menu = rota.menu;
        if (!menuLateral[menu.categoria]) {
          menuLateral[menu.categoria] = [];
        }
        menuLateral[menu.categoria].push({ nome: menu.nome, path: rota.route.path, icone: menu.icone });
      }
    });

    store.interfaceStore.setItensMenuLateral(menuLateral);
  }, [props.routes, store.interfaceStore, store.usuarioStore.sessao.loggedIn]);

  return null;
}
