import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import { makeStyles } from '@material-ui/core/styles';
import useStores from '../hooks/useStores';
import queryString from 'query-string';
import { useLocation } from 'react-router-dom';
import { InputSenhaNova, InputEfetuarLoginAposAlteracaoSenha, InputManterConectadoLogin } from '../components/Input';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Link from '@material-ui/core/Link';
import { Link as RouterLink } from 'react-router-dom';
import { Redirect } from 'react-router-dom';
import PsiqueAvatar from '../components/PsiqueAvatar';

const useStyles = makeStyles(theme => ({
  paper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  alert: {
    marginTop: theme.spacing(1),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  nomePagina: {
    color: theme.palette.primary.main
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(3)
  },
  submit: {
    margin: theme.spacing(3, 0, 2)
  },
  progress: {
    position: 'fixed',
    zIndex: 1200,
    top: '0',
    left: '0',
    width: '100%'
  }
}));

export default observer(ConfirmarEmailPage);

function ConfirmarEmailPage() {
  const classes = useStyles();
  const location = useLocation();
  const store = useStores();
  const [senha, setSenha] = useState('');
  const [manter, setManter] = useState(false);
  const [efetuarLogin, setEfetuarLogin] = useState(false);
  const [recuperacaoValidadaOk, setRecuperacaoValidadaOk] = useState(false);
  const [recuperacaoValidadaErro, setRecuperacaoValidadaErro] = useState(false);
  const [recuperacaoConcluidaOk, setRecuperacaoConcluidaOk] = useState(false);
  const [recuperacaoConcluidaErro, setRecuperacaoConcluidaErro] = useState(false);
  const [erroParseUrl, setErroParseUrl] = useState(false);

  let email = ' ';
  let codigoRecuperacao = ' ';

  if (!erroParseUrl) {
    try {
      let values = queryString.parse(location.search);
      const jsonB64 = values.p;
      const jsonAsc = JSON.parse(window.atob(jsonB64));
      email = jsonAsc.email;
      codigoRecuperacao = jsonAsc.codigoRecuperacao;
    } catch (err) {
      setErroParseUrl(true);
    }
  }

  useEffect(() => {
    (async function() {
      if (!erroParseUrl) {
        const RC = await store.usuarioStore.recuperarSenhaPasso2(email, codigoRecuperacao);
        if (RC.success) {
          setRecuperacaoValidadaOk(true);
        } else {
          setRecuperacaoValidadaErro(true);
        }
      }
    })();
  }, [email, codigoRecuperacao, store, erroParseUrl]);

  const _handleAlteraSenha = async event => {
    event.preventDefault();
    const RC = await store.usuarioStore.recuperarSenhaPasso3(email, codigoRecuperacao, senha);
    if (RC.success) {
      setRecuperacaoConcluidaOk(true);
      if (efetuarLogin) {
        await store.usuarioStore.login(email, senha, manter);
      }
    } else {
      setRecuperacaoConcluidaErro(true);
    }
  };

  if (store.usuarioStore.sessao.loggedIn) {
    return <Redirect to="/painel" />;
  }

  if (recuperacaoConcluidaOk) {
    return (
      <Container component="main" maxWidth="md" className={classes.paper}>
        <Grid container spacing={5} className={classes.paper}>
          <Grid item xs={12}>
            <PsiqueAvatar />
          </Grid>
          <Grid item xs={12}>
            <Typography component="h1" variant="h5">
              Senha alterada com sucesso.
            </Typography>
          </Grid>
          <Grid item xs={12}>
            {!efetuarLogin && (
              <Link component={RouterLink} to="/login" variant="body2">
                Acesse sua conta
              </Link>
            )}
            {efetuarLogin && (
              <Typography component="h1" variant="body2">
                Acessando sua conta...
              </Typography>
            )}
          </Grid>
        </Grid>
      </Container>
    );
  }

  if (recuperacaoValidadaErro || erroParseUrl) {
    return (
      <Container component="main" maxWidth="md" className={classes.paper}>
        <Grid container spacing={5} className={classes.paper}>
          <Grid item xs={12}>
            <PsiqueAvatar />
          </Grid>
          <Grid item xs={12}>
            <Typography component="h1" variant="h5">
              Link de recuperação de senha expirado ou incorreto.
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Link component={RouterLink} to="/login/esqueci" variant="body2">
              Iniciar nova recuperação de senha
            </Link>
          </Grid>
        </Grid>
      </Container>
    );
  }

  if (recuperacaoValidadaOk) {
    return (
      <Container component="main" maxWidth="md" className={classes.paper}>
        <PsiqueAvatar />
        <Typography component="h1" variant="h5">
          Digite sua nova senha
        </Typography>
        <Container className={classes.paper} maxWidth="xs">
          <form className={classes.form} noValidate onSubmit={_handleAlteraSenha}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <InputSenhaNova label="Nova senha" onChange={e => setSenha(e.target.value)} />
              </Grid>
              <Grid item xs={12}>
                <InputEfetuarLoginAposAlteracaoSenha
                  label="Acessar conta após alteração"
                  onChange={() => setEfetuarLogin(!efetuarLogin)}
                />
              </Grid>
              <Grid item xs={12}>
                <InputManterConectadoLogin
                  label="Mantenha-me conectado"
                  disabled={!efetuarLogin}
                  onChange={() => setManter(!manter)}
                />
              </Grid>
            </Grid>
            <Button type="submit" fullWidth variant="contained" color="primary" className={classes.submit}>
              Alterar senha
            </Button>
            {recuperacaoConcluidaErro && (
              <Grid container>
                <Grid item>
                  <Link component={RouterLink} to="/login/esqueci" variant="body2">
                    Erro na alteração? Iniciar nova recuperação de senha
                  </Link>
                </Grid>
              </Grid>
            )}
          </form>
        </Container>
      </Container>
    );
  }
  return (
    <Container component="main" maxWidth="md" className={classes.paper}>
      <Grid container spacing={5} className={classes.paper}>
        <Grid item xs={12}>
          <PsiqueAvatar />
        </Grid>
        <Grid item xs={12}>
          <Typography component="h1" variant="h5">
            Estamos verificando sua recuperação de senha...
          </Typography>
        </Grid>
      </Grid>
    </Container>
  );
}
