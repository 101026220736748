import React, { useEffect, useState } from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Icon from '@material-ui/core/Icon';
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';
import useStores from '../../hooks/useStores';
import AppConfig from '../../config/AppConfig';
import { grey } from '@material-ui/core/colors';
import { loadCSS } from 'fg-loadcss';
import { useHistory } from 'react-router-dom';
import LoadingBalls from '../LoadingBalls';

const useStyles = makeStyles(theme => ({
  table: {
    minWidth: 650
  },
  iconesHumor: {
    color: grey[500],
    fontSize: '1.2rem',
    [theme.breakpoints.up('md')]: {
      fontSize: '1.5rem'
    },
    marginRight: '0.5rem',
    width: '100%'
  },
  row: {
    '&:hover': {
      cursor: 'pointer'
    }
  }
}));

export default function ListaDiariosRecentes() {
  const history = useHistory();
  const [status, setStatus] = useState('initial');
  const [diariosRecentes, setDiariosRecentes] = useState([]);
  const { usuarioStore } = useStores();
  const classes = useStyles();

  const goToTratamento = idTratamento => {
    history.push(`/pacientes/${idTratamento}/diarios`);
  };

  useEffect(() => {
    const node = loadCSS(
      'https://use.fontawesome.com/releases/v5.12.0/css/all.css',
      document.querySelector('#font-awesome-css')
    );
    return () => {
      node.parentNode.removeChild(node);
    };
  }, []);

  useEffect(() => {
    async function fetchData() {
      const response = await fetch(
        `${AppConfig.API_URL}/api/psicologos/${usuarioStore.usuario.id}/tratamentos/diarios-recentes`,
        {
          method: 'GET',
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json'
          }
        }
      );
      if (response.status === 200) {
        const dados = await response.json();
        setDiariosRecentes(dados);
        setStatus('ready');
      } else {
        setStatus('error');
      }
    }
    if (usuarioStore.sessao.loggedIn) {
      fetchData();
    }
  }, [usuarioStore.usuario, usuarioStore.sessao]);

  if (status === 'initial') {
    return <LoadingBalls />;
  }

  if (status === 'error') {
    return 'Ocorreu um erro ao buscar os diários. Tente atualizar a página.';
  }

  if (status === 'ready') {
    if (diariosRecentes.length > 0) {
      return (
        <TableContainer component={Paper}>
          <Table className={classes.table} size="small">
            <TableHead>
              <TableRow>
                <TableCell style={{ width: '5rem' }}></TableCell>
                <TableCell>Paciente</TableCell>
                <TableCell>Data de Envio</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {diariosRecentes.map(row => (
                <TableRow
                  hover
                  key={row.diario.id}
                  className={classes.row}
                  onClick={() => goToTratamento(row.tratamento.id)}>
                  <TableCell style={{ width: '5rem' }}>{getIconHumor(row.diario.idSentimento)} </TableCell>
                  <TableCell>{row.tratamento.paciente.usuario.nome}</TableCell>
                  <TableCell>{formatarDataDiario(new Date(row.diario.dataEnvio))}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      );
    }

    return 'Nenhum diário novo no momento.';
  }

  function getIconHumor(idSentimento) {
    switch (idSentimento) {
      case 1:
        return <Icon classes={{ root: 'far fa-frown' }} className={classes.iconesHumor} />;
      case 2:
        return <Icon classes={{ root: 'far fa-sad-tear' }} className={classes.iconesHumor} />;
      case 3:
        return <Icon classes={{ root: 'far fa-meh' }} className={classes.iconesHumor} />;
      case 4:
        return <Icon classes={{ root: 'far fa-smile' }} className={classes.iconesHumor} />;
      case 5:
        return <Icon classes={{ root: 'far fa-laugh-squint' }} className={classes.iconesHumor} />;
      default:
        return null;
    }
  }

  function formatarDataDiario(data) {
    return new Intl.DateTimeFormat('pt-BR', {
      timeZone: 'America/Sao_Paulo',
      year: 'numeric',
      month: 'numeric',
      day: 'numeric',
      hour: 'numeric',
      minute: 'numeric'
    }).format(data);
  }
}
