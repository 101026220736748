import React, { useState } from 'react';
import Button from '@material-ui/core/Button';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import { Link as RouterLink } from 'react-router-dom';
import useStores from '../hooks/useStores';
import { InputEmailLogin } from '../components/Input';
import { observer } from 'mobx-react';
import PsiqueAvatar from '../components/PsiqueAvatar';
import LinkEmailSuporte from '../components/LinkEmailSuporte';
import AvisoCategorizacaoGmail from '../components/AvisoCategorizacaoGmail';
import LinkWhatsAppSuporte from '../components/LinkWhatsAppSuporte';

const useStyles = makeStyles(theme => ({
  paper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(3)
  },
  submit: {
    margin: theme.spacing(3, 0, 2)
  }
}));

export default observer(EsqueciSenhaPage);
function EsqueciSenhaPage() {
  const store = useStores();
  const classes = useStyles();

  const [email, setEmail] = useState(null);
  const [emailEnviado, setEmailEnviado] = useState(false);
  const [emailReenviado, setEmailReenviado] = useState(false);

  const recuperarSenha = async event => {
    event.preventDefault();
    const RC = await store.usuarioStore.recuperarSenhaPasso1(email);
    if (RC.success) {
      if (emailEnviado) {
        setEmailReenviado(true);
      }
      setEmailEnviado(true);
    }
  };

  if (emailEnviado) {
    return (
      <Container component="main" maxWidth="md" className={classes.paper}>
        <Grid container spacing={3} className={classes.paper}>
          <PsiqueAvatar />
          <Grid item xs={12}>
            <Typography component="h1" variant="h5">
              E-mail enviado com sucesso!
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography component="p" style={{ textAlign: 'center' }}>
              Enviamos para <b>{email}</b> um e-mail com as instruções para recuperar sua senha!
            </Typography>
            {emailReenviado && (
              <Typography component="p" variant="subtitle1" style={{ marginTop: '2rem' }}>
                Pode ser que você encontre o e-mail que havíamos enviado anteriormente na sua caixa de entrada.
                Certifique-se de usar o link contido no e-mail mais recente, que enviamos agora, pois o link anterior
                agora está inválido.
              </Typography>
            )}
            <AvisoCategorizacaoGmail email={email} style={{ marginTop: '2rem' }} />
            <Typography component="p" style={{ marginTop: '2rem' }}>
              Se estiver com dificuldades para recuperar sua senha, ou qualquer outro problema, entre em contato através
              do e-mail <LinkEmailSuporte />, ou pelo WhatsApp no número <LinkWhatsAppSuporte /> que iremos te ajudar!
            </Typography>
            {!emailReenviado && (
              <Typography component="p" style={{ textAlign: 'center', marginTop: '2rem' }}>
                Se você não recebeu o e-mail com o link de recuperação,{' '}
                <Link href="#" onClick={recuperarSenha}>
                  clique aqui
                </Link>{' '}
                para enviar novamente.
              </Typography>
            )}
          </Grid>
        </Grid>
      </Container>
    );
  }

  return (
    <Container component="main" maxWidth="md" className={classes.paper}>
      <PsiqueAvatar />
      <Typography component="h1" variant="h5">
        Recuperação de senha
      </Typography>
      <Container className={classes.paper} maxWidth="xs">
        <form className={classes.form} noValidate onSubmit={recuperarSenha}>
          <Grid container spacing={4}>
            <Grid item xs={12}>
              <Typography variant="p">
                Esqueceu sua senha? Não se preocupe! Informe o seu e-mail abaixo que te enviaremos um link para
                recuperação.
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <InputEmailLogin label="Email" onChange={e => setEmail(e.target.value)} />
            </Grid>
          </Grid>
          <Button type="submit" fullWidth variant="contained" color="primary" className={classes.submit}>
            Recuperar Senha
          </Button>
          <Grid container>
            <Grid item xs>
              <Link component={RouterLink} to="/login" variant="body2">
                Voltar para o login
              </Link>
            </Grid>
            <Grid item>
              <Link component={RouterLink} to="/cadastro" variant="body2">
                {'Não possui conta? Cadastre-se'}
              </Link>
            </Grid>
          </Grid>
        </form>
      </Container>
    </Container>
  );
}
