import React, { useState } from 'react';
import { observer } from 'mobx-react';
import { useForm, Controller } from 'react-hook-form';
import { makeStyles } from '@material-ui/core/styles';
import isEmail from 'validator/lib/isEmail';
import MascaraTelefone from '../forms/MascaraTelefone';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import CardActions from '@material-ui/core/CardActions';
import Button from '@material-ui/core/Button';
import Fade from '@material-ui/core/Fade';
import TextField from '@material-ui/core/TextField';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import ClearOutlinedIcon from '@material-ui/icons/ClearOutlined';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import useStores from '../../hooks/useStores';
import { cpf } from 'cpf-cnpj-validator';
import Avatar from '@material-ui/core/Avatar';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import withWidth from '@material-ui/core/withWidth';
import Cleave from 'cleave.js/react';

export default observer(withWidth()(CardPerfilUsuario));

const useStyles = makeStyles(theme => ({
  botaoVermelho: {
    color: theme.palette.error.light
  },
  botaoCinza: {
    color: theme.palette.grey[600]
  },
  cardHeaderAction: {
    margin: 'auto'
  },
  avatar: {
    width: theme.spacing(12),
    height: theme.spacing(12),
    fontSize: '2.5rem',
    backgroundColor: theme.palette.secondary.main
  },
  avatarGrid: {
    display: 'flex',
    justifyItems: 'center',
    justifyContent: 'center'
  },
  card: {
    display: 'flex',
    flexDirection: 'column'
  },
  cardActions: {
    display: 'flex',
    alignItems: 'right',
    justifyContent: 'space-between'
  }
}));

function CardPerfilUsuario(props) {
  const { width } = props;
  const classes = useStyles();
  const { usuarioStore } = useStores();
  const usuario = usuarioStore.usuario;

  const isSmallScreen = () => {
    return width === 'sm' || width === 'xs';
  };

  const [isAlteracaoSenha, setAlteracaoSenha] = useState(false);

  const { register, handleSubmit, errors, formState, getValues, control, reset } = useForm({
    defaultValues: {
      email: usuario.email,
      telefone: usuario.telefone
    }
  });

  const getIniciaisUsuario = () => {
    const partesNome = usuario.nome.split(' ');
    if (partesNome.length === 1) {
      return partesNome[0][0];
    } else {
      return `${partesNome[0][0]}${partesNome[partesNome.length - 1][0]}`;
    }
  };

  const { isSubmitting } = formState;

  const onSubmit = async dados => {
    const changed = {};
    Object.keys(formState.dirtyFields).forEach(field => {
      if (field && dados[field]) {
        changed[field] = dados[field];
      }
    });
    const resposta = await usuarioStore.atualizarDados(changed);
    if (resposta.success) {
      reset({ email: usuario.email, telefone: usuario.telefone });
      if (isAlteracaoSenha) {
        setAlteracaoSenha(false);
      }
    }
  };

  return (
    <Card className={classes.card}>
      <CardHeader
        title="Perfil"
        titleTypographyProps={{ variant: 'h6' }}
        style={{ paddingBottom: '0px' }}
        classes={{
          action: classes.cardHeaderAction
        }}></CardHeader>
      <CardContent>
        <Grid container spacing={3} style={{ marginBottom: '0.5rem' }}>
          <Grid item xs={12} md={12} className={classes.avatarGrid}>
            <Avatar className={classes.avatar}>{getIniciaisUsuario()}</Avatar>
          </Grid>
          <Grid item xs={12} md={12} style={{ textAlign: 'center' }}>
            <Typography component="h1" variant="h6">
              {usuario.nome}
            </Typography>
            <Typography component="p" variant="body1">
              {cpf.format(usuario.cpf)}
            </Typography>
            <Typography component="p" variant="body1">
              {usuario.crp}
            </Typography>
          </Grid>
        </Grid>
        <form noValidate onSubmit={handleSubmit(onSubmit)}>
          <Grid container spacing={3} style={{ marginTop: '0.75rem' }}>
            <Grid item xs={12} md={12}>
              <Controller
                name="email"
                control={control}
                render={({ onChange, onBlur, name, ref, value }) => (
                  <PerfilTextField
                    name={name}
                    type="text"
                    autoComplete="email"
                    label="E-mail"
                    inputRef={ref}
                    value={value}
                    onChange={e => onChange(e.target.value.toLowerCase())}
                    onBlur={onBlur}
                    errors={errors}
                  />
                )}
                rules={{
                  validate: {
                    required: value => value.trim() !== '' || 'Digite o seu e-mail',
                    isEmail: value => isEmail(value) || 'Digite um e-mail válido'
                  }
                }}
              />
            </Grid>
            <Grid item xs={12} md={12}>
              <Controller
                name="telefone"
                control={control}
                render={({ onChange, onBlur, name, ref, value }) => (
                  <PerfilTextField
                    name={name}
                    type="text"
                    autoComplete="telefone"
                    label="Telefone"
                    inputRef={ref}
                    InputProps={{
                      inputComponent: MascaraTelefone
                    }}
                    value={value}
                    onChange={e => onChange(stripMask(e.target.value))}
                    onBlur={onBlur}
                    errors={errors}
                  />
                )}
                rules={{
                  validate: {
                    required: value => value?.trim() !== '' || 'Digite o seu telefone.',
                    minLength: value => value?.length >= 10 || 'Digite um número de telefone válido.'
                  }
                }}
              />
            </Grid>
            {!usuario.cpf && (
              <Grid item xs={12} md={12}>
                <Controller
                  name="cpf"
                  control={control}
                  render={({ onChange, onBlur, name, ref, value }) => (
                    <PerfilTextField
                      name={name}
                      type="text"
                      autoComplete="cpf"
                      label="CPF"
                      inputRef={ref}
                      InputProps={{
                        inputComponent: MascaraCPF
                      }}
                      value={value}
                      onChange={e => onChange(stripMask(e.target.value))}
                      onBlur={onBlur}
                      errors={errors}
                    />
                  )}
                  rules={{
                    validate: {
                      required: value => value?.trim() !== '' || 'Digite o seu CPF',
                      cpfValido: value => cpf.isValid(value) || 'Digite um CPF válido.'
                    }
                  }}
                />
              </Grid>
            )}

            {isAlteracaoSenha && (
              <>
                <Grid item xs={12} md={12}>
                  <Fade in={isAlteracaoSenha} timeout={500}>
                    <PerfilTextField
                      name="senha"
                      type="password"
                      autoComplete="off"
                      label="Senha atual"
                      inputRef={register({
                        required: { value: true, message: 'Digite a sua senha atual.' }
                      })}
                      errors={errors}
                    />
                  </Fade>
                </Grid>
                <Grid item xs={12} md={12}>
                  <Fade in={isAlteracaoSenha} timeout={500}>
                    <PerfilTextField
                      name="novaSenha"
                      type="password"
                      autoComplete="off"
                      label="Digite a nova senha"
                      inputRef={register({
                        required: { value: true, message: 'Digite uma senha.' },
                        minLength: { value: 8, message: 'A senha deve ter no mínimo 8 caracteres.' },
                        validate: value => value !== getValues('senha') || 'A nova senha deve ser diferente da atual.'
                      })}
                      errors={errors}
                    />
                  </Fade>
                </Grid>
                <Grid item xs={12} md={12}>
                  <Fade in={isAlteracaoSenha} timeout={500}>
                    <PerfilTextField
                      name="confirmaNovaSenha"
                      type="password"
                      autoComplete="off"
                      label="Confirme a nova senha"
                      inputRef={register({
                        required: { value: true, message: 'Confirme a nova senha.' },
                        validate: value => value === getValues('novaSenha') || 'A senha digitada não confere.'
                      })}
                      errors={errors}
                    />
                  </Fade>
                </Grid>
              </>
            )}
            <Grid item xs={12} md={12}>
              <Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                disabled={!formState.isDirty || isSubmitting}>
                Salvar Alterações
              </Button>
            </Grid>
          </Grid>
        </form>
      </CardContent>
      <Grid style={{ marginTop: 'auto' }}>
        <Divider />
        <CardActions className={classes.cardActions}>
          {formState.isDirty && (
            <Button
              className={classes.botaoCinza}
              size="small"
              onClick={e => {
                e.preventDefault();
                reset({
                  email: usuario.email,
                  telefone: usuario.telefone
                });
                setAlteracaoSenha(false);
              }}
              startIcon={<DeleteOutlineIcon />}>
              {isSmallScreen() ? 'Descartar' : 'Descartar Alterações'}
            </Button>
          )}
          {!formState.isDirty && <span></span>}
          <Button
            color="primary"
            size="small"
            className={isAlteracaoSenha ? classes.botaoVermelho : null}
            onClick={() => setAlteracaoSenha(!isAlteracaoSenha)}
            startIcon={isAlteracaoSenha ? <ClearOutlinedIcon /> : <LockOutlinedIcon />}>
            {isAlteracaoSenha ? 'Cancelar' : 'Alterar Senha'}
          </Button>
        </CardActions>
      </Grid>
    </Card>
  );
}

function PerfilTextField(props) {
  const { name, errors, ...other } = props;
  return (
    <TextField
      id={name}
      name={name}
      error={errors[name] != null}
      helperText={errors[name]?.message}
      {...other}
      variant="outlined"
      size="small"
      fullWidth
    />
  );
}

function stripMask(maskedString) {
  return maskedString.replace(/[^\d]/g, '');
}

function MascaraCPF(props) {
  const { inputRef, ...other } = props;
  return (
    <Cleave
      htmlRef={inputRef}
      {...other}
      value=" "
      options={{ numericOnly: true, delimiters: ['.', '.', '-'], blocks: [3, 3, 3, 2] }}
    />
  );
}
