import { red } from '@material-ui/core/colors';
import { createMuiTheme, responsiveFontSizes } from '@material-ui/core/styles';
import { ptBR } from '@material-ui/core/locale';

const theme = responsiveFontSizes(
  createMuiTheme(
    {
      palette: {
        primary: {
          main: '#4E45AA'
        },
        secondary: {
          main: '#41b991'
        },
        error: {
          main: red.A400
        },
        background: {
          default: '#ffff'
        }
      },
      overrides: {
        MuiTableRow: {
          root: {
            '&:last-child td': {
              borderBottom: 0
            }
          }
        }
      },
      props: {
        MuiContainer: {
          disableGutters: true
        }
      }
    },
    ptBR
  )
);

export default theme;
