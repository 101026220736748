import React from 'react';
import Copyright from '../components/Copyright';
import Box from '@material-ui/core/Box';
import Link from '@material-ui/core/Link';
import { makeStyles } from '@material-ui/core/styles';
import AppConfig from '../config/AppConfig';

export default Footer;

const useStyles = makeStyles(theme => ({
  gridContainer: {
    justifyContent: 'center',
    display: 'flex',
    flexFlow: 'wrap'
  },
  items: {
    [theme.breakpoints.down('sm')]: {
      minWidth: '100%',
      paddingBottom: 10
    },
    textAlign: 'center',
    paddingLeft: 10,
    paddingRight: 10
  }
}));

function Footer() {
  const classes = useStyles();

  return (
    <Box className={classes.gridContainer}>
      <Box className={classes.items}>
        <Copyright />
      </Box>
      <Box className={classes.items}>
        <Link color="textSecondary" href={`${AppConfig.HOME_URL}/termos-de-uso`} target="_blank">
          Termos de Uso
        </Link>
      </Box>
      <Box className={classes.items}>
        <Link color="textSecondary" href={`${AppConfig.HOME_URL}/politica-de-privacidade`} target="_blank">
          Política de Privacidade
        </Link>
      </Box>
      <Box className={classes.items}>
        <Link color="textSecondary" href={`${AppConfig.HOME_URL}/suporte`} target="_blank">
          Preciso de Ajuda
        </Link>
      </Box>
    </Box>
  );
}
