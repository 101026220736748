import React from 'react';
import TextField from '@material-ui/core/TextField';
import Checkbox from '@material-ui/core/Checkbox';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import Box from '@material-ui/core/Box';
import { KeyboardDatePicker } from '@material-ui/pickers';
import Fade from '@material-ui/core/Fade';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/dayjs';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';

// import NumberFormat from 'react-number-format';

// function NumberFormatCustom(props) {
//   const { inputRef, onChange, ...other } = props;

//   return (
//     <NumberFormat
//       {...other}
//       getInputRef={inputRef}
//       onValueChange={(values) => {
//         onChange({
//           target: {
//             name: props.name,
//             value: values.value,
//           },
//         });
//       }}
//       thousandSeparator
//       isNumericString
//       prefix="$"
//     />
//   );
// }

const inputStyle = { WebkitBoxShadow: '0 0 0 1000px white inset' };

dayjs.extend(utc);
const utcDayJs = (...args) => dayjs.utc(...args);

export function InputCelularPerfil(props) {
  return (
    <TextField
      variant="standard"
      label={props.label}
      onChange={props.onChange}
      required
      value={props.value}
      // fullWidth
      id="celular"
      name="celular"
      type="tel"
      autoComplete="celular"
      autoFocus
      inputProps={{ style: inputStyle }}
    />
  );
}

export function InputCpfPerfil(props) {
  return (
    <TextField
      variant="standard"
      label={props.label}
      onChange={props.onChange}
      required
      value={props.value}
      fullWidth
      id="CPF"
      name="CPF"
      type="number"
      autoComplete="CPF"
      // autoFocus
      inputProps={{ style: inputStyle }}
    />
  );
}

export function InputSenhaAtualPerfil(props) {
  return (
    <TextField
      variant="standard"
      label={props.label}
      onChange={props.onChange}
      required
      value={props.value}
      id="senhaAtual"
      name="senhaAtual"
      type="password"
      autoComplete="senhaAtual"
      inputProps={{ style: inputStyle }}
    />
  );
}

export function InputSenhaLogin(props) {
  return (
    <TextField
      variant="outlined"
      label={props.label}
      onChange={props.onChange}
      required
      fullWidth
      id="senhaAtual"
      name="senhaAtual"
      type="password"
      autoComplete="senhaAtual"
    />
  );
}

export function InputSenhaNova(props) {
  return (
    <TextField
      variant="outlined"
      label={props.label}
      onChange={props.onChange}
      required
      fullWidth
      id="senhaNova"
      name="senhaNova"
      type="password"
      autoComplete="senhaNova"
    />
  );
}

export function InputNomePerfil(props) {
  return (
    <TextField
      variant="standard"
      label={props.label}
      onChange={props.onChange}
      required
      value={props.value}
      fullWidth
      id="nome"
      name="nome"
      type="text"
      autoComplete="nome"
      autoFocus
      inputProps={{ style: inputStyle }}
    />
  );
}

export function InputEnderecoLogradouroPerfil(props) {
  return (
    <TextField
      variant="standard"
      label={props.label}
      onChange={props.onChange}
      required
      value={props.value}
      fullWidth
      id="EnderecoLogradouroPerfil"
      name="EnderecoLogradouroPerfil"
      type="text"
      autoComplete="EnderecoLogradouroPerfil"
      autoFocus
      inputProps={{ style: inputStyle }}
    />
  );
}

export function InputEnderecoComplementoPerfil(props) {
  return (
    <TextField
      variant="standard"
      label={props.label}
      onChange={props.onChange}
      required
      value={props.value}
      // fullWidth
      id="EnderecoComplementoPerfil"
      name="EnderecoComplementoPerfil"
      type="text"
      autoComplete="EnderecoComplementoPerfil"
      autoFocus
      inputProps={{ style: inputStyle }}
    />
  );
}

export function InputEnderecoBairroPerfil(props) {
  return (
    <TextField
      variant="standard"
      label={props.label}
      onChange={props.onChange}
      required
      value={props.value}
      // fullWidth
      id="EnderecoBairroPerfil"
      name="EnderecoBairroPerfil"
      type="text"
      autoComplete="EnderecoBairroPerfil"
      autoFocus
      inputProps={{ style: inputStyle }}
    />
  );
}

export function InputEnderecoCidadePerfil(props) {
  return (
    <TextField
      variant="standard"
      label={props.label}
      onChange={props.onChange}
      required
      value={props.value}
      // fullWidth
      id="EnderecoCidadePerfil"
      name="EnderecoCidadePerfil"
      type="text"
      autoComplete="EnderecoCidadePerfil"
      autoFocus
      inputProps={{ style: inputStyle }}
    />
  );
}

export function InputEnderecoUFPerfil(props) {
  return (
    <TextField
      variant="standard"
      label={props.label}
      onChange={props.onChange}
      required
      value={props.value}
      id="EnderecoUFPerfil"
      name="EnderecoUFPerfil"
      type="text"
      autoComplete="EnderecoUFPerfil"
      autoFocus
      inputProps={{ style: inputStyle }}
    />
  );
}

export function InputEnderecoCepPerfil(props) {
  return (
    <TextField
      variant="standard"
      label={props.label}
      onChange={props.onChange}
      required
      value={props.value}
      id="EnderecoCepPerfil"
      name="EnderecoCepPerfil"
      type="number"
      autoComplete="EnderecoCepPerfil"
      // autoFocus
      inputProps={{ style: inputStyle }}
    />
  );
}

export function InputEnderecoNrPerfil(props) {
  return (
    <TextField
      variant="standard"
      label={props.label}
      onChange={props.onChange}
      required
      value={props.value}
      // fullWidth
      id="EnderecoNrPerfil"
      name="EnderecoNrPerfil"
      type="number"
      autoComplete="EnderecoNrPerfil"
      // autoFocus
      inputProps={{ style: inputStyle }}
    />
  );
}

export function InputEmailPerfil(props) {
  return (
    <TextField
      variant={props.variant}
      label={props.label}
      onChange={props.onChange}
      required
      value={props.value}
      fullWidth
      id="email"
      name="email"
      type="email"
      autoComplete="email"
      autoFocus
      inputProps={{ style: inputStyle }}
    />
  );
}

export function InputEmailLogin(props) {
  return (
    <TextField
      variant="outlined"
      label={props.label}
      onChange={props.onChange}
      required
      fullWidth
      id="email"
      name="email"
      type="email"
      autoComplete="email"
      autoFocus
    />
  );
}

export function InputEmailAddPaciente(props) {
  return (
    <TextField
      variant={props.variant}
      label={props.label}
      onChange={props.onChange}
      required
      fullWidth
      id="emailPaciente"
      name="emailPaciente"
      type="email"
      autoComplete="emailPaciente"
      autoFocus
      inputProps={{ style: inputStyle }}
    />
  );
}

export function InputManterConectadoLogin(props) {
  return (
    <FormControlLabel
      label={props.label}
      onChange={props.onChange}
      disabled={props.disabled}
      control={<Checkbox value="true" color="primary" />}
    />
  );
}

export function InputEfetuarLoginAposAlteracaoSenha(props) {
  return (
    <FormControlLabel
      label={props.label}
      onChange={props.onChange}
      control={<Checkbox value="true" color="primary" />}
    />
  );
}

export function InputDataNascimentoPerfil(props) {
  const [dataNasc, setDataNasc] = React.useState(props.value);

  return (
    <MuiPickersUtilsProvider libInstance={utcDayJs} utils={DateFnsUtils}>
      <KeyboardDatePicker
        label={props.label}
        value={dataNasc}
        onChange={X => {
          setDataNasc(X);
          props.onChange(X);
        }}
        disableFuture
        variant="standard"
        openTo="year"
        maxDate={new Date()}
        format="DD/MM/YYYY"
        views={['year', 'month', 'date']}
        cancelLabel="Cancelar"
        autoOk
        loadingIndicator={Fade}
      />
    </MuiPickersUtilsProvider>
  );
}

export default function Input(props) {
  const [sexo, setSexo] = React.useState(null);

  const handleChangeSexo = event => {
    setSexo(event.target.value);
    props.onChange(event);
  };

  let foco;
  if (props.foco) {
    foco = true;
  }

  if (props.nome) {
    return (
      <TextField
        variant={props.variant}
        label={props.label}
        onChange={props.onChange}
        required
        fullWidth
        id="nome"
        name="nome"
        type="text"
        autoComplete="nome"
        autoFocus={foco}
      />
    );
  }

  if (props.email) {
    return (
      <TextField
        variant={props.variant}
        label={props.label}
        onChange={props.onChange}
        required
        fullWidth
        id="email"
        name="email"
        type="email"
        autoComplete="email"
        autoFocus={foco}
      />
    );
  }
  if (props.emailPaciente) {
    return (
      <TextField
        variant={props.variant}
        label={props.label}
        onChange={props.onChange}
        required
        fullWidth
        id="emailPaciente"
        name="emailPaciente"
        type="email"
        autoComplete="emailPaciente"
        autoFocus={foco}
      />
    );
  }
  if (props.senha) {
    return (
      <TextField
        variant={props.variant}
        label={props.label}
        onChange={props.onChange}
        required
        fullWidth
        id="senha"
        name="senha"
        type="password"
        autoComplete="senha"
        autoFocus={foco}
      />
    );
  }

  if (props.sexo) {
    return (
      <FormControl component="fieldset">
        <FormLabel component="legend" focused={foco}>
          {props.label}
        </FormLabel>
        <RadioGroup name="position" value={sexo} onChange={handleChangeSexo} row>
          <FormControlLabel value="M" control={<Radio color="primary" />} label="Masculino" labelPlacement="start" />
          <Box mr={5} />
          <FormControlLabel value="F" control={<Radio color="primary" />} label="Feminino" labelPlacement="end" />
        </RadioGroup>
      </FormControl>
    );
  }
  if (props.termos) {
    return (
      <FormControlLabel
        label={props.label}
        onChange={props.onChange}
        control={<Checkbox value="concordaTermos" color="primary" />}
      />
    );
  }
}
