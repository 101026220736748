import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Avatar from '@material-ui/core/Avatar';

const useStyles = makeStyles(theme => ({
  avatar: {
    margin: theme.spacing(1),
    height: theme.spacing(8),
    width: theme.spacing(8),
    backgroundColor: theme.palette.primary.main
  }
}));

export default function PsiqueAvatar(props) {
  const classes = useStyles();
  return (
    <Avatar className={classes.avatar}>
      <img src="/images/psi_icon.png" alt="Psiquê" height="70%" />
    </Avatar>
  );
}
