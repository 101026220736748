import React from 'react';
import Container from '@material-ui/core/Container';

export default ErrorTestPage;

function ErrorTestPage(props) {
  const handleClickRow = idPaciente => {
    throw new Error('Testando o tratamento de erros.');
  };

  return (
    <Container component="main" maxWidth="lg">
      {handleClickRow()}
    </Container>
  );
}
