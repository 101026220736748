import React from 'react';
import Divider from '@material-ui/core/Divider';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import useStores from '../hooks/useStores';
import CategoriasMenu from '../config/CategoriasMenu';
import { observer } from 'mobx-react';
import Link from '@material-ui/core/Link';
import { Link as RouterLink } from 'react-router-dom';

function ItensMenuLateral() {
  const store = useStores();

  const montarItensCategoria = function(categoria) {
    return categoria.map(itemMenu => {
      return (
        <Link
          key={`menu-laterial-${itemMenu.nome}`}
          component={RouterLink}
          to={itemMenu.path}
          color="inherit"
          onClick={store.interfaceStore.menus.menuLateral.toggle}
          underline="none">
          <ListItem button>
            <ListItemIcon>{itemMenu.icone}</ListItemIcon>
            <ListItemText primary={itemMenu.nome} />
          </ListItem>
        </Link>
      );
    });
  };

  if (store.interfaceStore.menus.menuLateral.itens.length > 0) {
    const itens = store.interfaceStore.menus.menuLateral.itens;
    return (
      <div>
        <Divider />
        <List>{montarItensCategoria(itens[CategoriasMenu.PAINEL])}</List>
        <Divider />
        <List>{montarItensCategoria(itens[CategoriasMenu.PACIENTES])}</List>
        <Divider />
        <List>{montarItensCategoria(itens[CategoriasMenu.USUARIO])}</List>
        <Divider />
        <List>{montarItensCategoria(itens[CategoriasMenu.SESSAO])}</List>
      </div>
    );
  }
  return null;
}

export default observer(ItensMenuLateral);
