import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import useStores from '../hooks/useStores';
import { observer } from 'mobx-react';
import { useParams } from 'react-router-dom';
import PersonAddDisabledIcon from '@material-ui/icons/PersonAddDisabled';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import DadosPaciente from '../components/RegistrosPacientePage/DadosPaciente';
import Divider from '@material-ui/core/Divider';

const useStyles = makeStyles(theme => ({
  root: {
    flexDirection: 'column',
    alignItems: 'center'
  },
  centro: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  action: {
    display: 'inherit',
    textAlign: 'right'
  }
}));

export default observer(function RemoverPacientePage(props) {
  const store = useStores();
  const { idPaciente } = useParams();
  const classes = useStyles();
  const [pacienteRemovido, setPacienteRemovido] = useState(false);

  const handleRemoverPaciente = async event => {
    event.preventDefault();
    const RC = await store.tratamentoStore.encerrarTratamento(store.usuarioStore.usuario.id, idPaciente);
    if (RC.success) {
      setPacienteRemovido(true);
    }
  };

  if (pacienteRemovido) {
    return (
      <Container className={classes.root}>
        <Card>
          <CardContent>
            <Grid container spacing={3}>
              <Grid item xs={12} className={classes.centro}>
                <PersonAddDisabledIcon fontSize="large" />
              </Grid>
              <Grid item xs={12} className={classes.centro}>
                <Typography component="h1" variant="h6">
                  {store.tratamentoStore.tratamento.paciente.nome}
                </Typography>
                <Typography component="h1" variant="body1">
                  {store.tratamentoStore.tratamento.paciente.email}
                </Typography>
              </Grid>
              <Grid item xs={12} className={classes.centro}>
                <Typography component="br" />
                <Typography component="br" />
                <Typography variant="body1">Paciente removido.</Typography>
                <Typography component="br" />
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Container>
    );
  }

  return (
    <Container className={classes.root}>
      <Card>
        <form noValidate onSubmit={handleRemoverPaciente}>
          <CardContent>
            <Grid container spacing={3}>
              <Grid item xs={12} className={classes.centro}>
                <PersonAddDisabledIcon fontSize="large" />
              </Grid>
              <Grid item xs={12} className={classes.centro}>
                <DadosPaciente />
              </Grid>
              <Grid item xs={12} className={classes.centro}>
                <Divider variant="middle" flexItem />
              </Grid>
              <Grid item xs={12} className={classes.centro}>
                <Typography component="br" />
                <Typography component="br" />
                <Typography variant="body1">
                  Ao remover o paciente não será mais possível visualizar seus dados.
                </Typography>
                <Typography variant="body1">Confirma remoção do paciente acima?</Typography>
                <Typography component="br" />
              </Grid>
            </Grid>
          </CardContent>
          <CardActions className={classes.action}>
            <Button type="submit" variant="text" color="primary" className={classes.submit}>
              Remover Paciente
            </Button>
          </CardActions>
        </form>
      </Card>
    </Container>
  );
});
