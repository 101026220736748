import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import useStores from '../../hooks/useStores';
import { useParams } from 'react-router-dom';
import { XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer, LineChart, Line } from 'recharts';
import LoadingBalls from '../LoadingBalls';

export default observer(function LineChartSentimentoTempo(props) {
  const store = useStores();
  const { idPaciente } = useParams();
  const diarios = store.tratamentoStore.tratamento.diarios;
  const [isLoading, setLoading] = useState(true);

  useEffect(() => {
    (async function() {
      await store.tratamentoStore.listarDiarios(store.usuarioStore.usuario.id, idPaciente);

      setLoading(false);
    })();
  }, [store, idPaciente]);

  if (isLoading || diarios === '') {
    return <LoadingBalls />;
  }

  return (
    <ResponsiveContainer>
      <LineChart data={montaSentimentoPeloTempo(diarios)} margin={{ top: 5, right: 30, left: 20, bottom: 5 }}>
        <XAxis dataKey="data" />
        <YAxis
          dataKey="sentimento"
          yAxisId="sentimento"
          type="category"
          domain={dominioSentimento()}
          padding={{ top: 10, bottom: 10 }}
          tickMargin={10}
          tick={true}>
          {/* <Label value="Sentimento" position="insideStart" angle="-90" fill={'#8884d8'} /> */}
        </YAxis>
        <CartesianGrid strokeDasharray="3 3" />
        <Tooltip />
        {/* <Legend /> */}
        <Line
          yAxisId="sentimento"
          type="monotone"
          dataKey="sentimento"
          name="Sentimento"
          stroke="#82ca9d"
          strokeWidth={2}
          legendType="star"
        />
      </LineChart>
    </ResponsiveContainer>
  );
});

function montaSentimentoPeloTempo(diarios) {
  let dadosSentimentoPeloTempo = [];

  Array.from(diarios)
    .sort(comparaSort)
    .forEach(diario => {
      let sentimento = {
        data: new Date(diario.dataEnvio).toLocaleDateString('pt-BR'),
        sentimento: diario.sentimento
      };
      dadosSentimentoPeloTempo.push(sentimento);
    });
  return dadosSentimentoPeloTempo;
}

function comparaSort(a, b) {
  if (a.dataEnvio < b.dataEnvio) {
    return -1;
  } else if (a.dataEnvio > b.dataEnvio) {
    return 1;
  } else {
    return 0;
  }
}
//TODO: obter os dados via API
function dominioSentimento() {
  return ['Muito mal', 'Mal', 'Neutro', 'Bem', 'Muito bem'];
}
