import React from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import NavBar from './components/NavBar';
import Footer from './components/Footer';
import LoadingBalls from './components/LoadingBalls';
import SetPageTitle from './components/SetPageTitle';
import SetPageMenus from './components/SetPageMenus';
import ErrorBoundary from './components/ErrorBoundary';
import Auth from './components/Auth';
import Container from '@material-ui/core/Container';
import { observer } from 'mobx-react';
import useStores from './hooks/useStores';
import LoadingBar from './components/LoadingBar';
import { makeStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import ExibeMensagens from './components/ExibeMensagens';
import AppRoutes from './AppRoutes';
import MenuLateral from './components/MenuLateral';
import NavigationListener from './components/NavigationListener';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    minHeight: '100vh',
    backgroundColor: theme.palette.grey[100]
  },
  main: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4)
  },
  footer: {
    padding: theme.spacing(2, 2),
    marginTop: 'auto',
    backgroundColor: theme.palette.grey[300]
  },
  loading: {
    height: '100vh'
  }
}));

const routes = AppRoutes;

export default observer(App);

function App() {
  const store = useStores();
  const classes = useStyles();

  if (!store.usuarioStore.sessao.isReady) {
    return (
      <div className={classes.loading}>
        <LoadingBalls />
      </div>
    );
  }

  return (
    <Router>
      <div className={classes.root}>
        <CssBaseline />
        <div>
          <NavBar />
          <MenuLateral />
          <ExibeMensagens />
          <SetPageTitle routes={routes} />
          <SetPageMenus routes={routes} />
          <NavigationListener />
          <LoadingBar />
          <Container disableGutters={false} component="main" maxWidth="md" className={classes.main}>
            {!store.interfaceStore.interface.hideTitle && (
              <h2 style={{ textAlign: 'center' }}>{store.interfaceStore.interface.title}</h2>
            )}
            <ErrorBoundary>
              <Switch>
                {routes.map((rota, i) => (
                  <Route key={i} {...rota.route}>
                    <Auth {...rota.auth}>{rota.page}</Auth>
                  </Route>
                ))}
              </Switch>
            </ErrorBoundary>
          </Container>
        </div>
        <div className={classes.footer}>
          <Footer />
        </div>
      </div>
    </Router>
  );
}
