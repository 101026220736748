import FetchCache from '../components/FetchCache';
import AppConfig from '../config/AppConfig';
import { Returner } from '../components/PureJSUtils';

export default class CadastroStore {
  constructor(parent) {
    this.parent = parent;
  }

  async getParceria(codigoParceria) {
    try {
      this.parent.interfaceStore.iniciaLoading();
      const response = await FetchCache(
        `${AppConfig.API_URL}/api/assinaturas/parcerias?codigo=${codigoParceria}`,
        {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json'
          }
        },
        60
      );
      if (response.status === 200) {
        const dadosParceria = await response.json();
        return Returner(true, response.status, dadosParceria);
      } else if (response.status === 404) {
        return Returner(false, response.status, {});
      } else {
        this.parent.interfaceStore.incluiMensagem(
          'Erro ao carregar dados da parceria. Tente atualizar a página, e se o erro persistir, acione o nosso suporte.',
          'error'
        );
        throw new Error('Falha ao carregar a parceria');
      }
    } finally {
      this.parent.interfaceStore.finalizaLoading();
    }
  }

  async getCupom(codigoCupom) {
    try {
      this.parent.interfaceStore.iniciaLoading();
      const response = await FetchCache(
        `${AppConfig.API_URL}/api/assinaturas/cupons?codigo=${codigoCupom}`,
        {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json'
          }
        },
        60
      );
      if (response.status === 200) {
        const dadosCupom = await response.json();
        return Returner(true, response.status, dadosCupom);
      } else if (response.status === 404) {
        return Returner(false, response.status, {});
      } else {
        this.parent.interfaceStore.incluiMensagem(
          'Erro ao carregar dados do cupom. Tente atualizar a página, e se o erro persistir, acione o nosso suporte.',
          'error'
        );
        throw new Error('Falha ao carregar o cupom');
      }
    } finally {
      this.parent.interfaceStore.finalizaLoading();
    }
  }

  reset = () => {
    // Não faz nada, componente stateless.
  };
}
