import { observable, action } from 'mobx';
import { Returner } from '../components/PureJSUtils';
import AppConfig from '../config/AppConfig';

export default class UsuarioStore {
  constructor(parent) {
    this.parent = parent;
    this.userInfo();
  }

  usuario = observable({ id: 0, telefone: 0, u: '' });
  sessao = observable({ loggedIn: false, isReady: false, loggedInMilisseconds: 0 });
  dadosUpgradeConta = observable({ ...JSON.parse(localStorage.getItem('dadosUpgradeConta')) });

  reset = action(async () => {
    resetUsuario(this.usuario);
  });

  userInfo = action(async () => {
    this.parent.interfaceStore.iniciaLoading();

    const response = await fetch(`${AppConfig.API_URL}/api/auth/user-info`, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      }
    });
    this.parent.interfaceStore.finalizaLoading();
    if (response.status === 200) {
      const dados = await response.json();
      this.usuario.id = dados.usuario.id;
      this.usuario.nome = dados.usuario.nome;
      this.usuario.email = dados.usuario.email;
      this.usuario.telefone = dados.usuario.telefone;
      this.usuario.endereco = dados.usuario.endereco;
      this.usuario.cpf = dados.usuario.cpf;
      this.usuario.crp = dados.usuario.crp;
      this.usuario.dataNascimento = dados.usuario.dataNascimento;
      this.usuario.emailConfirmado = dados.usuario.emailConfirmado;
      this.usuario.dataInscricao = dados.usuario.dataInscricao;
      this.usuario.perfis = dados.perfis;
      this.sessao.loggedIn = true;
      this.sessao.isReady = true;
      return Returner(true, response.status);
    } else {
      this.sessao.isReady = true;
      return Returner(false, response.status);
    }
  });

  login = action(async (email, senha, manter) => {
    this.parent.interfaceStore.iniciaLoading();
    const response = await fetch(`${AppConfig.API_URL}/api/auth/login`, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        email: email,
        senha: senha,
        manterConectado: manter,
        usarCookie: true
      })
    });
    this.parent.interfaceStore.finalizaLoading();
    if (response.status === 200) {
      const dados = await response.json();
      this.usuario.id = dados.usuario.id;
      this.usuario.nome = dados.usuario.nome;
      this.usuario.email = dados.usuario.email;
      this.usuario.perfis = dados.perfis;
      this.sessao.loggedInMilisseconds = new Date().getTime();
      this.sessao.loggedIn = true;
      return Returner(true, response.status);
    } else if (response.status === 404) {
      this.parent.interfaceStore.incluiMensagem(
        'Usuário não encontrado. Verifique se digitou corretamente o e-mail e senha.',
        'error'
      );
      return Returner(false, response.status);
    } else if (response.status === 403) {
      const dados = await response.json();
      this.usuario.id = dados.usuario.id;
      this.usuario.nome = dados.usuario.nome;
      this.usuario.email = dados.usuario.email;
      window.localStorage.setItem('psiqueEmailConfirmar' + dados.usuario.id, dados.tokenProvisorio);
      return Returner(false, response.status);
    } else if (response.status === 401) {
      this.parent.interfaceStore.incluiMensagem('Senha incorreta. Tente novamente.', 'error');
      return Returner(false, response.status);
    } else if (response.status === 429) {
      const dadosErro = await response.json();
      const duracaoEspera = Math.ceil(dadosErro.duracaoEspera / 1000);
      this.parent.interfaceStore.incluiMensagem(
        `Foram realizadas muitas tentativas. Recupere sua senha ou tente novamente em ${duracaoEspera} segundos`,
        'error'
      );
      return Returner(false, response.status);
    } else {
      this.parent.interfaceStore.incluiMensagem('Erro ao efetuar login.', 'error');
      return Returner(false, response.status);
    }
  });

  cadastrar = action(async (nome, email, cpf, telefone, senha, crp, codigoCupom, codigoParceria) => {
    this.parent.interfaceStore.iniciaLoading();
    const response = await fetch(`${AppConfig.API_URL}/api/psicologos`, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        nome,
        email,
        cpf,
        telefone,
        crp,
        senha,
        codigoCupom,
        codigoParceria
      })
    });
    this.parent.interfaceStore.finalizaLoading();
    if (response.status === 201) {
      const dados = await response.json();
      window.localStorage.setItem('psiqueEmailConfirmar' + dados.id, dados.tokenProvisorio);
      return Returner(true, response.status, { id: dados.id });
    } else {
      const resp = await response.json();
      this.parent.interfaceStore.incluiMensagem(resp.erro.mensagem, 'error');
      return Returner(false, response.status);
    }
  });

  upgradePerfil = action(async (id, nome, email, cpf, telefone, crp, senha, codigoCupom, codigoParceria) => {
    this.parent.interfaceStore.iniciaLoading();
    const response = await fetch(`${AppConfig.API_URL}/api/psicologos/${id}`, {
      method: 'PUT',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        nome,
        email,
        cpf,
        telefone,
        crp,
        senha,
        codigoCupom,
        codigoParceria
      })
    });
    this.parent.interfaceStore.finalizaLoading();
    if (response.status === 200) {
      await this.login(email, senha, false);
      this.resetUpgradeConta();
      this.parent.interfaceStore.incluiMensagem('Sua conta profissional foi criada com sucesso!', 'success');
      return Returner(true, response.status);
    } else {
      const resp = await response.json();
      this.parent.interfaceStore.incluiMensagem(resp.erro.mensagem, 'error');
      return Returner(false, response.status);
    }
  });

  confirmarEmail = action(async (tokenProvisorio, codigoConfirmacao, id, senha) => {
    this.parent.interfaceStore.iniciaLoading();
    const response = await fetch(`${AppConfig.API_URL}/api/auth/email/confirmar`, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        tokenProvisorio: tokenProvisorio,
        codigoConfirmacao: codigoConfirmacao,
        id: id,
        senha: senha
      })
    });
    this.parent.interfaceStore.finalizaLoading();
    if (response.status === 200) {
      const dados = await response.json();
      this.usuario.id = dados.usuario.id;
      this.usuario.nome = dados.usuario.nome;
      this.usuario.email = dados.usuario.email;
      this.usuario.perfis = dados.perfis;
      this.sessao.loggedInMilisseconds = new Date().getTime();
      this.sessao.loggedIn = true;
      window.localStorage.removeItem('psiqueEmailConfirmar' + id);
      this.parent.interfaceStore.incluiMensagem('E-mail confirmado com sucesso.', 'success');
      return Returner(true, response.status);
    } else {
      this.parent.interfaceStore.incluiMensagem('Erro ao confirmar e-mail.', 'error');
      return Returner(false, response.status);
    }
  });

  validarLinkEmailConfirmacao = action(async (id, codigoConfirmacao) => {
    this.parent.interfaceStore.iniciaLoading();
    const response = await fetch(`${AppConfig.API_URL}/api/auth/email/status`, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        id: id,
        codigoConfirmacao: codigoConfirmacao
      })
    });
    this.parent.interfaceStore.finalizaLoading();
    if (response.status === 200) {
      const dados = await response.json();
      this.usuario.emailConfirmado = dados.emailConfirmado;
      this.usuario.codigoCorreto = dados.codigoCorreto;
      return Returner(true, response.status);
    } else {
      this.parent.interfaceStore.incluiMensagem('Erro ao checar link de confirmação de email.', 'error');
      return Returner(false, response.status);
    }
  });

  reenviarEmailConfirmacao = action(async tokenProvisorio => {
    this.parent.interfaceStore.iniciaLoading();
    const response = await fetch(`${AppConfig.API_URL}/api/auth/email/reenviar-confirmacao`, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        tokenProvisorio: tokenProvisorio
      })
    });
    this.parent.interfaceStore.finalizaLoading();
    if (response.status === 200) {
      this.parent.interfaceStore.incluiMensagem('Novo email de confirmação enviado.', 'success');
      return Returner(true, response.status);
    } else {
      this.parent.interfaceStore.incluiMensagem('Falha ao enviar novo email de confirmação.', 'error');
      return Returner(false, response.status);
    }
  });

  sair = action(async () => {
    this.parent.interfaceStore.iniciaLoading();
    const response = await fetch(`${AppConfig.API_URL}/api/auth/logout`, {
      method: 'POST'
    });
    this.parent.interfaceStore.finalizaLoading();
    if (response.status === 204) {
      this.sessao.loggedIn = false;
      this.parent.reset();
      return Returner(true, response.status);
    } else {
      this.parent.interfaceStore.incluiMensagem('Falha ao efetuar logout, tente novamente.', 'error');
      return Returner(false, response.status);
    }
  });

  atualizarDados = action(async dadosAtualizados => {
    this.parent.interfaceStore.iniciaLoading();
    const response = await fetch(`${AppConfig.API_URL}/api/psicologos/` + this.usuario.id, {
      method: 'PATCH',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(dadosAtualizados)
    });
    this.parent.interfaceStore.finalizaLoading();
    if (response.status === 200) {
      await this.userInfo();
      this.parent.interfaceStore.incluiMensagem('Dados atualizados com sucesso!', 'success');
      return Returner(true, response.status);
    } else if (response.status === 401) {
      this.parent.interfaceStore.incluiMensagem('A senha informada está incorreta.', 'error');
      return Returner(false, response.status);
    } else if (response.status === 409) {
      this.parent.interfaceStore.incluiMensagem('O e-mail informado já está em uso.', 'error');
      return Returner(false, response.status);
    } else {
      this.parent.interfaceStore.incluiMensagem('Falha na atualização dos dados. Tente novamente mais tarde.', 'error');
      return Returner(false, response.status);
    }
  });

  recuperarSenhaPasso1 = action(async email => {
    this.parent.interfaceStore.iniciaLoading();
    const response = await fetch(`${AppConfig.API_URL}/api/auth/senha/recuperar`, {
      method: 'PUT',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        email: email,
        source: 'psique-web'
      })
    });
    this.parent.interfaceStore.finalizaLoading();
    if (response.status === 201) {
      this.parent.interfaceStore.incluiMensagem('E-mail enviado com sucesso.', 'success');
      return Returner(true, response.status);
    } else if (response.status === 404) {
      this.parent.interfaceStore.incluiMensagem('Email não localizado.', 'error');
      return Returner(false, response.status);
    } else {
      this.parent.interfaceStore.incluiMensagem('Erro na recuperação da senha.', 'error');
      return Returner(false, response.status);
    }
  });

  recuperarSenhaPasso2 = action(async (email, codigoRecuperacao) => {
    this.parent.interfaceStore.iniciaLoading();
    const response = await fetch(`${AppConfig.API_URL}/api/auth/senha/recuperar`, {
      method: 'PATCH',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        email: email,
        codigoRecuperacao: codigoRecuperacao
      })
    });
    this.parent.interfaceStore.finalizaLoading();
    if (response.status === 202) {
      this.parent.interfaceStore.incluiMensagem('Dados verificados. Informe sua nova senha.', 'success');
      return Returner(true, response.status);
    } else if (response.status === 401) {
      this.parent.interfaceStore.incluiMensagem('Dados da recuperação não conferem.', 'error');
      return Returner(false, response.status);
    } else if (response.status === 404) {
      this.parent.interfaceStore.incluiMensagem('Requisição de recuperação de senha não localizada.', 'error');
      return Returner(false, response.status);
    } else {
      this.parent.interfaceStore.incluiMensagem('Erro na recuperação da senha.', 'error');
      return Returner(false, response.status);
    }
  });

  recuperarSenhaPasso3 = action(async (email, codigoRecuperacao, novaSenha) => {
    this.parent.interfaceStore.iniciaLoading();
    const response = await fetch(`${AppConfig.API_URL}/api/auth/senha/recuperar`, {
      method: 'PATCH',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        email: email,
        codigoRecuperacao: codigoRecuperacao,
        novaSenha: novaSenha
      })
    });
    this.parent.interfaceStore.finalizaLoading();
    if (response.status === 204) {
      this.parent.interfaceStore.incluiMensagem('Senha alterada.', 'success');
      return Returner(true, response.status);
    } else if (response.status === 401) {
      this.parent.interfaceStore.incluiMensagem('Dados da alteração da senha não conferem.', 'error');
      return Returner(false, response.status);
    } else if (response.status === 404) {
      this.parent.interfaceStore.incluiMensagem('Requisição de alteração de senha não localizada.', 'error');
      return Returner(false, response.status);
    } else {
      this.parent.interfaceStore.incluiMensagem('Erro na alteração da senha. Tente novamente.', 'error');
      return Returner(false, response.status);
    }
  });

  prepararUpgradeConta = action(async () => {
    this.dadosUpgradeConta.id = this.usuario.id;
    this.dadosUpgradeConta.nome = this.usuario.nome;
    this.dadosUpgradeConta.email = this.usuario.email;
    localStorage.setItem('dadosUpgradeConta', JSON.stringify(this.dadosUpgradeConta));
    await this.sair();
  });

  resetUpgradeConta = action(async () => {
    this.dadosUpgradeConta.id = undefined;
    this.dadosUpgradeConta.nome = undefined;
    this.dadosUpgradeConta.email = undefined;
    localStorage.removeItem('dadosUpgradeConta');
  });
}

const resetUsuario = action(usuario => {
  Object.keys(usuario).forEach(k => {
    usuario[k] = undefined;
  });
  usuario.id = 0;
  usuario.telefone = 0;
  usuario.u = ' ';
});
