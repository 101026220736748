import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import Box from '@material-ui/core/Box';
import useStores from '../../hooks/useStores';
import MontaTabela from './MontaTabela';
import LoadingBalls from '../LoadingBalls';

export default observer(function TabelaPacientes(props) {
  const store = useStores();
  const [isLoading, setLoading] = useState(true);

  const dadosTabelaPacientes = {
    pacientes: store.tratamentoStore.tratamento.pacientes,
    pacientesPendentes: store.tratamentoStore.tratamento.pacientesPendentes
  };

  useEffect(() => {
    (async function() {
      await Promise.all([
        store.tratamentoStore.listarTratamentos(store.usuarioStore.usuario.id),
        store.tratamentoStore.listarTratamentosPendentes(store.usuarioStore.usuario.id)
      ]);
      setLoading(false);
    })();
  }, [store]);

  if (isLoading) {
    return <LoadingBalls />;
  }

  return (
    <div>
      <MontaTabela dadosTabela={montaDadosTabelaPacientes(dadosTabelaPacientes)} />
      <Box mb={1} />
    </div>
  );
});

function montaDadosTabelaPacientes(dados) {
  const dadosTabela = [
    { nome: 'Ativos', valor: 0, link: '/pacientes' },
    { nome: 'Pendentes', valor: 0, link: '/pacientes/pendentes' }
  ];

  Array.from(dados.pacientes).forEach(row => {
    dadosTabela[0].valor++;
  });
  Array.from(dados.pacientesPendentes).forEach(row => {
    dadosTabela[1].valor++;
  });
  return dadosTabela;
}
