import React from 'react';
import Typography from '@material-ui/core/Typography';

export default AvisoCategorizacaoGmail;

function AvisoCategorizacaoGmail(props) {
  const { email, style } = props;
  if (email.includes('@gmail')) {
    return (
      <Typography component="p" variant="subtitle2" style={style}>
        <b>
          Obs: No Gmail, algumas vezes o nosso e-mail pode aparecer na aba das categorias "Promoções" ou "Atualizações".
          Se isso acontecer, você poder mover o e-mail para a sua caixa principal, e nas próximas vezes, nossos e-mails
          aparecerão lá.
        </b>
      </Typography>
    );
  }

  return null;
}
