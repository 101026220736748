import React, { useState } from 'react';
import Button from '@material-ui/core/Button';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import TextField from '@material-ui/core/TextField';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import Checkbox from '@material-ui/core/Checkbox';
import { Link as RouterLink, useHistory, useParams, useLocation } from 'react-router-dom';
import useStores from '../hooks/useStores';
import { useForm, Controller } from 'react-hook-form';
import isEmail from 'validator/lib/isEmail';
import AppConfig from '../config/AppConfig';
import { cpf } from 'cpf-cnpj-validator';
import Cleave from 'cleave.js/react';
import MascaraTelefone from '../components/forms/MascaraTelefone';
import { observer } from 'mobx-react';
import ClearOutlinedIcon from '@material-ui/icons/ClearOutlined';
import NoMatchPage from './NoMatchPage';
import CardOferta from '../components/cadastro/CardOferta';

const useStyles = makeStyles(theme => ({
  paper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  paperRight: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'right'
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1)
  },
  submit: {
    margin: theme.spacing(2, 0)
  },
  progress: {
    position: 'fixed',
    zIndex: 1200,
    top: '0',
    left: '0',
    width: '100%'
  },
  parceriaBox: {
    borderWidth: 1,
    border: 'dashed',
    borderColor: theme.palette.grey[500],
    backgroundColor: '#cbfbe7',
    padding: theme.spacing(1),
    marginTop: '1.25rem',
    marginBottom: '0.5rem'
  }
}));

export default observer(CadastroPage);

function CadastroPage() {
  const classes = useStyles();
  const store = useStores();
  const history = useHistory();
  const params = useParams();
  const query = new URLSearchParams(useLocation().search);
  const [isSuccess, setSuccess] = useState(false);

  const [ofertaNaoEncontrada, setOfertaNaoEncontrada] = useState(false);
  const { tipoOferta, codigoOferta } = obterDadosOferta(params, query);

  store.interfaceStore.setHideTitle(true);

  const isUpgradeConta = store.usuarioStore.dadosUpgradeConta.id ? true : false;

  const { register, handleSubmit, errors, formState, getValues, control } = useForm({
    mode: 'onBlur',
    defaultValues: isUpgradeConta
      ? {
          nome: store.usuarioStore.dadosUpgradeConta.nome,
          email: store.usuarioStore.dadosUpgradeConta.email
        }
      : undefined
  });
  const { isSubmitting } = formState;

  const onSubmit = async dados => {
    if (isUpgradeConta) {
      await doUpgradePerfil(dados);
    } else {
      await doCadastrar(dados);
    }
  };

  const doCadastrar = async dados => {
    const RC = await store.usuarioStore.cadastrar(
      dados.nome,
      dados.email,
      stripMask(dados.cpf),
      stripMask(dados.telefone),
      dados.senha,
      dados.crp,
      tipoOferta === 'cupom' ? codigoOferta : undefined,
      tipoOferta === 'parceria' ? codigoOferta : undefined
    );
    if (RC.success) {
      setSuccess(true);
      const isGmail = dados.email.includes('@gmail');
      const { id } = RC.dados;
      window.location.href = `${AppConfig.HOME_URL}/cadastro-concluido/?id=${id}${isGmail ? '&gmail=true' : ''}`;
    }
  };

  const doUpgradePerfil = async dados => {
    const RC = await store.usuarioStore.upgradePerfil(
      store.usuarioStore.dadosUpgradeConta.id,
      dados.nome,
      store.usuarioStore.dadosUpgradeConta.email,
      stripMask(dados.cpf),
      stripMask(dados.telefone),
      dados.crp,
      dados.senha,
      tipoOferta === 'cupom' ? codigoOferta : undefined,
      tipoOferta === 'parceria' ? codigoOferta : undefined
    );
    if (RC.success) {
      setSuccess(true);
      history.push('/painel');
    }
  };

  if (ofertaNaoEncontrada) {
    if (tipoOferta === 'parceria') {
      return <NoMatchPage />;
    } else {
      store.interfaceStore.incluiMensagem('O cupom informado não foi encontrado.', 'warning');
    }
  }

  return (
    <Container component="main" maxWidth="md" className={classes.paper}>
      <Container className={classes.paper} maxWidth="xs">
        <Typography component="h1" variant="h5">
          {!isUpgradeConta
            ? 'Seja bem vindo ao Psiquê!'
            : `Olá, ${store.usuarioStore.dadosUpgradeConta.nome.split(' ')[0]}!`}
        </Typography>
        {tipoOferta !== 'nenhuma' && (
          <CardOferta
            tipoOferta={tipoOferta}
            codigoOferta={codigoOferta}
            setOfertaNaoEncontrada={setOfertaNaoEncontrada}
          />
        )}
        <p style={{ textAlign: 'center' }}>
          {!isUpgradeConta
            ? 'Preencha os dados abaixo para criar sua conta.'
            : 'Complemente seus dados abaixo para criarmos sua conta profissional.'}
        </p>
        <form noValidate className={classes.form} onSubmit={handleSubmit(onSubmit)}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <CadastroTextField
                name="nome"
                type="text"
                autoComplete="nome"
                label="Nome completo"
                required
                inputRef={register({ required: { value: true, message: 'Digite o seu nome completo' } })}
                errors={errors}
              />
            </Grid>
            <Grid item xs={12}>
              <CadastroTextField
                name="email"
                type="email"
                autoComplete="email"
                label="Email"
                required
                inputRef={register({
                  required: { value: true, message: 'Digite o seu e-mail' },
                  validate: value => isEmail(value) || 'Digite um e-mail válido'
                })}
                errors={errors}
                disabled={isUpgradeConta}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Controller
                name="cpf"
                control={control}
                defaultValue=""
                render={({ onChange, onBlur, name, ref }) => (
                  <CadastroTextField
                    name={name}
                    type="text"
                    autoComplete="cpf"
                    label="CPF"
                    required
                    InputProps={{
                      inputComponent: MascaraCPF
                    }}
                    inputRef={ref}
                    onChange={onChange}
                    onBlur={onBlur}
                    errors={errors}
                  />
                )}
                rules={{
                  validate: {
                    required: value => value.trim() !== '' || 'Digite o seu CPF',
                    cpfValido: value => cpf.isValid(value) || 'Digite um CPF válido.'
                  }
                }}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Controller
                name="telefone"
                control={control}
                defaultValue=""
                render={({ onChange, onBlur, name, ref }) => (
                  <CadastroTextField
                    name={name}
                    type="text"
                    autoComplete="telefone"
                    label="Telefone"
                    required
                    inputRef={ref}
                    InputProps={{
                      inputComponent: MascaraTelefone
                    }}
                    onChange={e => onChange(stripMask(e.target.value))}
                    onBlur={onBlur}
                    errors={errors}
                  />
                )}
                rules={{
                  validate: {
                    required: value => value.trim() !== '' || 'Digite o seu telefone.',
                    minLength: value => value.length >= 10 || 'Digite um número válido.'
                  }
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <CadastroTextField
                name="crp"
                type="text"
                autoComplete="off"
                label="Número de inscrição no conselho"
                required
                inputRef={register({
                  required: { value: true, message: 'Digite o seu CRP ou CRM.' },
                  pattern: {
                    value: /\d+(\s|.)[a-zA-Z]{2}/,
                    message: 'Digite o CRP ou CRM com a região. Exemplo: 1234/DF.'
                  }
                })}
                errors={errors}
              />
            </Grid>

            <Grid item xs={12} md={isUpgradeConta ? 12 : 6}>
              <CadastroTextField
                name="senha"
                type="password"
                autoComplete="off"
                label={isUpgradeConta ? 'Confirme sua senha' : 'Senha'}
                required
                inputRef={register({
                  required: { value: true, message: 'Digite a sua senha.' },
                  minLength: isUpgradeConta
                    ? undefined
                    : { value: 8, message: 'A senha deve ter no mínimo 8 caracteres.' }
                })}
                errors={errors}
              />
            </Grid>
            {!isUpgradeConta && (
              <Grid item xs={12} md={6}>
                <CadastroTextField
                  name="confirmaSenha"
                  type="password"
                  autoComplete="off"
                  label="Confirme a senha"
                  required
                  inputRef={register({
                    required: { value: true, message: 'Confirme a senha.' },
                    validate: value => value === getValues('senha') || 'A senha digitada não confere.'
                  })}
                  errors={errors}
                />
              </Grid>
            )}
            <Grid item xs={12}>
              <FormControl
                required
                error={errors.aceiteTermos != null}
                component="fieldset"
                className={classes.formControl}>
                <FormControlLabel
                  name="aceiteTermos"
                  label={
                    <Typography>
                      Concordo com os{' '}
                      <Link href={`${AppConfig.HOME_URL}/termos-de-uso`} target="_blank">
                        Termos de Uso
                      </Link>
                    </Typography>
                  }
                  control={
                    <Checkbox
                      inputRef={register({
                        required: { value: true, message: 'É necessário concordar com os termos de uso.' }
                      })}
                      name="aceiteTermos"
                      required
                      defaultValue={false}
                      color="primary"
                    />
                  }
                />
                <FormHelperText style={{ textAlign: 'center' }}>{errors.aceiteTermos?.message}</FormHelperText>
              </FormControl>
            </Grid>
          </Grid>
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            disabled={isSubmitting || isSuccess}
            className={classes.submit}>
            {isSubmitting || isSuccess
              ? 'Aguarde...'
              : !isUpgradeConta
              ? 'Criar sua conta'
              : 'Criar Conta Profissional'}
          </Button>
          {!isUpgradeConta && (
            <Grid container justify="flex-end">
              <Grid item>
                <Link component={RouterLink} to="/login" variant="body2">
                  Já possui uma conta? Entre aqui.
                </Link>
              </Grid>
            </Grid>
          )}
          {isUpgradeConta && (
            <Button
              fullWidth
              color="primary"
              startIcon={<ClearOutlinedIcon />}
              onClick={() => {
                store.usuarioStore.resetUpgradeConta();
                history.push('/login');
              }}>
              Cancelar
            </Button>
          )}
        </form>
      </Container>
    </Container>
  );

  function obterDadosOferta(params, query) {
    if (!ofertaNaoEncontrada) {
      const { codigoParceria } = params;

      if (codigoParceria) {
        return { tipoOferta: 'parceria', codigoOferta: codigoParceria };
      } else {
        const codigoCupom = query.get('codigoCupom');

        if (codigoCupom) {
          return { tipoOferta: 'cupom', codigoOferta: codigoCupom };
        }
      }
    }

    const cupomPadrao = AppConfig.CUPOM_ATUAL_CONTA_PROFISSIONAL;
    if (cupomPadrao) {
      return { tipoOferta: 'cupom', codigoOferta: cupomPadrao };
    }

    return { tipoOferta: 'nenhuma' };
  }
}
function CadastroTextField(props) {
  const { name, errors, ...other } = props;
  return (
    <TextField
      id={name}
      name={name}
      error={errors[name] != null}
      helperText={errors[name]?.message}
      {...other}
      variant="outlined"
      fullWidth
    />
  );
}

function MascaraCPF(props) {
  const { inputRef, ...other } = props;
  return (
    <Cleave
      htmlRef={inputRef}
      {...other}
      value=" "
      options={{ numericOnly: true, delimiters: ['.', '.', '-'], blocks: [3, 3, 3, 2] }}
    />
  );
}

function stripMask(maskedString) {
  return maskedString.replace(/[^\d]/g, '');
}
