import React from 'react';
import Link from '@material-ui/core/Link';
import Typography from '@material-ui/core/Typography';

export default Copyright;

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {'Psiquê © '}
      <Link color="inherit" href="https://psique.app/" target="_blank">
        HasTI Tecnologia
      </Link>
      {' ' + new Date().getFullYear()}
    </Typography>
  );
}
