import { observable, action } from 'mobx';
import { Returner } from '../components/PureJSUtils';
import FetchCache from '../components/FetchCache';
import AppConfig from '../config/AppConfig';

export default class TratamentoStore {
  constructor(parent) {
    this.parent = parent;
  }

  tratamento = observable({
    pacientes: '',
    diarios: '',
    paciente: '',
    sentimentos: '',
    distorcoes: [],
    pacientesPendentes: [],
    convitesEnviados: [],
    pacienteIncluido: ''
  });

  // TODO: solução temporária para saber quando os dados já foram carregados
  statusCarregamento = observable({
    listaPacientes: false,
    listaPacientesPendentes: false
  });
  estatisticas = observable({ grafico1Painel: '' });

  pacienteAtual = 0;
  ultimaAtualizacaoDiarios = 0;
  atualizacaoDiariosEmProgresso = false;

  deveAtualizarDiarios = function(idPaciente) {
    if (idPaciente !== this.pacienteAtual) {
      this.pacienteAtual = idPaciente;
      return true;
    }

    if (this.atualizacaoDiariosEmProgresso) {
      return false;
    }

    const now = new Date();
    if (now - this.ultimaAtualizacaoDiarios > 10000) {
      return true;
    }
  };

  reset = action(async () => {
    resetTratamento(this.tratamento);
    this.estatisticas.grafico1Painel = '';
    this.pacienteAtual = 0;
    this.ultimaAtualizacaoDiarios = 0;
    this.atualizacaoDiariosEmProgresso = false;
  });

  listarTratamentos = action(async idDoctor => {
    this.parent.interfaceStore.iniciaLoading();
    const response = await FetchCache(
      `${AppConfig.API_URL}/api/psicologos/${idDoctor}/tratamentos`,
      {
        method: 'GET',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json'
        }
      },
      5
    );
    try {
      if (response.status === 200) {
        const dados = await response.json();
        this.tratamento.pacientes = dados;
        return Returner(true, response.status);
      } else if (response.status === 404) {
        this.tratamento.pacientes = [];
        return Returner(true, response.status);
      } else {
        this.parent.interfaceStore.incluiMensagem('Erro ao listar pacientes.', 'error');
        return Returner(false, response.status);
      }
    } finally {
      if (!this.statusCarregamento.listaPacientes) {
        this.statusCarregamento.listaPacientes = true;
      }
      this.parent.interfaceStore.finalizaLoading();
    }
  });

  listarTratamentosPendentes = action(async idDoctor => {
    this.parent.interfaceStore.iniciaLoading();
    const response = await fetch(`${AppConfig.API_URL}/api/psicologos/${idDoctor}/tratamentos/?listarPendentes=true`, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      }
    });
    try {
      if (response.status === 200) {
        const dados = await response.json();
        this.tratamento.pacientesPendentes = dados;
        return Returner(true, response.status);
      } else if (response.status === 404) {
        this.tratamento.pacientesPendentes = [];
        return Returner(true, response.status);
      } else {
        this.parent.interfaceStore.incluiMensagem('Erro ao listar pacientes pendentes.', 'error');
        return Returner(false, response.status);
      }
    } finally {
      if (!this.statusCarregamento.listaPacientesPendentes) {
        this.statusCarregamento.listaPacientesPendentes = true;
      }
      this.parent.interfaceStore.finalizaLoading();
    }
  });

  iniciarTratamento = action(async (idDoctor, email) => {
    this.parent.interfaceStore.iniciaLoading();
    const response = await fetch(`${AppConfig.API_URL}/api/psicologos/${idDoctor}/tratamentos`, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        emailPaciente: email
      })
    });
    this.parent.interfaceStore.finalizaLoading();
    if (response.status === 201 || response.status === 200) {
      const dados = await response.json();
      this.tratamento.pacienteIncluido = dados;
      this.parent.interfaceStore.incluiMensagem(
        'Paciente incluído, solicite que ele faça a confirmação pelo aplicativo.',
        'success'
      );
      return Returner(true, response.status);
    } else if (response.status === 404) {
      return Returner(false, response.status);
    } else if (response.status === 409) {
      this.parent.interfaceStore.incluiMensagem('O usuário informado já é seu paciente.', 'info');
      return Returner(false, response.status);
    } else {
      this.parent.interfaceStore.incluiMensagem('Erro ao incluir paciente.', 'error');
      return Returner(false, response.status);
    }
  });

  enviarConvite = action(async (idDoctor, emailPaciente, nomePaciente) => {
    this.parent.interfaceStore.iniciaLoading();
    const response = await fetch(`${AppConfig.API_URL}/api/psicologos/${idDoctor}/tratamentos/convites`, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        emailPaciente,
        nomePaciente
      })
    });
    this.parent.interfaceStore.finalizaLoading();
    if (response.status === 201 || response.status === 200) {
      const dados = await response.json();
      this.tratamento.pacienteIncluido = dados;
      this.parent.interfaceStore.incluiMensagem('Convite enviado!', 'success');
      return Returner(true, response.status);
    } else if (response.status === 409) {
      const body = await response.json();
      if (body.erro.codigo === 'PACIENTE_JA_CONVIDADO') {
        this.parent.interfaceStore.incluiMensagem('O usuário informado já foi convidado', 'info');
      }
      if (body.erro.codigo === 'EMAIL_JA_UTILIZADO') {
        this.parent.interfaceStore.incluiMensagem('O usuário informado já possui conta no Psiquê', 'info');
      }
      return Returner(false, response.status);
    } else {
      this.parent.interfaceStore.incluiMensagem('Erro ao enviar convite.', 'error');
      return Returner(false, response.status);
    }
  });

  listarConvitesEnviados = action(async idDoctor => {
    this.parent.interfaceStore.iniciaLoading();
    const response = await fetch(`${AppConfig.API_URL}/api/psicologos/${idDoctor}/tratamentos/convites`, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      }
    });
    this.parent.interfaceStore.finalizaLoading();
    if (response.status === 200) {
      const dados = await response.json();
      this.tratamento.convitesEnviados = dados;
      return Returner(true, response.status);
    } else {
      this.parent.interfaceStore.incluiMensagem('Erro ao listar convites enviados.', 'error');
      return Returner(false, response.status);
    }
  });

  encerrarTratamento = action(async (idDoctor, idPaciente) => {
    this.parent.interfaceStore.iniciaLoading();
    const response = await fetch(`${AppConfig.API_URL}/api/psicologos/${idDoctor}/tratamentos/${idPaciente}`, {
      method: 'DELETE',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      }
    });
    this.parent.interfaceStore.finalizaLoading();
    if (response.status === 200) {
      this.parent.interfaceStore.incluiMensagem('Paciente removido.', 'success');
      return Returner(true, response.status);
    } else {
      this.parent.interfaceStore.incluiMensagem('Erro ao encerrar tratamento com o paciente.', 'error');
      return Returner(false, response.status);
    }
  });

  listarDiarios = action(async (idDoctor, idPaciente) => {
    if (!this.deveAtualizarDiarios(idPaciente)) {
      return;
    }
    this.parent.interfaceStore.iniciaLoading();
    this.atualizacaoDiariosEmProgresso = true;
    this.tratamento.diarios = '';
    const response = await FetchCache(
      `${AppConfig.API_URL}/api/psicologos/${idDoctor}/tratamentos/${idPaciente}/diarios`,
      {
        method: 'GET',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json'
        }
      },
      5
    );
    this.parent.interfaceStore.finalizaLoading();
    this.atualizacaoDiariosEmProgresso = false;
    if (response.status === 200) {
      const dados = await response.json();
      this.tratamento.diarios = dados;
      this.ultimaAtualizacaoDiarios = new Date();
      return Returner(true, response.status);
    } else if (response.status === 404) {
      this.tratamento.diarios = [];
      this.parent.interfaceStore.incluiMensagem('Paciente ainda não possui ou não compartilhou diários.', 'info');
      return Returner(true, response.status);
    } else {
      this.parent.interfaceStore.incluiMensagem('Erro ao buscar diários do paciente.', 'error');
      return Returner(false, response.status);
    }
  });

  exportarDiarios = async (idDoctor, idPaciente, listaDiarios, options) => {
    this.parent.interfaceStore.iniciaLoading();
    const formato = options.formato || 'pdf';
    const response = await fetch(
      `${AppConfig.API_URL}/api/psicologos/${idDoctor}/tratamentos/${idPaciente}/diarios/exportar?formato=${formato}`,
      {
        method: 'POST',
        headers: {
          Accept: 'application/pdf',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ idDiarios: listaDiarios })
      }
    );
    this.parent.interfaceStore.finalizaLoading();
    if (response.status === 200) {
      return await response.blob();
    } else {
      this.parent.interfaceStore.incluiMensagem(
        'Houve um erro ao salvar os diários. Por favor, tente novamente.',
        'error'
      );
      return Returner(false, response.status);
    }
  };

  alterarStatusDiario = action(async (idDoctor, idPaciente, idDiario, status) => {
    let index = Array.from(this.tratamento.diarios).findIndex(x => x.id === idDiario);
    this.tratamento.diarios[index].diarioLido = status;
    this.alterarStatusDiarioRemoto(idDoctor, idPaciente, idDiario, status);
  });

  alterarStatusDiarioRemoto = action(async (idDoctor, idPaciente, idDiario, status) => {
    const response = await fetch(
      `${AppConfig.API_URL}/api/psicologos/${idDoctor}/tratamentos/${idPaciente}/diarios/${idDiario}`,
      {
        method: 'PATCH',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          diarioLido: status
        })
      }
    );
    if (response.status !== 204) {
      await this.listarDiarios(idDoctor, idPaciente);
      this.parent.interfaceStore.incluiMensagem('Falha ao alterar status do diário.', 'error');
      return Returner(false, response.status);
    }
  });

  detalharTratamentos = action(async (idDoctor, idPaciente) => {
    this.parent.interfaceStore.iniciaLoading();
    const response = await fetch(`${AppConfig.API_URL}/api/psicologos/${idDoctor}/tratamentos/${idPaciente}`, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      }
    });
    this.parent.interfaceStore.finalizaLoading();
    if (response.status === 200) {
      const dados = await response.json();
      this.tratamento.paciente = dados;
      return Returner(true, response.status);
    } else {
      this.parent.interfaceStore.incluiMensagem('Falha ao buscar informações do paciente.', 'error');
      return Returner(false, response.status);
    }
  });

  obterSentimentos = action(async () => {
    if (this.tratamento.sentimentos !== null && this.tratamento.sentimentos !== '') {
      return;
    }
    this.parent.interfaceStore.iniciaLoading();
    const response = await fetch(`${AppConfig.API_URL}/api/estatico/sentimentos`, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      }
    });
    this.parent.interfaceStore.finalizaLoading();
    if (response.status === 200) {
      const dados = await response.json();
      this.tratamento.sentimentos = dados;
      return Returner(true, response.status);
    } else {
      this.parent.interfaceStore.incluiMensagem('Erro ao buscar sentimentos.', 'error');
      return Returner(false, response.status);
    }
  });

  mapearDistorcoes = action(async (idPsicologo, idTratamento, idPensamento, idsDistorcoes) => {
    this.parent.interfaceStore.iniciaLoading();
    const response = await fetch(
      `${AppConfig.API_URL}/api/psicologos/${idPsicologo}/tratamentos/${idTratamento}/mapear-distorcoes`,
      {
        method: 'POST',
        body: JSON.stringify({
          idPensamento,
          idsDistorcoes,
          perfil: 'psicologo'
        }),
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json'
        }
      }
    );
    this.parent.interfaceStore.finalizaLoading();
    if (response.status === 200) {
      this.parent.interfaceStore.incluiMensagem('Distorções salvas.', 'success');
      return Returner(true, response.status);
    } else {
      this.parent.interfaceStore.incluiMensagem('Erro ao salvar distorções. Por favor, tente novamente.', 'error');
      return Returner(false, response.status);
    }
  });

  obterDistorcoes = action(async () => {
    if (this.tratamento.distorcoes !== null && this.tratamento.distorcoes.length > 0) {
      return;
    }
    this.parent.interfaceStore.iniciaLoading();
    const response = await fetch(`${AppConfig.API_URL}/api/estatico/distorcoes`, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      }
    });
    this.parent.interfaceStore.finalizaLoading();
    if (response.status === 200) {
      const dados = await response.json();
      this.tratamento.distorcoes = dados;
      return Returner(true, response.status);
    } else {
      this.parent.interfaceStore.incluiMensagem('Erro ao buscar as distorções.', 'error');
      return Returner(false, response.status);
    }
  });

  obterEstatisticas = action(async idDoctor => {
    this.parent.interfaceStore.iniciaLoading();
    const response = await FetchCache(
      `${AppConfig.API_URL}/api/psicologos/${idDoctor}/estatisticas`,
      {
        method: 'GET',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json'
        }
      },
      60
    );
    this.parent.interfaceStore.finalizaLoading();
    if (response.status === 200) {
      const dados = await response.json();
      this.estatisticas.grafico1Painel = dados;
      return Returner(true, response.status);
    } else {
      this.parent.interfaceStore.incluiMensagem('Falha ao buscar estatísticas para o Painel.', 'error');
      return Returner(false, response.status);
    }
  });
}

const resetTratamento = action(tratamento => {
  Object.keys(tratamento).forEach(k => {
    tratamento[k] = '';
  });
});
