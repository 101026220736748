import React, { useEffect, useState, useRef } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import useStores from '../hooks/useStores';
import { observer } from 'mobx-react';
import Toolbar from '@material-ui/core/Toolbar';
import LoadingBalls from '../components/LoadingBalls';
import { BotaoIncluirPaciente } from '../components/IncluirPacientePage/BotaoIncluirPaciente';
import RowTablePacientes from '../components/ListaPacientesPage/RowTablePacientes';
import Hidden from '@material-ui/core/Hidden';
import LayoutTextual from '../layout/LayoutTextual';
import Link from '@material-ui/core/Link';
import { Link as RouterLink } from 'react-router-dom';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';
import { useHistory } from 'react-router-dom';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%'
  },
  table: {
    minWidth: '100%'
  },
  title: {
    width: '100%'
  },
  cell: {
    fontSize: '0.9rem',
    padding: '0.8rem'
  },
  firstCell: {
    fontSize: '0.9rem',
    padding: '0.5rem 1.25rem'
  },
  lastCell: {
    fontSize: '0.9rem',
    padding: '0.5rem 0.7rem'
  },
  fabAdicionarPaciente: {
    position: 'fixed',
    bottom: theme.spacing(2),
    right: theme.spacing(2),
    zIndex: 999999,
    [theme.breakpoints.up('md')]: {
      display: 'none'
    }
  },
  optionsBar: {
    display: 'flex',
    justifyContent: 'right',
    marginBottom: '0.25rem',
    [theme.breakpoints.down('md')]: {
      display: 'none'
    }
  }
}));

export default observer(ListaPacientesPage);

function ListaPacientesPage(props) {
  const store = useStores();
  const classes = useStyles();
  const history = useHistory();
  const [colunaSort, setColunaSort] = useState('nome');
  const [sortDirection, setSortDirection] = useState('asc');
  const [isLoading, setLoading] = useState(true);
  const [textoPesquisa, setTextoPesquisa] = useState('');
  const campoPesquisaRef = useRef();
  const rows = filtrarPorNomeIgnorandoAcentos(textoPesquisa, store.tratamentoStore.tratamento.pacientes);

  let timeoutPesquisa;
  const pesquisarPaciente = texto => {
    clearTimeout(timeoutPesquisa);
    timeoutPesquisa = setTimeout(() => {
      setTextoPesquisa(texto);
    }, 500);
  };

  const limparPesquisa = () => {
    clearTimeout(timeoutPesquisa);
    setTextoPesquisa('');
    campoPesquisaRef.current.value = '';
  };

  const handleKeyDownPesquisa = e => {
    if (e.key === 'Enter') {
      campoPesquisaRef.current.blur();
    }
  };

  store.interfaceStore.setOneTrueToManyFalse('reset', true);

  useEffect(() => {
    (async function() {
      await store.tratamentoStore.listarTratamentos(store.usuarioStore.usuario.id);
      setLoading(false);
    })();
  }, [store]);

  const handleSetColuna = x => {
    setColunaSort(x);
    if (colunaSort === x) {
      sortDirection === 'asc' ? setSortDirection('desc') : setSortDirection('asc');
    }
  };

  function comparaSort(a, b) {
    if (colunaSort.includes('data')) {
      if (new Date(a[colunaSort]).getTime() < new Date(b[colunaSort]).getTime()) {
        return sortDirection === 'asc' ? -1 : 1;
      } else {
        return sortDirection === 'asc' ? 1 : -1;
      }
    } else {
      if (a[colunaSort] < b[colunaSort]) {
        return sortDirection === 'asc' ? -1 : 1;
      } else {
        return sortDirection === 'asc' ? 1 : -1;
      }
    }
  }

  if (isLoading) {
    return <LoadingBalls />;
  }

  if (store.tratamentoStore.tratamento.pacientes.length === 0) {
    store.interfaceStore.setHideTitle(true);
    return (
      <LayoutTextual
        title={`Hmm, não encontramos nenhum paciente`}
        iconUrl="/images/icon_empty.svg"
        iconAltText="Nenhum paciente encontrado">
        <LayoutTextual.Paragrafo>
          Você não possui nenhum paciente ativo no momento. Gostaria de incluir um paciente agora? Acesse a a página de{' '}
          <Link to="/pacientes/novo" component={RouterLink}>
            inclusão de pacientes
          </Link>
          . Se você já adicionou um paciente, pode ser que ele ainda não tenha confirmado a inclusão no aplicativo,
          verifique na página de{' '}
          <Link to="/pacientes/pendentes" component={RouterLink}>
            pacientes pendentes
          </Link>
          .
        </LayoutTextual.Paragrafo>
        <LayoutTextual.Paragrafo variant="subtitle2">
          Se estiver com dificuldades para incluir seus pacientes, nós preparamos um tutorial especial para te auxiliar
          na inclusão, você pode acessar ele{' '}
          <Link to="/tutoriais/inclusao-paciente" component={RouterLink}>
            clicando aqui
          </Link>
          , ou a qualquer momento pelo menu principal.
        </LayoutTextual.Paragrafo>
      </LayoutTextual>
    );
  }

  return (
    <Container component="main" maxWidth="lg">
      <div className={classes.root}>
        <Fab
          color="primary"
          className={classes.fabAdicionarPaciente}
          onClick={event => {
            event.stopPropagation();
            history.push('/pacientes/novo');
          }}
          onFocus={event => event.stopPropagation()}>
          <AddIcon />
        </Fab>
        <Grid item xs={12} className={classes.optionsBar}>
          <BotaoIncluirPaciente />
        </Grid>
        <Paper style={{ marginBottom: '1rem' }}>
          <Toolbar>
            <Grid
              container
              spacing={3}
              direction="row"
              alignItems="center"
              style={{ marginTop: '0.75rem', marginBottom: '0.5rem' }}>
              <Grid item xs={12} md={12}>
                <Box style={{ display: 'flex' }}>
                  <TextField
                    id="paciente-search-box"
                    name="paciente-search-box"
                    type="text"
                    label="Pesquisar pelo nome"
                    variant="outlined"
                    size="small"
                    inputRef={campoPesquisaRef}
                    fullWidth
                    onChange={e => pesquisarPaciente(e.target.value)}
                    onKeyDown={e => handleKeyDownPesquisa(e)}
                  />
                  <Button
                    color="primary"
                    disabled={textoPesquisa.length === 0}
                    onClick={limparPesquisa}
                    style={{ marginLeft: '0.5rem' }}>
                    Limpar
                  </Button>
                </Box>
              </Grid>
            </Grid>
          </Toolbar>
        </Paper>
        <Paper>
          <TableContainer>
            <Table className={classes.table} aria-label="simple table">
              <TableHead>
                <TableRow hover>
                  <TableCell className={classes.firstCell}>
                    <TableSortLabel
                      active={'nome' === colunaSort}
                      direction={sortDirection}
                      onClick={x => handleSetColuna('nome')}>
                      Nome
                    </TableSortLabel>
                  </TableCell>
                  <Hidden smDown implementation="js">
                    <TableCell className={classes.cell} align="right">
                      <TableSortLabel
                        active={'diariosNaoLidos' === colunaSort}
                        direction={sortDirection}
                        onClick={x => handleSetColuna('diariosNaoLidos')}>
                        Diários Não Lidos
                      </TableSortLabel>
                    </TableCell>
                    <TableCell className={classes.cell} align="right">
                      <TableSortLabel
                        active={'diariosEnviados' === colunaSort}
                        direction={sortDirection}
                        onClick={x => handleSetColuna('diariosEnviados')}>
                        Total de Diários
                      </TableSortLabel>
                    </TableCell>
                    <TableCell className={classes.cell} align="right">
                      <TableSortLabel
                        active={'dataInicio' === colunaSort}
                        direction={sortDirection}
                        onClick={x => handleSetColuna('dataInicio')}>
                        Início do Tratamento
                      </TableSortLabel>
                    </TableCell>
                  </Hidden>
                  <TableCell className={classes.lastCell} />
                </TableRow>
              </TableHead>
              <TableBody>
                {rows.sort(comparaSort).map(row => (
                  <RowTablePacientes key={row.id} row={row} />
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>
      </div>
    </Container>
  );
}

function filtrarPorNomeIgnorandoAcentos(termoPesquisa, pacientes) {
  if (!pacientes || !Array.isArray(pacientes)) {
    return [];
  }

  if (!termoPesquisa || termoPesquisa.trim().length === 0) {
    return pacientes;
  }

  const termoNormalizado = normalizarString(termoPesquisa);
  return pacientes.filter(paciente => normalizarString(paciente.nome).includes(termoNormalizado));
}

function normalizarString(string) {
  return string
    .toLowerCase()
    .normalize('NFD')
    .replace(/[\u0300-\u036f]/g, '');
}
