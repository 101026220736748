import React, { useEffect, useState } from 'react';
import useStores from '../hooks/useStores';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import { observer } from 'mobx-react';
import LoadingBalls from '../components/LoadingBalls';
import LayoutTextual from '../layout/LayoutTextual';
import Typography from '@material-ui/core/Typography';
import { Link as RouterLink } from 'react-router-dom';
import LinkEmailSuporte from '../components/LinkEmailSuporte';
import LinkWhatsAppSuporte from '../components/LinkWhatsAppSuporte';

export default observer(ConcluirCheckoutPage);

const maxTentativas = 5;
const delay = 3000;
let tentativas = 0;

function ConcluirCheckoutPage() {
  const { assinaturaStore } = useStores();
  const [status, setStatus] = useState('loading');

  useEffect(() => {
    async function atualizarDadosAssinatura() {
      if (assinaturaStore.assinatura.situacao === 'inicial') {
        if (tentativas++ < maxTentativas) {
          await wait(delay);
          await assinaturaStore.atualizarAssinatura();
        } else {
          setStatus('failed');
        }
      } else {
        setStatus('success');
      }
    }
    atualizarDadosAssinatura();
    // Necessário utilizar array vazio para executar a função somente uma vez
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (status === 'loading') {
    return (
      <Container component="main" maxWidth="md">
        <Grid container spacing={3} style={{ display: 'flex', justifyContent: 'center' }}>
          <Grid item xs={12} md={12} style={{ display: 'flex', justifyContent: 'center' }}>
            <LoadingBalls />
          </Grid>
          <Typography variant="h6" style={{ textAlign: 'center' }}>
            Estamos processando o seu pagamento, por favor aguarde...
          </Typography>
        </Grid>
      </Container>
    );
  } else if (status === 'success') {
    let titulo;
    let subTitulo;
    if (assinaturaStore.assinatura.isPeriodoGratis) {
      titulo = 'Pagamento Configurado com Sucesso!';
      subTitulo =
        'Obrigado por usar o Psiquê! O seu cartão será cobrado automaticamente ao término do seu período de avaliação.';
    } else {
      titulo = 'Pagamento Processado com Sucesso!';
      subTitulo = 'Obrigado por usar o Psiquê! O seu cartão será cobrado automaticamente a cada vencimento.';
    }
    return (
      <LayoutTextual title={titulo} iconUrl="/images/icon_boas_vindas.svg" iconAltText="Sucesso!">
        <LayoutTextual.Paragrafo style={{ textAlign: 'center' }}>{subTitulo}</LayoutTextual.Paragrafo>
        <LayoutTextual.Paragrafo style={{ textAlign: 'center' }}>
          <RouterLink to="/painel">Retornar para o seu painel</RouterLink>
        </LayoutTextual.Paragrafo>
      </LayoutTextual>
    );
  } else {
    return (
      <LayoutTextual
        title={'Pagamento em Processsamento'}
        iconUrl="/images/icon_processando_pagamento.svg"
        iconAltText="Em processamento">
        <LayoutTextual.Paragrafo>
          O nosso parceiro de pagamentos ainda está processando o seu pagamento. Aguarde alguns instantes e verifique
          novamente a situação da sua conta.
        </LayoutTextual.Paragrafo>
        <LayoutTextual.Paragrafo>
          Não se preocupe, o seu acesso ao Psiquê <b>não será</b> bloqueado. Caso a situação do seu pagamento não seja
          atualizada dentro de uma hora, por favor entre em contato pelo e-mail <LinkEmailSuporte /> ou via WhatsApp no
          número <LinkWhatsAppSuporte />.
        </LayoutTextual.Paragrafo>
        <LayoutTextual.Paragrafo style={{ textAlign: 'center' }}>
          <RouterLink to="/perfil">Retornar para Minha Conta</RouterLink>
        </LayoutTextual.Paragrafo>
      </LayoutTextual>
    );
  }
}

async function wait(ms) {
  return new Promise(resolve => {
    setTimeout(resolve, ms);
  });
}
