import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { observer } from 'mobx-react';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import useStores from '../hooks/useStores';
import LoadingBalls from '../components/LoadingBalls';
import CardPerfilUsuario from '../components/perfil/CardPerfilUsuario';
import CardAssinatura from '../components/perfil/CardAssinatura';
import LinkEmailSuporte from '../components/LinkEmailSuporte';
import LinkWhatsAppSuporte from '../components/LinkWhatsAppSuporte';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%'
  },
  gridTextoAjuda: {
    justifyContent: 'center',
    textAlign: 'center'
  }
}));

export default observer(PerfilPage);

function PerfilPage(props) {
  const classes = useStyles();
  const store = useStores();
  const usuario = store.usuarioStore.usuario;
  const [isLoading, setLoading] = useState(true);

  useEffect(() => {
    (async function() {
      await store.usuarioStore.userInfo();
      setLoading(false);
    })();
  }, [store]);

  if (isLoading) {
    return <LoadingBalls />;
  }

  return (
    <Container component="main" maxWidth="md" className={classes.root}>
      <Grid container spacing={3} style={{ alignItems: 'stretch' }}>
        <Grid item xs={12} md={6} style={{ display: 'flex' }}>
          <CardPerfilUsuario />
        </Grid>
        <Grid item xs={12} md={6} style={{ display: 'flex' }}>
          <CardAssinatura usuario={usuario} />
        </Grid>
        <Grid item xs={12} md={12} className={classes.gridTextoAjuda}>
          Se estiver com alguma dificuldade, envie um e-mail para <LinkEmailSuporte /> ou pelo WhatsApp{' '}
          <LinkWhatsAppSuporte /> que iremos te ajudar!
        </Grid>
      </Grid>
    </Container>
  );
}
