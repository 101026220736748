import { observable, action, autorun } from 'mobx';
import { loadStripe } from '@stripe/stripe-js';
import FetchCache from '../components/FetchCache';
import AppConfig from '../config/AppConfig';

const initialState = {
  status: 'initial',
  assinatura: null
};

export default class AssinaturaStore {
  #state;
  usuarioStore;
  interfaceStore;
  stripe;
  isLoading;

  constructor(parent) {
    this.parent = parent;
    this.#state = observable(initialState);
    this.usuarioStore = parent.usuarioStore;
    this.interfaceStore = parent.interfaceStore;

    autorun(() => {
      if (
        this.usuarioStore.sessao.isReady &&
        this.usuarioStore.sessao.loggedIn &&
        this.usuarioStore.usuario.perfis.includes('psicologo')
      ) {
        this.#init();
      }
    });
  }

  #init = async () => {
    this.stripe = await loadStripe(AppConfig.STRIPE_PUBLIC_KEY);
    await this.#getAssinatura();
    this.#state.status = 'ready';
  };

  isLoading = () => {
    return this.#state.status !== 'ready';
  };

  get assinatura() {
    return this.#state.assinatura;
  }

  atualizarAssinatura = async () => {
    return this.#getAssinatura(true);
  };

  #getAssinatura = action(async (refresh = false) => {
    if (this.#state.status === 'initial' || refresh === true) {
      this.#state.status = 'fetching';
      this.interfaceStore.iniciaLoading();
      const idUsuario = this.usuarioStore.usuario.id;
      const response = await FetchCache(
        `${AppConfig.API_URL}/api/psicologos/${idUsuario}/assinaturas`,
        {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json'
          }
        },
        60,
        refresh
      );
      if (response.status === 200) {
        this.#state.assinatura = await response.json();
      } else {
        this.parent.interfaceStore.incluiMensagem(
          'Erro ao carregar sua assinatura. Tente atualizar a página, e se o erro persistir, acione o nosso suporte.',
          'error'
        );
      }
      this.#state.status = 'ready';
      this.interfaceStore.finalizaLoading();
    }
  });

  iniciarCheckout = async () => {
    this.interfaceStore.iniciaLoading();
    const idUsuario = this.usuarioStore.usuario.id;
    const response = await fetch(
      `${AppConfig.API_URL}/api/psicologos/${idUsuario}/assinaturas/${this.#state.assinatura.id}/checkout`,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          priceId: 'price_1Iq79wLW8KguUS1Ibp3kNGIO'
        })
      }
    );

    if (response.status !== 200) {
      this.#exibirErroCheckout();
      return;
    }

    const data = await response.json();

    try {
      await this.stripe.redirectToCheckout({ sessionId: data.sessionId });
      this.interfaceStore.finalizaLoading();
    } catch (error) {
      this.#exibirErroCheckout();
    }
  };

  iniciarPortal = async () => {
    this.interfaceStore.iniciaLoading();
    const idUsuario = this.usuarioStore.usuario.id;
    const response = await fetch(
      `${AppConfig.API_URL}/api/psicologos/${idUsuario}/assinaturas/${this.#state.assinatura.id}/portal`,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        }
      }
    );
    if (response.status === 200) {
      const data = await response.json();
      this.interfaceStore.finalizaLoading();
      window.location.href = data.url;
    } else {
      this.parent.interfaceStore.incluiMensagem(
        'Erro ao iniciar o portal de pagamentoss. Tente novamente, e se o erro persistir, acione o nosso suporte.',
        'error'
      );
    }
  };

  #exibirErroCheckout = () => {
    this.parent.interfaceStore.incluiMensagem(
      'Erro ao iniciar checkout. Tente novamente, e se o erro persistir, entre em contato com suporte@psique.app.',
      'error'
    );
    this.interfaceStore.finalizaLoading();
  };

  reset = action(() => {
    Object.assign(this.#state, initialState);
  });
}
