const cache = new Map();
const promises = new Map();

export default async function FetchCache(input, init, seconds, refresh = false) {
  const timestampAtual = new Date().getTime();

  // Somente cachear GETs
  if (init.method && init.method.toUpperCase() !== 'GET') {
    return fetch(input, init);
  }

  if (!refresh) {
    const cachedResponse = cache.get(input);
    if (cachedResponse) {
      if (timestampAtual > cachedResponse.expiration) {
        cache.delete(input);
      } else {
        return cachedResponse.response.clone();
      }
    }
  }

  let runningPromise = promises.get(input);
  if (!runningPromise) {
    runningPromise = fetch(input, init);
    promises.set(input, runningPromise);
    runningPromise
      .then(response => {
        const expiration = timestampAtual + seconds * 1000;
        if (response.status >= 200 && response.status < 300) {
          cache.set(input, { response: response.clone(), expiration });
        }
        promises.delete(input);
      })
      .finally(() => {
        promises.delete(input);
      });
  }

  return runningPromise.then(response => {
    return response.clone();
  });
}
