import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import { makeStyles } from '@material-ui/core/styles';
import useStores from '../../hooks/useStores';
import { useParams } from 'react-router-dom';
import AccordionDoDiario from './AccordionDoDiario';
import PaginacaoT2P2 from '../PaginacaoT2P2';
import { loadCSS } from 'fg-loadcss';
import LoadingBalls from '../LoadingBalls';
import Button from '@material-ui/core/Button';
import Icon from '@material-ui/core/Icon';
import Tooltip from '@material-ui/core/Tooltip';
import Fab from '@material-ui/core/Fab';
import { saveAs } from 'file-saver';

const useStyles = makeStyles(theme => ({
  diarios: {
    width: '100%'
  },
  desktopMenu: {
    display: 'none',
    [theme.breakpoints.up('md')]: {
      display: 'block'
    }
  },
  fabPdf: {
    color: 'white',
    background: '#cf0000',
    position: 'fixed',
    bottom: theme.spacing(2),
    right: theme.spacing(15),
    zIndex: 999999,
    [theme.breakpoints.up('md')]: {
      display: 'none'
    }
  },
  fabDocx: {
    color: 'white',
    background: '#344fa1',
    position: 'fixed',
    bottom: theme.spacing(2),
    right: theme.spacing(2),
    zIndex: 999999,
    [theme.breakpoints.up('md')]: {
      display: 'none'
    }
  }
}));

export default observer(function Diarios(props) {
  const store = useStores();
  const { idPaciente } = useParams();
  const diarios = store.tratamentoStore.tratamento.diarios;
  const classes = useStyles();
  const [page, setPage] = React.useState(1);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const diariosDaPagina = Array.from(store.tratamentoStore.tratamento.diarios)
    .sort(comparaSort)
    .slice(page * rowsPerPage - rowsPerPage, page * rowsPerPage);
  const [isLoading, setLoading] = useState(true);
  const [diariosSelecionados, setDiariosSelecionados] = useState(new Set());
  const [exportacaoEmAndamento, setExportacaoEmAndamento] = useState(false);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(1);
  };

  const handleChangeDiariosSelecionados = async (id, checked) => {
    if (checked) {
      diariosSelecionados.add(id);
    } else {
      diariosSelecionados.delete(id);
    }
    setDiariosSelecionados(new Set(diariosSelecionados));
  };

  const exportarDiarios = async options => {
    setExportacaoEmAndamento(true);
    const blob = await store.tratamentoStore.exportarDiarios(
      store.usuarioStore.usuario.id,
      idPaciente,
      Array.from(diariosSelecionados),
      options
    );
    saveAs(blob, 'diarios');
    setDiariosSelecionados(new Set());
    setExportacaoEmAndamento(false);
  };

  const getMenuItems = () => {
    return [
      <Tooltip title="Selecione um ou mais diários para salvar como PDF" arrow className={classes.desktopMenu}>
        <span>
          <Button
            onClick={() => exportarDiarios({ formato: 'pdf' })}
            color="primary"
            size="small"
            startIcon={<Icon className="far fa-file-pdf" />}
            disabled={exportacaoEmAndamento || diariosSelecionados.size === 0}>
            {exportacaoEmAndamento ? 'Carregando...' : 'Salvar PDF'}
          </Button>
        </span>
      </Tooltip>,
      <Tooltip title="Selecione um ou mais diários para salvar como arquivo Word" arrow className={classes.desktopMenu}>
        <span>
          <Button
            onClick={() => exportarDiarios({ formato: 'docx' })}
            color="primary"
            size="small"
            startIcon={<Icon className="far fa-file-word" />}
            disabled={exportacaoEmAndamento || diariosSelecionados.size === 0}>
            {exportacaoEmAndamento ? 'Carregando...' : 'Salvar Word'}
          </Button>
        </span>
      </Tooltip>
    ];
  };

  const Paginacao = style => {
    if (isLoading || diarios.length === 0) {
      return null;
    }
    return (
      <PaginacaoT2P2
        count={diarios.length}
        page={page}
        onChangePage={handleChangePage}
        rowsPerPage={rowsPerPage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
        menuItems={getMenuItems()}
        style={style}
      />
    );
  };

  useEffect(() => {
    (async function() {
      await store.tratamentoStore.listarDiarios(store.usuarioStore.usuario.id, idPaciente);
      await store.tratamentoStore.obterDistorcoes();
      setLoading(false);
    })();
  }, [store, idPaciente]);

  useEffect(() => {
    const node = loadCSS(
      'https://use.fontawesome.com/releases/v5.12.0/css/all.css',
      document.querySelector('#font-awesome-css')
    );
    return () => {
      node.parentNode.removeChild(node);
    };
  }, []);

  if (isLoading) {
    return <LoadingBalls />;
  }

  return (
    <div className={classes.diarios}>
      {diariosSelecionados.size > 0 && !exportacaoEmAndamento && (
        <>
          <Fab
            variant="extended"
            className={classes.fabPdf}
            onClick={event => {
              event.stopPropagation();
              exportarDiarios({ formato: 'pdf' });
            }}
            onFocus={event => event.stopPropagation()}
            disabled={exportacaoEmAndamento}>
            <Icon className="far fa-file-pdf" />
            PDF
          </Fab>
          <Fab
            variant="extended"
            className={classes.fabDocx}
            onClick={event => {
              event.stopPropagation();
              exportarDiarios({ formato: 'docx' });
            }}
            onFocus={event => event.stopPropagation()}
            disabled={exportacaoEmAndamento}>
            <Icon className="far fa-file-word" />
            Word
          </Fab>
        </>
      )}
      {Paginacao({ marginBottom: '0.5rem' })}
      {diariosDaPagina.map(diario => (
        <AccordionDoDiario
          diario={diario}
          key={diario.id}
          idPaciente={idPaciente}
          checked={diariosSelecionados.has(diario.id)}
          onCheckboxChanged={handleChangeDiariosSelecionados}
        />
      ))}
      {Paginacao({ marginTop: '0.5rem' })}
    </div>
  );
});

function comparaSort(a, b) {
  if (a.dataEnvio < b.dataEnvio) {
    return 1;
  } else if (a.dataEnvio > b.dataEnvio) {
    return -1;
  } else {
    return 0;
  }
}
