import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer, Label } from 'recharts';
import useStores from '../../hooks/useStores';
import LoadingBalls from '../LoadingBalls';

export default observer(function LineChartPacientesDiarios(props) {
  const store = useStores();
  const dadosGrafico = store.tratamentoStore.estatisticas.grafico1Painel;
  const [isLoading, setLoading] = useState(true);

  useEffect(() => {
    (async function() {
      await store.tratamentoStore.obterEstatisticas(store.usuarioStore.usuario.id);
      setLoading(false);
    })();
  }, [store]);

  if (isLoading) {
    return <LoadingBalls />;
  }

  return (
    <ResponsiveContainer>
      <LineChart
        data={montaDadosGraficoPacientesDiarios(dadosGrafico)}
        margin={{ top: 5, right: 30, left: 20, bottom: 5 }}>
        <XAxis dataKey="data" />
        <YAxis yAxisId="left">
          <Label value="Qtd Pacientes" position="insideBottomLeft" angle="-90" fill={'#8884d8'} />
        </YAxis>
        <YAxis yAxisId="right" orientation="right">
          <Label value="Qtd Diários" position="insideRight" angle="-90" fill={'#82ca9d'} />
        </YAxis>
        <CartesianGrid strokeDasharray="3 3" />
        <Tooltip />
        <Legend />
        <Line yAxisId="left" type="monotone" dataKey="qtdPacientes" name="Quantidade de Pacientes" stroke="#8884d8" />
        <Line
          yAxisId="right"
          type="monotone"
          dataKey="qtdNovosDiarios"
          name="Quantidade de Novos Diários"
          stroke="#82ca9d"
        />
      </LineChart>
    </ResponsiveContainer>
  );
});

function montaDadosGraficoPacientesDiarios(dadosGrafico) {
  const dadosPacientesDiarios = [];
  const options = { year: 'numeric', month: 'numeric', day: 'numeric' };
  Array.from(dadosGrafico)
    .reverse()
    .forEach(row => {
      dadosPacientesDiarios.push({
        data: new Date(row.data).toLocaleString('pt-BR', options),
        qtdPacientes: row.pacientes,
        qtdNovosDiarios: row.diarios
      });
    });
  return dadosPacientesDiarios;
}
