import React from 'react';
import { observer } from 'mobx-react';
import useStores from '../hooks/useStores';
import { Redirect, useLocation } from 'react-router-dom';
import LoadingBalls from './LoadingBalls';
import SituacaoPagamento from './perfil/SituacaoAssinatura';

export default observer(Auth);

let exibirMensagemAssinatura = true;

function Auth(props) {
  const { usuarioStore, assinaturaStore, interfaceStore } = useStores();
  const location = useLocation();
  const milissecondsAtu = new Date().getTime();

  if (props.requireAuth) {
    if (!usuarioStore.sessao.loggedIn) {
      return props.otherwise;
    }

    if (!usuarioStore.usuario.perfis.includes('psicologo')) {
      return <Redirect to="/perfil-invalido" />;
    }

    if (assinaturaStore.isLoading()) {
      return <LoadingBalls />;
    }

    const assinatura = assinaturaStore.assinatura;

    if (assinatura && assinatura.acessoBloqueado) {
      let mensagem;
      if (assinatura.situacao === SituacaoPagamento.INICIAL) {
        mensagem = `Seu período de avaliação terminou. Configure uma forma de pagamento para continuar usando o Psiquê.`;
      } else {
        mensagem = `A sua assinatura está bloqueada. Configure uma forma de pagamento para continuar usando o Psiquê.`;
      }

      interfaceStore.incluiMensagem(mensagem, 'error');

      if (location.pathname !== '/perfil') {
        return <Redirect to="/perfil" />;
      }
    } else if (exibirMensagemAssinatura) {
      exibirMensagemAssinatura = false;
      if (assinatura.situacao === SituacaoPagamento.INICIAL) {
        if (assinatura.isPeriodoGratis) {
          if (assinatura.diasRestantesPeriodoGratis === 0) {
            interfaceStore.incluiMensagem(
              `Seu período de avaliação termina hoje.`,
              'warning',
              'Configurar Pagamento',
              '/perfil'
            );
          } else if (assinatura.diasRestantesPeriodoGratis === 1) {
            interfaceStore.incluiMensagem(
              `Seu período de avaliação termina amanhã.`,
              'warning',
              'Configurar Pagamento',
              '/perfil'
            );
          } else if (assinatura.diasRestantesPeriodoGratis <= 7) {
            interfaceStore.incluiMensagem(
              `Seu período de avaliação termina em ${assinatura.diasRestantesPeriodoGratis} dias.`,
              'info',
              'Configurar Pagamento',
              '/perfil'
            );
          }
        } else {
          interfaceStore.incluiMensagem(
            `Seu período de avaliação terminou. Configure uma forma de pagamento para continuar usando o Psiquê.`,
            'error',
            'Configurar Pagamento',
            '/perfil'
          );
        }
      }
    }

    return props.children;
  }

  if (props.requireNotAuth) {
    return (
      <>
        {usuarioStore.sessao.loggedIn && usuarioStore.sessao.loggedInMilisseconds + 5000 < milissecondsAtu
          ? props.otherwise
          : props.children}
      </>
    );
  }

  return props.children;
}
