import React, { useEffect, useState } from 'react';
import Button from '@material-ui/core/Button';
import { useHistory } from 'react-router-dom';
import useStores from '../hooks/useStores';
import { observer } from 'mobx-react';
import LoadingBalls from '../components/LoadingBalls';
import LayoutTextual from '../layout/LayoutTextual';
import AppConfig from '../config/AppConfig';

export default observer(PerfilInvalidoPage);
function PerfilInvalidoPage() {
  const store = useStores();
  const history = useHistory();
  const [isLoading, setLoading] = useState(true);

  useEffect(() => {
    async function init() {
      if (!store.usuarioStore.sessao.loggedIn) {
        history.push('/login');
      } else {
        await store.usuarioStore.prepararUpgradeConta();
        setLoading(false);
      }
    }
    init();
    // Necessário utilizar array vazio para executar a função somente uma vez
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onClickVerPlanos = () => {
    window.location.href = `${AppConfig.HOME_URL}/#planos`;
  };

  const onClickUpgradeConta = () => {
    history.push('/cadastro');
  };

  if (isLoading) {
    return <LoadingBalls />;
  }

  return (
    <LayoutTextual
      title={`O nosso ambiente Web é exclusivo para Contas Profissionais`}
      iconUrl="/images/icon_upgrade_perfil.svg"
      iconAltText="Upgrade de Conta">
      <LayoutTextual.Paragrafo variant="h6" style={{ textAlign: 'center' }}></LayoutTextual.Paragrafo>
      <LayoutTextual.Paragrafo variant="body1" style={{ textAlign: 'center' }}>
        Olá, {store.usuarioStore.dadosUpgradeConta.nome.split(' ')[0]}! Se você é <b>Psicólogo ou Psiquiatra</b>, e quer usar o Psiquê
        com os seus pacientes, faça o <b>upgrade para a Conta Profissional</b>.
      </LayoutTextual.Paragrafo>
      <LayoutTextual.Paragrafo variant="subtitle2" style={{ textAlign: 'center' }}>
        Você poderá testar o Psiquê gratuitamente por 60 dias!
      </LayoutTextual.Paragrafo>
      <LayoutTextual.Paragrafo variant="body1" style={{ textAlign: 'center' }}>
        <Button type="submit" variant="contained" color="primary" onClick={onClickUpgradeConta}>
          Upgrade para Conta Profissional
        </Button>
      </LayoutTextual.Paragrafo>
      <LayoutTextual.Paragrafo variant="body1" style={{ textAlign: 'center' }}>
        <Button variant="outlined" color="primary" onClick={onClickVerPlanos}>
          Ver os Planos Disponíveis
        </Button>
      </LayoutTextual.Paragrafo>
    </LayoutTextual>
  );
}
